import React from "react";

import Logo from "../../images/logo-black.png";
import Sketch from "../../images/confirm-lp.png";

const ConfirmationLayout = (props) => {
  return (
    <div className="confirmation-lp">
      <header className="text-center">
        <img src={Logo} />
      </header>
      <main>{props.children}</main>
      <footer>
        <img src={Sketch} className="w-100" />
      </footer>
    </div>
  );
};

export default ConfirmationLayout;

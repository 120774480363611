import React from "react";
import { Row, Col } from "antd";

const ReduceCollapseHead = ({ header }) => {
  return (
    <>
      <Row align="middle" className="py-2" gutter={[16, 8]}>
        <Col md={9}>
          <p className="m-0 text-black fw-500 title-pad">{header.title}</p>
        </Col>
        <Col md={9}>
          <h4 className="m-0 fw-600 ff-mont">{header.percent}</h4>
          <p className="m-0 text-black fs-12 fw-400">%</p>
        </Col>
        <Col md={6}>
          <div className="text-right">
            <h4 className="m-0 fw-600 ff-mont">{header.emission}</h4>
            <p className="m-0 text-black fs-12 fw-400">tCO₂e</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReduceCollapseHead;

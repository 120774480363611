import React, { useState } from "react";
import { Row, Col, Card, Select, Button, Radio, Badge } from "antd";
import {
  DownOutlined,
  ArrowRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import SubscriptionPlan from "../../sharedModules/SubscriptionPlan";

const Subscription = ({ isVerified = false, activePackage = false }) => {
  const [mode, setMode] = useState("monthly");
  const currencyItem = [
    {
      label: "USD",
      key: "1",
    },
    {
      label: "SAR",
      key: "2",
    },
    {
      label: "AED",
      key: "3",
    },
  ];

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const menuProps = {
    currencyItem,
    onClick: handleMenuClick,
  };

  const planDetails = [
    {
      id: 0,
      team: "Team: 0 - 50",
      planName: "Basic",
      planDesc:
        "Best for enterprises wanting to measure their direct emissions and start their sustainability journey.",
      planPrice: "90",
      fetureTitle: "Features",
      featureList: [
        "Measure– your scope 1 and 2 emissions (GHG accounting). Assign footprint sources (85+ pre-configured) and select from AhyaAI EF Database (10,000+ multi-sectoral emission factors)",
        "Analyze – your emissions by scope, category or facility and access key sustainability indicators (emissions intensity)",
        "Reduce – model your reduction on each category",
        "Report – access your GHG Protocol Inventory Report",
      ],
    },
    {
      id: 1,
      team: "Team: 51 - 250",
      planName: "Pro",
      planDesc:
        "Best for enterprises wanting to measure their direct, indirect, financed emissions and achieve a comprehensive sustainability journey.",
      planPrice: "400",
      fetureTitle: "Everything included in Basic +",
      featureList: [
        "Measure – your all scopes incl. scope 3 emissions (GHG accounting). Collect data directly from internal team members and external stakeholders",
        "Reduce – generate a reduction roadmap aligned with SBTi goals and targets",
        "Report – one report per annum across standards - Global Reporting Initiative (GRI), IFRS S – 2, CDP, GHG Protocol and Partnership for Carbon Accounting Financials (PCAF)",
      ],
    },
    {
      id: 2,
      team: "Team: 251 - 500",
      planName: "Enterprise",
      planDesc:
        "With innovative emissions reduction strategies, and robust tracking tools to measure progress and compliance with sustainability regulations.",
      planPrice: "800",
      fetureTitle: "Everything included in Basic & Pro +",
      featureList: [
        "Measure – across all scopes with auditability checks",
        "Report – Report – multiple reports per annum across standards - Global Reporting Initiative (GRI), IFRS S – 2, CDP, GHG Protocol and Partnership for Carbon Accounting Financials (PCAF)",
        "AhyaAI – enhance accuracy of your climate measurement via AhyaAI Predictive Analytics, Data Anomaly Detection, Reduction Optimization and AhyaAI Copilot",
      ],
    },
  ];
  const planDetailsYearly = [
    {
      id: 0,
      team: "Team: 0 - 50",
      planName: "Basic",
      planDesc:
        "Best for enterprises wanting to measure their direct emissions and start their sustainability journey.",
      planPrice: "972",
      isCurrent: activePackage,
      crossPrice: "1080",
      fetureTitle: "Features",
      featureList: [
        "Measure– your scope 1 and 2 emissions (GHG accounting). Assign footprint sources (85+ pre-configured) and select from AhyaAI EF Database (10,000+ multi-sectoral emission factors)",
        "Analyze – your emissions by scope, category or facility and access key sustainability indicators (emissions intensity)",
        "Reduce – model your reduction on each category",
        "Report – access your GHG Protocol Inventory Report",
      ],
    },
    {
      id: 1,
      team: "Team: 51 - 250",
      planName: "Pro",
      planDesc:
        "Best for enterprises wanting to measure their direct, indirect, financed emissions and achieve a comprehensive sustainability journey.",
      planPrice: "4320",
      crossPrice: "4800",
      fetureTitle: "Everything included in Basic +",
      featureList: [
        "Measure – your all scopes incl. scope 3 emissions (GHG accounting). Collect data directly from internal team members and external stakeholders",
        "Reduce – generate a reduction roadmap aligned with SBTi goals and targets",
        "Report – one report per annum across standards - Global Reporting Initiative (GRI), IFRS S – 2, CDP, GHG Protocol and Partnership for Carbon Accounting Financials (PCAF)",
      ],
    },
    {
      id: 2,
      team: "Team: 251 - 500",
      planName: "Enterprise",
      planDesc:
        "With innovative emissions reduction strategies, and robust tracking tools to measure progress and compliance with sustainability regulations.",
      planPrice: "8640",
      crossPrice: "9600",
      fetureTitle: "Everything included in Basic & Pro +",
      featureList: [
        "Measure – across all scopes with auditability checks",
        "Report – Report – multiple reports per annum across standards - Global Reporting Initiative (GRI), IFRS S – 2, CDP, GHG Protocol and Partnership for Carbon Accounting Financials (PCAF)",
        "AhyaAI – enhance accuracy of your climate measurement via AhyaAI Predictive Analytics, Data Anomaly Detection, Reduction Optimization and AhyaAI Copilot",
      ],
    },
  ];

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  return (
    <Card style={{ marginTop: 24 }}>
      <div className="card-header">
        <Row gutter={[16, 0]} align="middle">
          <Col md={14}>
            <h3 className="m-0">Select subscription</h3>
            <p className="mt-6 mb-0 text-black-60">
              Your enterprise's goals help us provide a tailor made experience.
            </p>
          </Col>
          <Col md={10}>
            <div className="flex-center-end">
              <div className="subscription-pay-type">
                <Radio.Group
                  buttonStyle="solid"
                  onChange={handleModeChange}
                  value={mode}
                >
                  <Radio.Button value="monthly">Monthly</Radio.Button>
                  <Radio.Button value="yearly">
                    Yearly
                    <Badge
                      count="Save 10%"
                      style={{
                        backgroundColor: "#D1FAE4",
                        color: "#166E3F",
                        fontWeight: "500",
                        borderRadius: "6px",
                        fontSize: "12px",
                      }}
                    />
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="currency-dd">
                <Select
                  defaultValue="USD"
                  style={{ width: 110 }}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "SAR", label: "SAR" },
                    { value: "AED", label: "AED" },
                  ]}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-body">
        <div className="subscription-plan">
          {mode === "monthly" ? (
            <Row gutter={[24, 24]}>
              {planDetails.map((plan) => {
                return (
                  <Col md={8}>
                    <SubscriptionPlan plan={plan} verified={isVerified} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row gutter={[24, 24]}>
              {planDetailsYearly.map((plan) => {
                return (
                  <Col md={8}>
                    <SubscriptionPlan plan={plan} verified={isVerified} />
                  </Col>
                );
              })}
            </Row>
          )}

          {isVerified ? (
            ""
          ) : (
            <div className="subscription-cs mt-24">
              <div className="single-plan">
                <div className="plan-head">
                  <Row gutter={[40, 16]}>
                    <Col md={18}>
                      <h4 className="mb-0 mt-8">
                        Fully Managed Experience (FME)
                      </h4>
                      <p className="mt-8 text-black-60 fs-12">
                        Best for large enterprises with complex operations
                        requiring comprehensive tools for managing, tracking and
                        reducing emissions. They require specialized climate
                        science and data science support to progress with their
                        sustainability goals and targets.
                      </p>
                    </Col>
                    <Col md={6}>
                      <div className="price-sec mt-0">
                        <h3 className="m-0">Coming soon</h3>
                      </div>
                      <Button
                        // onClick={onFinish}
                        type="primary"
                        shape="round"
                        icon={<ArrowRightOutlined />}
                        iconPosition="end"
                        block
                        size="large"
                      >
                        Schedule a call
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="plan-body">
                  <p className="mt-0 fw-600">
                    Everything included in Enterprise plus
                  </p>
                  <ul>
                    <li>
                      <CheckOutlined />
                      Climate Science and Data Science experts via a service
                      level agreement (SLA)
                    </li>
                    <li>
                      <CheckOutlined />
                      Customization – additional modules and white-labelling
                    </li>
                    <li>
                      <CheckOutlined />
                      AhyaAI – enhance accuracy of your climate measurement via
                      AhyaAI Predictive Analytics, Data Anomaly Detection,
                      Reduction Optimization and AhyaAI Copilot
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default Subscription;

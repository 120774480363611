import React from "react";

const DetailList = () => {
  return (
    <ul className="monitoring-activity">
      <li>
        <p className="m-0 text-black fw-600">Calculation description</p>
        <p className="m-0 fw-600 text-black-60">Calculated by multiplying the allocation percentage with the Scope 1, 2, and or 3 emissions of the site.</p>
      </li>
      <li>
        <p className="m-0 text-black fw-600">Calculation formula</p>
        <p className="m-0 fw-600 text-black-60">Emissions = Allocation percentage x (Scope 1 + Scope 2 emissions, or +Scope 3 if available)</p>
      </li>
      <li>
        <p className="m-0 text-black fw-600">Calculation summary </p>
        <p className="m-0 fw-600 text-black-60">
          Coming soon
        </p>
      </li>
      <li>
        <p className="m-0 text-black fw-600">Conversion factor</p>
        <p className="m-0 fw-600 text-black-60">Coming Soon</p>
      </li>
    </ul>
  );
};

export default DetailList;

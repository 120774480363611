import React, { useState } from "react";
import { Steps, Layout } from "antd";
import BusinessDetail from "./BusinessDetail";
import PersonalInformation from "./PersonalInformation";
import CompanyInformation from "./CompanyInformation";
import GoalTarget from "./GoalTarget";
import Subscription from "./Subscription";

const { Header } = Layout;

const Verification = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onChange = (val) => {
    setCurrent(val);
  };

  const steps = [
    {
      title: "Business details",
      content: <BusinessDetail current={current} onNext={next} onPrev={prev} />,
    },
    {
      title: "Personal information",
      content: (
        <PersonalInformation current={current} onNext={next} onPrev={prev} />
      ),
    },
    {
      title: "Company information",
      content: (
        <CompanyInformation current={current} onNext={next} onPrev={prev} />
      ),
    },
    {
      title: "Goals & targets",
      content: <GoalTarget current={current} onNext={next} onPrev={prev} />,
    },
    {
      title: "Package & subscription",
      content: <Subscription />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="m-0">Profile verification</h3>
      </Header>

      <div className="main-content">
        <div className="steps-wizard">
          <Steps
            progressDot
            onChange={onChange}
            size="small"
            current={current}
            items={items}
          />
          <>{steps[current].content}</>
        </div>
      </div>
    </>
  );
};

export default Verification;

import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  Button,
  Card,
  Flex,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const PersonalInfo = () => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { Option } = Select;
  const selectBefore = (
    <Select defaultValue="add" style={{ width: 80 }}>
      <Option value="add">971</Option>
      <Option value="minus">+92</Option>
    </Select>
  );
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];
  const optionsGender = [
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "mrs", label: "Mrs" },
    { value: "miss", label: "Miss" },
    { value: "sir", label: "Sir" },
    { value: "madam", label: "Madam" },
    { value: "dr", label: "Dr" },
  ];
  const onFinish = (values) => {
    // onNext();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    // Checks if there are any errors
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    // Check if any field is empty
    const formValues = form.getFieldsValue(); // Get all field values as an object
    const hasEmptyFields = Object.values(formValues).some((value) => !value); // Check for empty values

    // Disable the button if there are errors or missing fields
    setIsButtonDisabled(hasErrors || hasEmptyFields);
  };

  return (
    <>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Personal information</h3>
          <p className="mt-6 mb-0 text-black-60">
            Provide account administrator's information - the primary point of
            contact for your enterprise.
          </p>
        </div>
        <div className="card-body">
          <Form
            layout="vertical"
            form={form}
            onFieldsChange={handleFormChange}
            className="mt-24"
          >
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Row gutter={[24, 0]}>
                  <Col md={6}>
                    <Form.Item
                      label="Salutation"
                      name="Salutation"
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Select placeholder="e.g Mr" options={optionsGender} />
                    </Form.Item>
                  </Col>
                  <Col md={18}>
                    <Form.Item
                      label="Full name"
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: "Required full name",
                        },
                      ]}
                    >
                      <Input placeholder="eg. John" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Corporate email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Required  corporate email",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="eg. name@company.com" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Job title"
                  name="jobTitle"
                  rules={[
                    {
                      required: true,
                      message: "Required job title",
                    },
                  ]}
                >
                  <Input placeholder="eg. Head of Sustainability" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Company ID number"
                  name="IDnumber"
                  rules={[
                    {
                      required: true,
                      message: "Required  company ID number",
                    },
                  ]}
                >
                  <Input placeholder="eg. 7A367BD" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Company ID image"
                  name="IDImage"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                      type: "file",
                    },
                  ]}
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      // border: "1px solid #DEE0E3",
                    },
                  }}
                >
                  {/* <Input placeholder="pdf or jpeg; 10 mb" /> */}
                  <Space.Compact style={{ width: "100%" }}>
                    <Input placeholder="pdf or jpeg; 10 mb" />
                    <Button>Upload</Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Required  phone number",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={selectBefore}
                    placeholder="Enter number"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Personal address (line 1)">
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Personal address (line 2)">
                  <Input placeholder="eg. Dubai International Financial Center" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Country">
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Code / P.O. box">
                  <Input placeholder="eg. 29670" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="State / province">
                  <Input placeholder="eg. Dubai" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="City">
                  <Input placeholder="eg. Dubai" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          className="btn-h-40 mt-24"
          // onClick={handleNext}
          onClick={() => form.submit()}
          disabled={isButtonDisabled}
        >
          Update & save
        </Button>
      </Flex>
    </>
  );
};

export default PersonalInfo;

import React, { useState } from "react";
import AuthLayout from "../../../sharedModules/authtLayout";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apiClient, { API_SETTINGS_BASE_URL } from "../../../config";
import { hanldeSignupVerifiedRequest } from "../../../redux/slices/authSlice";
import { maskEmail } from "../../../utils/maskEmail";
import { useDispatch } from "react-redux";
import axios from "axios";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const rawData = location.state || {};

  const onFinish = async (values) => {
    if (!values?.inputOtp) return setError("Please enter correct OTP.");
    const url = `${API_SETTINGS_BASE_URL}/v1/auth_sso_otp/verify-otp?otp=${values?.inputOtp}&email=${rawData?.corporate_email}`;
    setLoading(true);
    try {
      const response = await axios.post(url);
      setLoading(false);
      const data = response.data;
      if (response?.status === 200) {
        const verifiedUrl = `${API_SETTINGS_BASE_URL}/v1/auth_sso_otp/signup-sso/`;
        if (rawData?.isLogin) {
          localStorage.setItem("user", JSON.stringify(rawData?.user));
          localStorage.setItem("token", JSON.stringify(rawData?.token));
          navigate("/");
        } else {
          dispatch(
            hanldeSignupVerifiedRequest({
              url: verifiedUrl,
              raw: { ...rawData, otp: values?.inputOtp },
            })
          ).then((action) => {
            console.log("action ", action);
            if (hanldeSignupVerifiedRequest.fulfilled.match(action)) {
              navigate("/login");
              localStorage.setItem("loggedIn", JSON.stringify("true"));
            }
            if (hanldeSignupVerifiedRequest.rejected.match(action)) {
              setError(
                typeof action?.payload === "string"
                  ? action?.payload
                  : "Something went wrong."
              );
            }
          });
        }
      } else {
        console.log("response ", response);
        setError(data?.message);
      }
    } catch (err) {
      setLoading(false);
      console.log("error: ", err);
      if (!err.response) {
        throw err;
      }

      const message = err?.response?.data?.detail ?? "An error occurred";

      const result =
        message?.detail?.length > 0
          ? message?.detail?.msg
          : "Wrong OTP entered. Please try again. ";

      if (result) {
        console.log("Failed:", result);
        setError(result);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setError(errorInfo);
  };

  return (
    <AuthLayout>
      <Card bordered={false}>
        <h3 className="text-center m-0 fw-700">Verify your email</h3>
        <p className="mt-8 text-black-60 mb-0 text-center">
          Please enter the 6-digit verification code sent <br />
          to your email{" "}
          <span className="fw-600">{maskEmail(rawData?.corporate_email)}</span>
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mt-24"
        >
          <Row gutter={[16, 0]}>
            <Col md={24} className="mt-10">
              <p className="text-center m-0">
                You didn’t got the email?{" "}
                <Link className="text-primary fw-500" to="/signup">
                  Send again
                </Link>
              </p>
            </Col>
            <Col md={24}>
              <Form.Item
                name="inputOtp"
                rules={[
                  {
                    required: true,
                    message: "Required  Otp",
                  },
                  {
                    len: 6,
                    message: "OTP must be 6 digits",
                  },
                ]}
              >
                <Input.OTP length={6} variant="filled" />
              </Form.Item>
            </Col>
            <Col md={24}>
              {error && (
                <p
                  style={{
                    color: "#E6483D",
                    fontSize: "14px",
                    marginTop: "-20px",
                    textAlign: "center",
                  }}
                >
                  {error}
                </p>
              )}
            </Col>
            <Col md={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { inputOtp } = getFieldsValue();
                  const formIsComplete = !!inputOtp;
                  return (
                    <Button
                      // onClick={onFinish}
                      type="primary"
                      shape="round"
                      disabled={!formIsComplete}
                      block
                      size="large"
                      htmlType="submit"
                    >
                      {loading && (
                        <Spin
                          tip="Loading"
                          size="small"
                          className="custom-spinner"
                        />
                      )}
                      Continue
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={24} className="mt-10">
              <Button
                onClick={() => navigate(-1)}
                shape="round"
                icon={<ArrowLeftOutlined />}
                block
                size="large"
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default VerifyOtp;

import React, { useState } from "react";
import { Card, Radio, Tooltip } from "antd";

import info from "../../../images/info.png";

const ScopeEmission = () => {
  const [value, setValue] = useState("");
  const [valueFC, setValueFC] = useState("");
  const [valueES, setValueES] = useState("");
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onChangeFC = (e) => {
    setValueFC(e.target.value);
  };
  const onChangeES = (e) => {
    setValueES(e.target.value);
  };
  return (
    <>
      <h4 className="mt-3 mb-3 fs-18">Questions</h4>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            How much of your footprint do you want to calculate
          </h5>
          <p className="m-0 fs-12">
            Based on what you are looking to report on, you may already know the
            scope of your desired footprint.
          </p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeFC} value={valueFC}>
              <li>
                <Radio value="rec">
                  <p className="m-0 fw-500">Scope 1, Scope 2</p>
                  <p className="m-0 fs-12" style={{ color: "#D95E28" }}>
                    Recommended
                  </p>
                </Radio>
                <Tooltip title="prompt text">
                  <img src={info} />
                </Tooltip>
              </li>
              <li>
                <Radio value="material">
                  <p className="m-0 fw-500">
                    Scope 1, Scope 2, Scope 3 (Material)
                  </p>
                </Radio>
                <Tooltip title="prompt text">
                  <img src={info} />
                </Tooltip>
              </li>
              <li>
                <Radio value="financed">
                  <p className="m-0 fw-500">
                    Financed Emissions Only (Scope 3 - Category 15))
                  </p>
                </Radio>
                <Tooltip title="prompt text">
                  <img src={info} />
                </Tooltip>
              </li>
              <li>
                <Radio value="determin">
                  <p className="m-0 fw-500">I don’t know</p>
                  <p className="m-0 fs-12 text-black-60">
                    You can determine this later on in the platform as well.
                  </p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Select which part of your value chain emissions you would like to
            account for.
          </h5>
          <p className="m-0 fs-12">Select all that apply.</p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChange} value={value}>
              <li>
                <Radio value="upstream">
                  <p className="m-0 fw-500">
                    Upstream Emissions (Occur during the production of goods or
                    services that an enterprises purchases or uses)
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="downstream">
                  <p className="m-0 fw-500">
                    Downstream Emissions (Occur during the use or disposal of a
                    an enterprise's products or services)
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="none">
                  <p className="m-0 fw-500">None of the above</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Please select which type of emission sources are relevant to your
            operations?
          </h5>
          <p className="m-0 fs-12">Select all that apply.</p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeES} value={valueES}>
              <li>
                <Radio value="vented">
                  <p className="m-0 fw-500">
                    Processed Emissions and Vented Sources
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="waste">
                  <p className="m-0 fw-500">Waste Gas Disposal</p>
                </Radio>
              </li>
              <li>
                <Radio value="leak">
                  <p className="m-0 fw-500">Equipment Leak</p>
                </Radio>
              </li>
              <li>
                <Radio value="combustion">
                  <p className="m-0 fw-500">Combustion</p>
                </Radio>
              </li>
              <li>
                <Radio value="none1">
                  <p className="m-0 fw-500">None of the above</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default ScopeEmission;

import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./slices/authSlice";
import countriesSlice from "./slices/countriesSlice";
import settingsSlice from "./slices/settingsSlice";
import statesSlice from "./slices/statesSlice";
import industrySlice from "./slices/industrySlice";
import businessDetailsSlice from "./slices/businessDetailSlice";
import personalDetailsSlice from "./slices/personalInfoSlice";
import solutationSlice from "./slices/solutationSlice";
import taxInfoSlice from "./slices/taxInfoSlice";
import bankInfoSlice from "./slices/bankInfoSlice";
import generalSettingsSlice from "./slices/generalSettingsSlice";
import yearsSlice from "./slices/yearsSlice";

const persistConfig = {
  key: "goals-and-targets",
  storage,
};

const persistedReducer = persistReducer(persistConfig, generalSettingsSlice);

export const store = configureStore({
  reducer: {
    auth: authSlice,
    countries: countriesSlice,
    userSettings: settingsSlice,
    states: statesSlice,
    industries: industrySlice,
    solutations: solutationSlice,
    businessDetails: businessDetailsSlice,
    personalInfo: personalDetailsSlice,
    taxInfo: taxInfoSlice,
    bankInfo: bankInfoSlice,
    generalSettings: persistedReducer,
    years: yearsSlice,
  },
});

export const persistor = persistStore(store);

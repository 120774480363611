import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  Button,
  Card,
  Spin,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CountriesSelect from "../../sharedModules/verification/CountriesSelect";
import StatesSelect from "../../sharedModules/verification/StatesSelect";
import CitiesSelect from "../../sharedModules/verification/CitiesSelect";
import { useFetchSolutationsData } from "../../hooks/useApiHook";
import { API_SETTINGS_BASE_URL } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { savePersonalDetails } from "../../redux/slices/personalInfoSlice";

const PersonalInformation = ({ current, onNext, onPrev }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.personalInfo);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [companyIdImage, setCompanyIdImage] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  // Fetching Solutations data.
  const { solutations, solutationsLoader, solutationsErr } =
    useFetchSolutationsData(
      `${API_SETTINGS_BASE_URL}/v1/general_settings/salutation/`
    );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCompanyIdImage(file);
    }
  };

  const selectBefore = (
    <Select defaultValue="add" style={{ width: 80 }}>
      <Option value="add">971</Option>
      <Option value="minus">+92</Option>
    </Select>
  );

  const onFinish = (values) => {
    const url = `${API_SETTINGS_BASE_URL}/v1/profile-verfication/personal-info`;

    dispatch(
      savePersonalDetails({ url, raw: { ...values, companyIdImage } })
    ).then((action) => {
      if (savePersonalDetails.fulfilled.match(action)) {
        onNext();
        localStorage.setItem("personalDetails", JSON.stringify(values));
      } else {
        setErrorMsg(
          typeof action.payload === "string"
            ? action.payload
            : "Something went wrong, please try again."
        );
        console.log("Error:", action);
      }
      if (savePersonalDetails.rejected.match(action)) {
        console.log("action ", action);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        onFinish(values);
      })
      .catch((errorInfo) => {
        onFinishFailed(errorInfo);
      });
  };

  useEffect(() => {
    // Retrieve form values from localStorage
    const storedValues =
      JSON.parse(localStorage.getItem("personalDetails")) || null;
    if (storedValues) {
      form.setFieldsValue(storedValues);
    }
  }, [form]);

  return (
    <>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Personal information</h3>
          <p className="mt-6 mb-0 text-black-60">
            Provide account administrator's information - the primary point of
            contact for your enterprise.
          </p>
        </div>
        <div className="card-body">
          <Form layout="vertical" form={form} className="mt-24">
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Row gutter={[24, 0]}>
                  <Col md={6}>
                    <Form.Item label="Salutation" name="solutation">
                      <Select
                        placeholder="Please Select"
                        style={{ position: "rel" }}
                        dropdownRender={(menu) => (
                          <div>
                            {solutationsErr &&
                              "Something went wrong, please try again"}
                            {menu}
                            {solutationsLoader && (
                              <div style={{ textAlign: "center", padding: 8 }}>
                                <Spin />
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {solutations?.length > 0
                          ? solutations?.map((solutation) => (
                              <Select.Option
                                key={solutation.id}
                                value={solutation?.id}
                              >
                                {solutation?.salutation}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={9}>
                    <Form.Item
                      label="First name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Required first name",
                        },
                      ]}
                    >
                      <Input placeholder="eg. John" />
                    </Form.Item>
                  </Col>
                  <Col md={9}>
                    <Form.Item
                      label="Last name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Required last name",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Doe" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Corporate email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Required  corporate email",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="eg. name@company.com" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Company ID number"
                  name="IDnumber"
                  rules={[
                    {
                      required: true,
                      message: "Required  company ID number",
                    },
                  ]}
                >
                  <Input placeholder="eg. 7A367BD" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Company ID image"
                  name="comapanyIdImage"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      overflow: "hidden",
                      borderRadius: "16px",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <Input
                      value={companyIdImage?.name}
                      placeholder="pdf or jpeg; 10 mb"
                      readOnly
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    />
                    <Button
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                      style={{
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Upload
                    </Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Required  phone number",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={selectBefore}
                    placeholder="Enter number"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Personal address (line 1)" name="address1">
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Personal address (line 2)" name="address2">
                  <Input placeholder="eg. Dubai International Financial Center" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <CountriesSelect
                  setCountryId={setCountryId}
                  isPersonalInfo={true}
                />
              </Col>
              <Col md={12}>
                <Form.Item label="Code / P.O. box" name="poBox">
                  <Input placeholder="eg. 29670" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <StatesSelect
                  setStateId={setStateId}
                  countryId={countryId}
                  isPersonalInfo={true}
                />
              </Col>
              <Col md={12}>
                <CitiesSelect stateId={stateId} isPersonalInfo={true} />
              </Col>
              <Col md={24}>
                {errorMsg && (
                  <p
                    style={{
                      color: "#FF4D4F",
                      marginTop: "-5px",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <div
        style={{ marginTop: 24 }}
        className={!current > 0 ? "flex-center-end" : "flex-center-between"}
      >
        {current > 0 && (
          <Button
            shape="round"
            icon={<ArrowLeftOutlined />}
            onClick={onPrev}
            size="large"
          >
            Back
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          size="large"
          onClick={handleNext}
          disabled={loading}
        >
          Continue
          {loading && <Spin tip="Loading" size="small" />}
        </Button>
      </div>
    </>
  );
};

export default PersonalInformation;

import React, { useState } from "react";
import { Button, Card, Drawer, Space } from "antd";
import { ArrowRightOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ReportCards = ({ logo, heading, subtitle, paragraph, isLock }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const openDrawer = () => {};
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card className="inte-card">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                height: 75,
                marginBottom: 6,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img src={logo} style={{ maxHeight: "100%" }} />
            </div>
            <p className="m-0 text-black fs-16 text-merri">{heading}</p>
            {subtitle && <p className="m-0 text-black-60 fs-12">{subtitle}</p>}
            <p className="m-0 fw-500 text-black-60">{paragraph}</p>
          </div>
          <Button
            shape="round"
            icon={isLock ? <LockOutlined /> : <ArrowRightOutlined />}
            iconPosition="end"
            size="large"
            className="btn-h-40 mt-24 fs-14 text-black fw-500 icon-tilt"
            block
            disabled={isLock}
            onClick={() => navigate("/report/report-table")}
          >
            Request report
          </Button>
        </div>
      </Card>
    </>
  );
};

export default ReportCards;

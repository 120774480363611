import React, { useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register required components
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const chartRef = useRef();

  // Chart data
  const data = {
    labels: ["Data 1"],
    datasets: [
      {
        data: [100], // Represents 100% to form a full doughnut
        backgroundColor: ["rgba(255, 255, 255, 0)"], // Transparent inner background
        borderWidth: 20, // Width of the gradient border
        borderColor: "rgba(0, 0, 0, 0)", // Transparent border, we will handle it through a custom gradient
      },
    ],
  };

  // Chart options with gradient border
  const options = {
    cutout: "85%", // Creates a "hole" inside the doughnut
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#000",
        display: false,
      },
      legend: {
        display: false, // Hides the legend
      },
      tooltip: {
        backgroundColor: "#E9EAEC",
        titleColor: "#14151A",
        bodyColor: "rgba(15, 19, 36, 0.6)",
        borderColor: "rgba(20, 21, 26,0.08)",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 14,
          weight: "600",
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue;
          },
          title: function (data) {
            return "";
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 20, // Controls the border width
      },
    },
    animation: {
      onComplete: () => {
        // Create the gradient
        const chart = chartRef.current;
        if (!chart) return;
        const ctx = chart.canvas.getContext("2d");

        const gradient = ctx.createLinearGradient(
          0,
          0,
          chart.width,
          chart.height
        );
        gradient.addColorStop(0, "#D95E28"); // Start color
        gradient.addColorStop(1, "#4778F5"); // End color

        chart.data.datasets[0].borderColor = gradient; // Apply gradient border
        chart.update(); // Update the chart to reflect changes
      },
    },
  };

  return <Doughnut ref={chartRef} data={data} options={options} />;
};

export default DoughnutChart;

import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Badge,
  Flex,
  Card,
  Button,
  Tag,
  Alert,
} from "antd";
import {
  ArrowRightOutlined,
  CheckOutlined,
  StarOutlined,
} from "@ant-design/icons";
import BadgeIcon from "../../../images/badge-icon.png";
import { useNavigate } from "react-router-dom";
import Subscription from "../../verification/Subscription";

const SubscriptionProfile = () => {
  const [chooseUpgrade, setChooseUpgrade] = useState(false);
  const navigate = useNavigate();
  const currentPlan = {
    id: 0,
    team: "Team: 0 - 50",
    planName: "Basic",
    planDesc:
      "Best for enterprises wanting to measure their direct emissions and start their sustainability journey.",
    planPrice: "90",
    fetureTitle: "Features",
    featureList: [
      "Measure– your scope 1 and 2 emissions (GHG accounting). Assign footprint sources (85+ pre-configured) and select from AhyaAI EF Database (10,000+ multi-sectoral emission factors)",
      "Analyze – your emissions by scope, category or facility and access key sustainability indicators (emissions intensity)",
      "Reduce – model your reduction on each category",
      "Report – access your GHG Protocol Inventory Report",
    ],
  };
  return (
    <>
      {chooseUpgrade ? (
        <Subscription isVerified="true" activePackage="true" />
      ) : (
        <Card style={{ marginTop: 24 }}>
          <div className="card-header">
            <Flex justify="space-between" align="center">
              <h3 className="m-0">Current Packages</h3>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={() => setChooseUpgrade(true)}
              >
                Upgrade your subscription plan
              </Button>
            </Flex>
          </div>
          {/* style={{ height: "70vh" }} */}
          <div className="card-body mt-24">
            <div className="single-plan">
              <div className="plan-head">
                <div className="flex-center-between">
                  <Tag>
                    <img src={BadgeIcon} /> {currentPlan.team}
                  </Tag>
                </div>
                <Flex align="start" className="mt-8" gap="middle">
                  <h4 className="m-0">{currentPlan.planName}</h4>
                  <div className="price-sec m-0">
                    {currentPlan.crossPrice && (
                      <h4 className="m-0">{currentPlan.crossPrice}</h4>
                    )}
                    <h2 className="m-0">{currentPlan.planPrice}</h2>
                    <Badge
                      count="USD"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                </Flex>
              </div>
              <div className="plan-body">
                <p className="mt-0 fw-600">{currentPlan.fetureTitle}</p>
                <ul>
                  {currentPlan.featureList.map((list) => {
                    return (
                      <li>
                        <CheckOutlined />
                        {list}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <Flex className="mt-24">
              <Alert
                style={{ border: 0, background: "rgba(230, 72, 61,0.1)" }}
                message={
                  <>
                    <h4 className="m-0 fs-18 text-black-60 fw-500 ff-mont">
                      Term:{" "}
                      <strong className="fw-500 text-black">Monthly</strong>
                    </h4>
                    <h4 className="m-0 fs-16 text-black-60 fw-400 ff-mont">
                      Auto-renew 1st Dec, 2022
                    </h4>
                  </>
                }
                type="error"
              />
            </Flex>
          </div>
          <div className="card-footer mt-24">
            <Flex justify="flex-end">
              <Button shape="round" className="btn-h-40">
                Cancel your Subscription
              </Button>
            </Flex>
          </div>
        </Card>
      )}
    </>
  );
};

export default SubscriptionProfile;

import React, { Component } from "react";
// import 'antd/dist/antd.css';
// import './App.scss';
import "./Styles.scss";
import MyRoutes from "./Routes";
import { ConfigProvider } from "antd";

class App extends Component {
  render() {
    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Montserrat",
            colorPrimary: "#EB632C",
          },
          components: {
            Layout: {
              headerPadding: "16px 32px",
              headerBg: "#fff",
              headerHeight: 80,
            },
            Tabs: {
              itemActiveColor: "#14151A",
              itemSelectedColor: "#14151A",
              itemHoverColor: "#14151A",
              itemColor: "rgba(15, 19, 36,.6)",
            },
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 40,
            },
            Slider: {
              dotSize: 8,
              handleSize: 8,
              railSize: 2,
              dotBorderColor: "#000",
              dotActiveBorderColor: "#000",
              handleActiveColor: "#000",
              handleActiveOutlineColor: "#000",
              handleColor: "#000",
              trackBg: "#000",
              railHoverBg: "#000",
            },
            Table: {
              headerBg: "#F7F7F8",
              headerColor: "#14151A",
              cellPaddingBlock: 10,
              cellPaddingInline: 12,
              headerSplitColor: "transparent",
              rowSelectedBg: "transparent",
              rowSelectedHoverBg: "transparent",
            },
          },
        }}
      >
        <MyRoutes />
      </ConfigProvider>
    );
  }
}

export default App;

import React from "react";
import { Table, Space, Switch, Button } from "antd";

const AccountSettings = () => {
  // const toggleActivity = () => {
  //   setCheckedSwitch(!checkedSwitch);
  // };

  const columns = [
    {
      title: "Account Id",
      dataIndex: "accountId",
      key: "accountId",
      render: (accountId, record) => (
        <>
          <p className="m-0 text-black fw-400">{accountId}</p>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "desc",
      key: "desc",
      render: (desc, record) => (
        <>
          <p className="m-0">{desc}</p>
          <p className="m-0 text-black-60 fw-400">{record.descContent}</p>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (action, record) => (
        <>
          <Button shape="round" className="btn-h-40 fw-500">
            <Space>
              <Switch defaultChecked={action === "Enabled" ? true : false} />
              <p className="m-0 fs-14 fw-500 text-black">{action}</p>
            </Space>
          </Button>
        </>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      accountId: "20240412-0001",
      desc: "Integration Hub",
      descContent:
        "When enabled, users can use Integration Hub to create new integrations and connections, update integration settings, and run integrations.",
      action: "Disabled",
    },
    {
      key: "2",
      accountId: "20240412-0001",
      desc: "Approvals",
      descContent:
        "When enabled, users can use Integration Hub to create new integrations and connections, update integration settings, and run integrations.",
      action: "Enabled",
    },
  ];
  return (
    <>
      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 230px)" }}
      >
        <Table dataSource={data} pagination={false} columns={columns}></Table>
      </div>
    </>
  );
};

export default AccountSettings;

import React, { useState } from "react";
import CatalogLayout from "./CatalogLayout";
import {
  Flex,
  Space,
  Button,
  Dropdown,
  Switch,
  Input,
  Table,
  Drawer,
  Form,
  Select,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import AddCatalog from "./addCatalog";
import Search from "../../../images/search.svg";

const Catalog = () => {
  const equipmentData = {
    type: "Equipment",
    content: [
      "Equipment includes objects, tools, and supplies used in an organization's operations that may create emissions.",
      "Uploading Equipment Types to Persefoni allows you to more accurately track which types of equipment are responsible for which emissions.",
    ],
  };
  const [equipmentModalData, setEquipmentModalData] = useState(equipmentData);
  const [open, setOpen] = useState(false);
  const [addManually, setAddManually] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
      width: 660,
      sorter: (a, b) => a.equipment - b.equipment,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 300,
    },
    {
      title: "Item Source",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
      sorter: (a, b) => a.itemSource - b.itemSource,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Turbine",
      category: "Refrigeration Equipment",
      itemSource: "Account",
    },
    {
      key: "2",
      equipment: "Domestic Refrigeration",
      category: "Stationary Combustion Equipment",
      itemSource: "Default",
    },
    {
      key: "3",
      equipment: "Generator",
      category: "Refrigeration Equipment",
      itemSource: "Default",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const onClose = () => {
    setOpen(false);
    setAddManually(false);
  };
  const addManully = () => {
    setAddManually(true);
  };
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center" gap="middle">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Equipment</h4>
        </Space>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Export
          </Button>
        </Dropdown>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        className="mt-24"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500 }}
        />
        <Space size={[20, 20]}>
          <Space>
            <Switch />
            <p className="m-0">Show archived</p>
          </Space>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            type="primary"
            className="btn-h-40 fw-500"
            onClick={() => setOpen(true)}
          >
            Add Equipment
          </Button>
        </Space>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 400px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>

      <Drawer
        className="footprintDrawer"
        title="Add Equipment"
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <AddCatalog
          onClose={onClose}
          equipmentModalData={equipmentModalData}
          addManully={addManully}
        />
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="Add Equipment"
        onClose={onClose}
        open={addManually}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" className="btn-h-40" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
              onClick={onClose}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <div className="activity-row">
          <div className="activity-head">
            <h5 className="m-0 text-black">Equipment type details</h5>
          </div>
          <div className="activity-body">
            <Form layout="vertical">
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Required category",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Equipment type name"
                name="equipment"
                rules={[
                  {
                    required: true,
                    message: "Required equipment",
                  },
                ]}
              >
                <Input placeholder="Cooler" />
              </Form.Item>
              <Form.Item
                label="Installation Emissions"
                name="installation"
                rules={[
                  {
                    required: true,
                    message: "Required installation emissions",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
              <Form.Item
                label="Operating Emissions (Per year)"
                name="operating"
                rules={[
                  {
                    required: true,
                    message: "Required operating emissions",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
              <Form.Item
                label="Refrigerant remaining at disposal"
                name="refrigerant"
                rules={[
                  {
                    required: true,
                    message: "Required refrigerant remaining",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
              <Form.Item
                label="Refrigerant remaining at disposal (Remaining percentage)"
                name="disposal"
                rules={[
                  {
                    required: true,
                    message: "Required Refrigerant remaining at disposal",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </CatalogLayout>
  );
};

export default Catalog;

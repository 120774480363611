import { Form, Select, Spin } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useFetchCountriesData from "../../hooks/useApiHook";
import { API_SETTINGS_BASE_URL } from "../../config";

const CountriesSelect = ({ setCountryId, isPersonalInfo }) => {
  const [countriesPage, setCountriesPage] = useState(0);

  const { countries, countryErr, coutryLoader } = useFetchCountriesData(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/countries/?skip=${
      countriesPage * 20
    }&limit=20`
  );
  return (
    <>
      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: "Required  country",
          },
        ]}
      >
        <Select
          placeholder="Please Select"
          style={{ position: "rel" }}
          dropdownRender={(menu) => (
            <div>
              {countryErr && "Something went wrong, please try again"}
              {menu}
              {coutryLoader && (
                <div style={{ textAlign: "center", padding: 8 }}>
                  <Spin />
                </div>
              )}
              {!coutryLoader && (
                <div style={{ textAlign: "center", padding: 8 }}>
                  <NavLink
                    onClick={() => {
                      if (countries?.length > 0) {
                        setCountriesPage((prevPage) => prevPage + 1);
                      } else {
                        setCountriesPage(0);
                      }
                    }}
                  >
                    {countries?.length > 0 ? "Load more" : "Back"}
                  </NavLink>
                </div>
              )}
            </div>
          )}
          onSelect={(selected) => {
            if (isPersonalInfo) {
              let result = countries?.find(
                (country) => country?.name === selected
              );
              setCountryId(result?.id);
            } else {
              setCountryId(selected);
            }
          }}
        >
          {countries?.length > 0
            ? countries?.map((country) => (
                <Select.Option
                  key={country.id}
                  value={isPersonalInfo ? country?.name : country?.id}
                >
                  {country?.name}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </>
  );
};

export default CountriesSelect;

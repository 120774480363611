import React from "react";
import Logo from "../../images/logo.png";

const AuthLayout = (props) => {
  return (
    <div className="auth-layout">
      <div className="auth-img">
        <div className="auth-logo">
          <img src={Logo} alt="logo" />
        </div>
        <h1>AI-enabled GHG emissions operating system for enterprises.</h1>
      </div>
      <div className="auth-content">
        <main>{props.children}</main>
      </div>
    </div>
  );
};

export default AuthLayout;

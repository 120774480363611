import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  Space,
  Slider,
  Input,
  Button,
  Flex,
} from "antd";
import { SyncOutlined, ArrowDownOutlined } from "@ant-design/icons";

const ReduceCollapseBody = ({ data }) => {
  const [sliderValues, setSliderValues] = useState(data.map(() => 0)); // Separate state for each slider/input

  // Function to handle slider change for each slider
  const handleSliderChange = (index, value) => {
    const newValues = [...sliderValues];
    newValues[index] = value; // Update the value for the specific slider
    setSliderValues(newValues);
  };

  // Function to handle input change for each input
  const handleInputChange = (index, e) => {
    const newValue = e.target.value === "" ? 0 : Number(e.target.value);
    const newValues = [...sliderValues];
    newValues[index] = newValue; // Update the value for the specific input
    setSliderValues(newValues);
  };

  return (
    <>
      <Row align="middle" className="py-2" gutter={[16, 8]}>
        <Col md={9}>
          <p className="m-0 text-black fs-12 fw-600">Categories</p>
        </Col>
        <Col md={9}>
          <p className="m-0 text-black fs-12 fw-600">
            % of currency scope 1 emissions decrease
          </p>
        </Col>
        <Col md={6}>
          <div className="text-right">
            <p className="m-0 text-black fs-12 fw-600">Modeled emissions</p>
          </div>
        </Col>
      </Row>
      {data.map((val, idx) => (
        <Card className="mb-2 body-pad-16" key={idx}>
          <Row align="middle" gutter={[16, 8]}>
            <Col md={9}>
              <Badge
                count={val.recommended}
                style={{
                  backgroundColor: "#fff",
                  color: "rgba(15, 19, 36, 0.6)",
                  fontWeight: "500",
                  fontSize: 12,
                  borderColor: "#0A0F2914",
                  borderRadius: "6px",
                }}
              />
              <p className="text-black fw-600 m-0 py-2">{val.title}</p>
              <p className="m-0 fs-10">{val.currentEmission}</p>
            </Col>
            <Col md={9}>
              <Space direction="vertical" className="w-100">
                <Slider
                  min={1}
                  value={sliderValues[idx]} // Correct slider value binding
                  onChange={(value) => handleSliderChange(idx, value)}
                />
                <div>
                  <Flex gap="middle" className="w-100">
                    <Input
                      value={sliderValues[idx]} // Correct input value binding
                      onChange={(e) => handleInputChange(idx, e)}
                      suffix={
                        <Badge
                          count="%"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.8)",
                            padding: "0px 10px",
                            borderRadius: "6px",
                            fontSize: 14,
                            height: "auto",
                          }}
                        />
                      }
                    />
                    <SyncOutlined />
                  </Flex>
                </div>
                <p className="text-black fw-400 m-0 fs-10">
                  % of decrease of emissions
                </p>
              </Space>
            </Col>
            <Col md={6}>
              <Flex justify="end" align="middle" gap="middle" className="w-100">
                <Button
                  type="primary"
                  shape="circle"
                  className="btn-h-40"
                  icon={<ArrowDownOutlined />}
                />
                <div className="text-right">
                  <h4 className="m-0 fw-600 ff-mont">{val.scopeCount}</h4>
                  <p className="m-0 text-black fs-12 fw-400">tCO₂e</p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};

export default ReduceCollapseBody;

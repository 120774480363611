import React, { useState } from "react";
import {
  Layout,
  Button,
  Tabs,
  Badge,
  Switch,
  Space,
  Form,
  Radio,
  Drawer,
  Row,
  Col,
  Divider,
  List,
  Tag,
  Table,
  Dropdown,
  Select,
  DatePicker,
  Input,
  Upload,
  Steps,
  Card,
  Flex,
} from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import { useNavigate } from "react-router-dom";
import {
  SettingOutlined,
  CloseOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  EditOutlined,
  ArrowRightOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import CategoryIcon from "../../../images/list-settings-line.png";
import ScopeRow from "../../../sharedModules/ScopeRow";
import mailAdd from "../../../images/mail-add.svg";
import assignee from "../../../images/assignee.png";
import footprint from "../../../images/footprint.png";
import scope from "../../../images/scope.png";
import status from "../../../images/status.png";
import updated from "../../../images/updated.png";

const { Header } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const Footprint = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [openFootprint, setOpenFootprint] = useState(false);
  const [handleASValue, setHandleASValue] = useState("");
  const [valueCM, setValueCM] = useState("");
  const [valueEFS, setValueEFS] = useState("");
  const [tabThree, setTabThree] = useState(false);

  const navigate = useNavigate();
  const handleButton = (link) => {
    navigate(link);
  };

  const columns = [
    {
      title: "Activity ID",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
    },
    {
      title: "Footprint source",
      dataIndex: "footprintSource",
      key: "footprintSource",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
    },
    {
      title: "Calculation framework",
      dataIndex: "calculationFramework",
      key: "calculationFramework",
    },
    {
      title: "Calculation method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
    },
    {
      title: "CO₂e emission",
      dataIndex: "emission",
      key: "emission",
    },
  ];

  const data = [
    {
      key: "1",
      name: "--",
      organization: "--",
      category: "--",
      subcategory: "--",
      footprintSource: "--",
      scope: "--",
      calculationFramework: "--",
      calculationMethod: "--",
      emission: "--",
    },
  ];

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const DescDrawer = () => {
    setOpen(true);
  };

  const onTabChange = (e) => {
    if (e === "3") {
      setTabThree(true);
    } else {
      setTabThree(false);
    }
  };

  const addFootprint = () => {
    setOpenFootprint(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCloseFootprint = () => {
    setOpenFootprint(false);
  };

  const items = [
    {
      label: "Assignee",
      key: "0",
    },
    {
      label: "Category",
      key: "1",
    },
  ];

  const menus = [
    {
      label: "Internal request",
      key: "2",
    },
    {
      label: "External request",
      key: "3",
    },
  ];
  const extraContent = (
    <Space align="center" size={20}>
      <Space align="center">
        <Switch onChange={onChange} />
        <p className="m-0">Show Completed</p>
      </Space>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <p
          className="m-0 cursor-pointer"
          style={{ display: "flex", alignItems: "center", gap: 5 }}
        >
          <img src={CategoryIcon} />
          Category
        </p>
      </Dropdown>
      {/* <Button type="text">
        
      </Button> */}
    </Space>
  );

  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const optionsAS = [
    { value: "EC", label: "External Calculation" },
    { value: "GGP", label: "Greenhouse Gas Protocol" },
  ];

  const handleASChange = (val) => {
    setHandleASValue(val);
  };

  const onChangeCM = (val) => {
    setValueCM(val.target.value);
  };
  const onChangeEFS = (val) => {
    setValueEFS(val.target.value);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        onCloseFootprint();
        onClose();
      })
      .catch((errorInfo) => {
        // onFinishFailed(errorInfo);
      });
  };

  // Scope Card (separate for 3 scopes) Data arrays:

  const ScopeRowCard1 = [
    {
      id: 0,
      label: "Mobile Combustion",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 1,
      label: "Associated Gas Venting and Flaring",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 2,
      label: "Atmospheric Storage Tanks",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 3,
      label: "Blowdown Vent Stacks",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 4,
      label: "Centrifugal Compressors",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 5,
      label: "Combustion Drilling and Completion Engines",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 6,
      label: "Combustion Equipment",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 7,
      label: "Completions and Workovers with Hydraulic Fracturing",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 8,
      label: "Dehydrators",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 9,
      label: "Enhanced Oil Recovery Hydrocarbon Liquids",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 10,
      label: "Enhanced Oil Recovery Injection Pumps",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 11,
      label: "Equipment Leaks Surveys and Population Counts",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 12,
      label: "Flare Stacks",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 13,
      label: "Fugitive Emissions - Industrial Gases",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 14,
      label: "Fugitive Emissions - Refrigeration",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 15,
      label: "Mobile Combustion - Aircraft",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 16,
      label: "Natural Gas Driven Pneumatic Pumps",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 17,
      label: "Natural Gas Pneumatic Devices",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 18,
      label: "Reciprocating Compressors",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 19,
      label: "Transmission Storage Tanks",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 20,
      label: "Well Testing",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 21,
      label: "Well Venting for Liquids Unloading",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 22,
      label: "Production of Cement",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 23,
      label: "Flare Stacks",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
  ];

  const ScopeRowCard2 = [
    {
      id: 0,
      label: "Purchased Electricity - Fleet On Site Fueling",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 1,
      label: "Purchased Electricity - Fleet On Remote Fueling",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 2,
      label: "Direct Line Energy Transfer",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 3,
      label: "Purchased Cooling",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 4,
      label: "Purchased Cooling",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 5,
      label: "Purchased Electricity - Facility",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
  ];

  const ScopeRowCard3 = [
    {
      id: 0,
      label: "Business-Loan - Unlisted (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 1,
      label: "Downstream Leased Assets - Aircraft (Category 13)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 2,
      label: "Downstream Leased Assets - Ground Transportation (Category 13)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 3,
      label: "Upstream Leased Assets - Aircraft (Category 8)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 4,
      label: "Upstream Leased Assets - Equipment (Category 8)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 5,
      label: "Purchased Electricity - Fleet On Site Fueling",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 6,
      label: "Upstream Leased Assets - Ground Transportation (Category 8)",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 7,
      label: "Business Loan - Listed (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 8,
      label: "Business Loan - Unlisted (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 9,
      label: "Commercial Real Estate (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 10,
      label: "Corporate Bond - Unlisted (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 11,
      label: "Equity - Listed (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 12,
      label: "Equity - Unlisted (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 13,
      label: "Mortgages (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 14,
      label: "Motor Vehicle Loan (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 15,
      label: "Project Finance - Debt (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 16,
      label: "Project Finance - Equity (Category 15)",
      category: "Financed Emissions",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 17,
      label: "Bus Transportation (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 18,
      label: "Business Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 19,
      label: "Commercial Air Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 20,
      label: "Employee Commuting (Category 7)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 21,
      label: "Employee Commuting/Telework (Category 7)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 22,
      label: "Hotel Stay (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 23,
      label: "Number of Employees (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 24,
      label: "Personal Vehicle (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 25,
      label: "Private Air Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 26,
      label: "Rail Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 27,
      label: "Rental Car (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 28,
      label: "Ride Share (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 29,
      label: "Subway/Tram Transportation (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 30,
      label: "Taxi (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 31,
      label: "Telework (Category 7)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 32,
      label: "Water Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 33,
      label: "Private Air Travel (Category 6)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 34,
      label: "Downstream Leased Assets - Facility (Category 13)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 35,
      label: "Upstream Leased Assets - Facility (Category 8)",
      category: "Assets",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 36,
      label: "Waste (Category 5)",
      category: "Sites",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 37,
      label: "Capital Goods (Category 2)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 38,
      label: "Downstream Processing of Sold Products (Category 10)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 39,
      label: "Downstream Transportation and Distribution (Category 9)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 40,
      label: "Electricity T&D Losses (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 41,
      label: "End of Life Treatment of Sold Products (Category 12)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 42,
      label: "Franchises (Category 14)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 43,
      label: "Generation of Purchased Electricity Sold to End Users (Category 3)",
      category: "People Activity",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 44,
      label: "Heat & Steam T&D Losses (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 45,
      label: "Purchased Goods and Services (Category 1)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 46,
      label: "Upstream Purchased Electricity (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 47,
      label: "Upstream Purchased Fuels - Mobile Combustion (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 48,
      label: "Upstream Purchased Fuels - Stationary Combustion (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 49,
      label: "Upstream Purchased Heat & Steam (Category 3)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 50,
      label: "Upstream Transportation and Distribution (Category 4)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    },
    {
      id: 51,
      label: "Use of Sold Products: Direct Use Phase (Category 11)",
      category: "Supply Chain",
      checmical: "tCO₂e",
      checmicalVal: "--",
      records: "Records",
      recordsVal: "--",
      request: "Request",
      requestVal: "--",
    }
  ];
  
  
  
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Footprint source checklist</h4>
        <Button
          type="gray"
          shape="round"
          icon={<SettingOutlined />}
          iconPosition="start"
          size="default"
          className="btn-h-40"
          onClick={() => handleButton("/measure/footprint/configure-checklist")}
        >
          Configure Checklist
        </Button>
      </Header>
      <div className="main-content">
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={extraContent}
          onChange={onTabChange}
        >
          <TabPane
            tab={
              <span>
                <span style={{ marginRight: 8, fontWeight: 500 }}>Scope 1</span>
                <Badge
                  count="85"
                  style={{
                    backgroundColor: "rgba(10, 15, 41, 0.04)",
                    color: "#14151A",
                    fontWeight: "500",
                    fontSize: 12,
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                ></Badge>
              </span>
            }
            key="1"
          >
            <p className="fw-500 m-0">
              Scope 1 footprint sources are greenhouse gases directly from
              operations that are owned or controlled by the reporting company.
            </p>

            <Space
              size={16}
              direction="vertical"
              style={{ width: "100%", marginTop: 16 }}
            >
              {ScopeRowCard1.map((val, key) => {
                return <ScopeRow ScopeRowCard={val} DescDrawer={DescDrawer} />;
              })}
            </Space>
          </TabPane>
          <TabPane
            tab={
              <span>
                <span style={{ marginRight: 8, fontWeight: 500 }}>Scope 2</span>
                <Badge
                  count="6"
                  style={{
                    backgroundColor: "rgba(10, 15, 41, 0.04)",
                    color: "#14151A",
                    fontWeight: "500",
                    fontSize: 12,
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                ></Badge>
              </span>
            }
            key="2"
          >
            <p className="fw-500 m-0">
            Scope 2 emissions are indirect emissions from generation of purchased electricity, steam, heating or cooling consumed by the enterprise.
            </p>

            <Space
              size={16}
              direction="vertical"
              style={{ width: "100%", marginTop: 16 }}
            >
              {ScopeRowCard2.map((val, key) => {
                return <ScopeRow ScopeRowCard={val} DescDrawer={DescDrawer} />;
              })}
            </Space>
          </TabPane>
          <TabPane
            tab={
              <span>
                <span style={{ marginRight: 8, fontWeight: 500 }}>Scope 3</span>
                <Badge
                  count="51"
                  style={{
                    backgroundColor: "rgba(10, 15, 41, 0.04)",
                    color: "#14151A",
                    fontWeight: "500",
                    fontSize: 12,
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                ></Badge>
              </span>
            }
            key="3"
          >
            <p className="fw-500 m-0">
            Scope 3 emissions are indirect emissions along a company’s value chain from upstream and downstream activities that are not owned or controlled by the enterprise. Scope 3 emissions are divided across 15 categories that are mutually exclusive.
            
            </p>

            <div className="steps-wizard">
              <Form layout="vertical" className="mt-24">
                <Radio.Group buttonStyle="solid" size="large">
                  <Radio.Button value="a">All</Radio.Button>
                  <Radio.Button value="b">Upstream</Radio.Button>
                  <Radio.Button value="c">Downstream</Radio.Button>
                </Radio.Group>
              </Form>
            </div>

            <Space
              size={16}
              direction="vertical"
              style={{ width: "100%", marginTop: 16 }}
            >
              {ScopeRowCard3.map((val, key) => {
                return <ScopeRow ScopeRowCard={val} DescDrawer={DescDrawer} />;
              })}
            </Space>

          </TabPane>
        </Tabs>
      </div>
      <Drawer
        className="footprintDrawer"
        title={<h4 className="m-0">Mobile combustion</h4>}
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Dropdown menu={{ items: menus }}>
                <Button
                  shape="round"
                  icon={<img src={mailAdd} />}
                  iconPosition="end"
                  className="btn-h-40 fw-500"
                >
                  Create Request
                </Button>
              </Dropdown>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={addFootprint}
              >
                Add Footprint Activity
              </Button>
            </Col>
          </Row>
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
            <p className="m-0 px-40">
              Mobile combustion is the burning of fuels by transportation
              devices such as cars, trucks, trains, or airplanes owned or
              controlled by the company.
            </p>
            <Divider />
            <Row>
              <Col md={12} className="text-center">
                <h3 className="m-0">--</h3>
                <p className="mt-0">
                  tCO<sub>2</sub>e
                </p>
                <Badge
                  count="View all activities"
                  style={{
                    backgroundColor: "#E9EAEC",
                    color: "rgba(15, 19, 36, 1)",
                    fontWeight: 500,
                  }}
                />
              </Col>
              <Col md={12} className="text-center">
                <h3 className="m-0">--</h3>
                <p className="mt-0">Records</p>
                <Badge
                  count="View all activities"
                  style={{
                    backgroundColor: "#E9EAEC",
                    color: "rgba(15, 19, 36, 1)",
                    fontWeight: 500,
                  }}
                />
              </Col>
            </Row>
            <Divider style={{ marginBottom: 0 }} />
            <List itemLayout="horizontal">
              <List.Item
                actions={[
                  <Space>
                    <Tag
                      color="warning"
                      style={{
                        color: "#AE590A",
                        fontSize: 14,
                        fontWeight: 500,
                        background: "#FDEAD8",
                        border: 0,
                        borderRadius: 6,
                        padding: "2px 5px",
                      }}
                    >
                      <ClockCircleOutlined style={{ marginRight: 3 }} /> In
                      progress
                    </Tag>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      iconPosition="start"
                      // style={{ padding: "10px 12px", height: 40 }}
                    >
                      Edit
                    </Button>
                  </Space>,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={status} />}
                  title="Status"
                />
              </List.Item>
              <List.Item
                actions={[
                  <Space>
                    <Tag
                      color="error"
                      style={{
                        color: "#9A1C13",
                        fontSize: 12,
                        fontWeight: 500,
                        background: "#FCE5E4",
                        border: 0,
                        borderRadius: 6,
                        padding: "2px 8px",
                      }}
                    >
                      Unassigned
                    </Tag>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      iconPosition="start"
                      // style={{ padding: "10px 12px", height: 40 }}
                    >
                      Edit
                    </Button>
                  </Space>,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={assignee} />}
                  title="Assignee"
                />
              </List.Item>
              <List.Item actions={[<p className="m-0 fw-600 text-black">1</p>]}>
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={scope} />}
                  title="Scope"
                />
              </List.Item>
              <List.Item
                actions={[<p className="m-0 fw-600 text-black">Assets</p>]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={footprint} />}
                  title="Footprint Category"
                />
              </List.Item>
              <List.Item
                actions={[<p className="m-0 fw-600 text-black">--</p>]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={updated} />}
                  title="Last Updated"
                />
              </List.Item>
            </List>
          </TabPane>
          <TabPane tab="CO₂e activity ledger" key="2">
            <div className="table-footprint">
              <Table columns={columns} dataSource={data} pagination={false} />
            </div>
          </TabPane>
          <TabPane tab="Uploads" key="3">
            <div className="px-40 mt-2">
              <Card>
                <FileTextOutlined />
                <p className="mt-2 mb-2 fw-500 text-black">
                  GHGP - Fleet - Mobile Combustion - Distance - Based.xlsx
                </p>
                <Flex justify="space-between" align="center" gap="middle">
                  <p className="m-0 fs-12 text-black-60">
                    <span className="fw-700">Uploaded</span> 05-17-2024 10:47 AM
                  </p>
                  <p className="m-0 fs-12 text-black-60">
                    <span className="fw-700">Uploader</span>{" "}
                    john@acmecorporation.com
                  </p>
                </Flex>
              </Card>
            </div>
          </TabPane>
          <TabPane tab="Activity" key="4">
            <div className="px-40 mt-2">
              <Steps progressDot direction="vertical" current={-1}>
                <Steps.Step
                  title="08:00 AM"
                  description={
                    <p className="m-0 fw-600">
                      Ticket created{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
                <Steps.Step
                  title="04:43 PM"
                  description={
                    <p className="m-0 fw-600">
                      New sheet uploaded{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
                <Steps.Step
                  title="05:11 PM"
                  description={
                    <p className="m-0 fw-600">
                      Activity Ledge Updated{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
              </Steps>
            </div>
          </TabPane>
        </Tabs>
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title={<h4 className="m-0">Create activity</h4>}
        onClose={onCloseFootprint}
        open={openFootprint}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onCloseFootprint}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button shape="round" className="btn-h-40">
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={handleNext}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <div className="activity-row">
            <div className="activity-body">
              <div className="mt-0">
                <Form.Item
                  label="Organization"
                  name="organization"
                  rules={[
                    {
                      required: true,
                      message: "Required organization",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Activity footprint</h5>
            </div>
            <div className="activity-body">
              <p className="m-0 mb-8 fw-600">Footprint Source</p>
              <p className="m-0">
                Don't see the Footprint Source you're looking for?{" "}
                <span style={{ color: "#D95E28" }}>
                  Review and configure your Footprint Source Checklist
                </span>{" "}
                to add more Footprint Sources.
              </p>
              <div className="mt-24">
                <Form.Item
                  label="Footprint source"
                  name="footprintSource"
                  rules={[
                    {
                      required: true,
                      message: "Required footprint source",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">File attachment</h5>
            </div>
            <div className="activity-body pb-24">
              <p className="m-0 mb-8">
                Attach a file of original source material related to this
                activity entry. Some examples include invoices and energy bills.
              </p>
              <p className="m-0">
                Supported files: pdf, doc, docx, csv, xls, xlsx, jpeg, png{" "}
                <br />
                Maximum file size: 10mb
              </p>
              <div className="drager-upload">
                <Dragger>
                  <p className="ant-upload-text">Drop, Browse, or Import</p>
                </Dragger>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Activity standard</h5>
            </div>
            <div className="activity-body">
              <div className="mt-0">
                <Form.Item
                  label="Accounting Standard"
                  name="as"
                  rules={[
                    {
                      required: true,
                      message: "Required accounting standard",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    options={optionsAS}
                    onChange={handleASChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {handleASValue === "EC" ? (
            <>
              <div className="activity-row">
                <div className="activity-head">
                  <h5 className="m-0 text-black">Calculation method</h5>
                </div>
                <div className="activity-body">
                  <div className="mt-0">
                    <Form.Item
                      label="Calculation Method*"
                      name="cm"
                      rules={[
                        {
                          required: true,
                          message: "Required accounting standard",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChangeCM} value={valueCM}>
                        <Space direction="vertical">
                          <Radio value="reportedEmission">
                            Custom Footprint Source: Reported Emissions
                          </Radio>
                          <Radio value="reportedTotalEmission">
                            Custom Footprint Source: Reported Total Emissions
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {valueCM && (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Location</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Location" name="location">
                          <Select
                            placeholder="Please Select"
                            options={optionsAS}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">
                        Global Warming Potential Set
                      </h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item
                          label="Global warming potential set"
                          name="gwps"
                        >
                          <Select
                            placeholder="Please Select"
                            options={optionsAS}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emissions Information</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="CO₂ emissions"
                            name="emissionCo"
                            rules={[
                              {
                                required: true,
                                message: "Required emissionCo",
                              },
                            ]}
                          >
                            <Input placeholder="Write here" />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="CO₂ Emissions UOM"
                            name="emissionUOM"
                            rules={[
                              {
                                required: true,
                                message: "Required emissionUOM",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please Select"
                              options={optionsAS}
                            />
                          </Form.Item>
                        </Col>
                        {valueCM === "reportedEmission" ? (
                          <>
                            <Col md={12}>
                              <Form.Item
                                label="CH₄ Emissions"
                                name="emissionCo1"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required emissionCo",
                                  },
                                ]}
                              >
                                <Input placeholder="Write here" />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                label="CH₄ Emissions UOM"
                                name="emissionUOM1"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required emissionUOM",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Please Select"
                                  options={optionsAS}
                                />
                              </Form.Item>
                            </Col>
                            {tabThree ? (
                              <>
                                <Col md={12}>
                                  <Form.Item
                                    label="N₂O Emissions"
                                    name="emissionCo2"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionCo",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Write here" />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="N₂O Emissions UOM"
                                    name="emissionUOM2"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionUOM",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please Select"
                                      options={optionsAS}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="SF6 Emissions"
                                    name="emissionCo3"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionCo",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Write here" />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="SF6 Emissions UOM"
                                    name="emissionUOM3"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionUOM",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please Select"
                                      options={optionsAS}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="NF3 Emissions"
                                    name="emissionCo4"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionCo",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Write here" />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="NF3 Emissions UOM"
                                    name="emissionUOM4"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionUOM",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please Select"
                                      options={optionsAS}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="Unspecified GHG Emissions"
                                    name="emissionCo5"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionCo",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Write here" />
                                  </Form.Item>
                                </Col>
                                <Col md={12}>
                                  <Form.Item
                                    label="Unspecified GHG Emissions UOM"
                                    name="emissionUOM5"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required emissionUOM",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please Select"
                                      options={optionsAS}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Reference Data</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="External Data Source" name="eds">
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item
                          label="External Calculation Method"
                          name="ecm"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item
                          label="External Emission Factor Set"
                          name="eefs"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        {valueCM === "reportedTotalEmission" ? (
                          <>
                            <Form.Item
                              label="Reported Emissions Global Warming Potential Set"
                              name="regps"
                            >
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item
                              label="External Global Warming Potential Value"
                              name="egwpv"
                            >
                              <Input placeholder="Write here" />
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                        <Form.Item
                          label="External Documentation Reference"
                          name="edr"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item label="External Activity ID" name="eds1">
                          <Input placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Comments</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Comments" name="comments">
                          <TextArea maxLength={100} placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : handleASValue === "GGP" ? (
            <>
              <div className="activity-row">
                <div className="activity-head">
                  <h5 className="m-0 text-black">Calculation method</h5>
                </div>
                <div className="activity-body">
                  <div className="mt-0">
                    <Form.Item
                      label="Calculation Method*"
                      name="cm"
                      rules={[
                        {
                          required: true,
                          message: "Required accounting standard",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChangeCM} value={valueCM}>
                        <Space direction="vertical">
                          <Radio value="distanceBased">Distance-Based</Radio>
                          <Radio value="fuelBased">Fuel-Based: Fuel Type</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {valueCM === "fuelBased" ? (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emission Factor Source</h5>
                    </div>
                    <div className="activity-body">
                      <Form.Item
                        label="Emission Factor Source"
                        name="efs"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value="distanceBased">
                              Customer-Managed
                            </Radio>
                            <Radio value="fuelBased">AhyaOS-Managed</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Comments</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Comments" name="comments">
                          <TextArea maxLength={100} placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </>
              ) : valueCM === "distanceBased" ? (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emission Factor Source</h5>
                    </div>
                    <div className="activity-body">
                      <Form.Item
                        label="Emission Factor Source"
                        name="efs"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group onChange={onChangeEFS} value={valueEFS}>
                          <Space direction="vertical">
                            <Radio value="cusManage">Customer-Managed</Radio>
                            <Radio value="ahyaManage">AhyaOS-Managed</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                  {valueEFS === "ahyaManage" ? (
                    <>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Emission Factor Set
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Emission Factor Set" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Mobile Combustion Details
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item
                              label={
                                <span>
                                  Vehicle Type{" "}
                                  <span
                                    style={{ color: "#0D1126", opacity: 0.4 }}
                                  >
                                    (Vehicle type is based on emission factor
                                    set)
                                  </span>{" "}
                                </span>
                              }
                              name="gwps"
                            >
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Fuel Name" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Row gutter={[16]}>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Input placeholder="Write here" />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Select
                                    placeholder="Please Select"
                                    options={optionsAS}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Vehicle Details</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="VIN" name="vin">
                              <Input placeholder="234" />
                            </Form.Item>
                            <Form.Item label="Internal Identifier" name="iD">
                              <Input placeholder="234" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Comments</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Comments" name="comments">
                              <TextArea
                                maxLength={100}
                                placeholder="Write here"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : valueEFS === "cusManage" ? (
                    <>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Emission Factor Set
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Emission Factor Set" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Emission Factor Name" name="efm">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item
                              label={
                                <span>
                                  Emission Factor{" "}
                                  <span
                                    style={{ color: "#0D1126", opacity: 0.4 }}
                                  >
                                    (Description)
                                  </span>
                                </span>
                              }
                              name="ef"
                            >
                              <Input placeholder="Write here" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Location</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Location" name="location">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Facility" name="facility">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Mobile Combustion Details
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Row gutter={[16]}>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Input placeholder="Write here" />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Select
                                    placeholder="Please Select"
                                    options={optionsAS}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Other Details</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Supplier Name" name="location">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Comments</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Comments" name="comments">
                              <TextArea
                                maxLength={100}
                                placeholder="Write here"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default Footprint;

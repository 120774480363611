import React, { useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({ data, options }) => {
  const chartRef = useRef(null);

  // useEffect(() => {
  //   const chart = chartRef.current;
  //   if (!chart) return;

  //   const ctx = chart.ctx;
  //   const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  //   gradient.addColorStop(0, "rgba(255, 99, 132, 0.5)");
  //   gradient.addColorStop(1, "rgba(255, 99, 132, 0)");
  // }, []);

  return <Line data={data} ref={chartRef} options={options} />;
};

export default LineChart;

// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_SETTINGS_BASE_URL =
  process.env.REACT_APP_API_SETTINGS_BASE_URL ||
  "http://api-ahyaos.codelabs.inc:443/api";
export const API_BASE_URL =
  process.env.API_BASE_URL || "http://api-ahyaos.codelabs.inc/api";

export const API_KEY =
  "ahyaosh2PnWso61W47W6ogIAUXq9UDyD2xVN4uInbg4SpcBpXDKqm97JbML8aqFD";

export const token = JSON.parse(localStorage.getItem("token"));

// // Create an Axios instance
// const apiClient = axios.create({
//   baseURL: API_BASE_URL,
//   timeout: 10000,
// });

// // Add a request interceptor
// apiClient.interceptors.request.use(
//   (config) => {
//     // Attach token or any other custom logic
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     config.headers["x-api-key"] = API_KEY;
//     return config;
//   },
//   (error) => {
//     // Handle request errors here
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle response errors here (e.g., redirect to login on 401)
//     if (error.response && error.response.status === 401) {
//       console.log(error);
//     }
//     return Promise.reject(error);
//   }
// );

// export default apiClient;

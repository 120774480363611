import React, { useState } from "react";
import CatalogLayout from "../CatalogLayout";
import {
  Flex,
  Space,
  Button,
  Dropdown,
  Switch,
  Input,
  Table,
  Drawer,
  Form,
  Select,
  Checkbox,
  Row,
  Col,
  Radio,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import AddCatalog from "../addCatalog";
import Search from "../../../../images/search.svg";

const { TextArea } = Input;
const FinancedEntity = () => {
  const equipmentData = {
    type: "Financed Entity",
    content: [
      "A portfolio of entities, typically organizations like companies, responsible for emissions that are funded by your organization.",
      "As an example, the S&P 500 is made up of 500 entities, all of which are responsible for a specified number of emissions.",
      "Any subset of those companies could represent a Financed Entities Portfolio.",
    ],
  };
  const [equipmentModalData, setEquipmentModalData] = useState(equipmentData);
  const [open, setOpen] = useState(false);
  const [addManually, setAddManually] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onClose = () => {
    setOpen(false);
    setAddManually(false);
  };
  const addManully = () => {
    setAddManually(true);
  };
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Entity",
      dataIndex: "equipment",
      key: "equipment",
      width: 660,
      sorter: (a, b) => a.equipment - b.equipment,
    },
    {
      title: "Country",
      dataIndex: "category",
      key: "category",
      width: 300,
      sorter: (a, b) => a.category - b.category,
    },
    {
      title: "NAICS Sector",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
      sorter: (a, b) => a.itemSource - b.itemSource,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Hub Power Company Ltd",
      category: "United States",
      itemSource: "Utility",
    },
    {
      key: "2",
      equipment: "Hub Power Company Ltd",
      category: "United States",
      itemSource: "Information",
    },
    {
      key: "3",
      equipment: "Hub Power Company Ltd",
      category: "United States",
      itemSource: "Wholesale Trade",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];
  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Financed Entity Catalog</h4>
        </Space>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Export
          </Button>
        </Dropdown>
      </Flex>
      <Space className="mt-24">
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Sector
          </Button>
        </Dropdown>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Industry
          </Button>
        </Dropdown>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Subindustry
          </Button>
        </Dropdown>
      </Space>
      <Flex
        justify="space-between"
        align="center"
        className="mt-24"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500 }}
        />
        <Space size={[20, 20]}>
          <Space>
            <Switch />
            <p className="m-0">Show archived</p>
          </Space>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            type="primary"
            className="btn-h-40 fw-500"
            onClick={() => setOpen(true)}
          >
            Add Financed Entity
          </Button>
        </Space>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>
      <Drawer
        className="footprintDrawer"
        title="Add Financed Entity"
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <AddCatalog
          onClose={onClose}
          equipmentModalData={equipmentModalData}
          addManully={addManully}
        />
      </Drawer>

      <Drawer
        className="footprintDrawer"
        title="Add Financed Entity"
        onClose={onClose}
        open={addManually}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" className="btn-h-40" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
              onClick={onClose}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <Form layout="vertical">
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Financed Entity Details</h5>
            </div>
            <div className="activity-body">
              <Form.Item
                label="Is this company a publicly listed entity"
                name="publicly"
                rules={[
                  {
                    required: true,
                    message: "Required publicly listed entity",
                  },
                ]}
              >
                <Radio.Group>
                  <div>
                    <Radio value="yes" className="checkbox-round">
                      Yes
                    </Radio>
                  </div>
                  <div>
                    <Radio value="no" className="checkbox-round">
                      No
                    </Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Company Name"
                name="cnasme"
                rules={[
                  {
                    required: true,
                    message: "Required company name",
                  },
                ]}
              >
                <Input placeholder="Acme Corporation" />
              </Form.Item>
              <Form.Item
                label="Company Country"
                name="cCountry"
                rules={[
                  {
                    required: true,
                    message: "Required company country",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item label="Description" name="Description">
                <TextArea placeholder="Acme Corporation" />
              </Form.Item>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <Form.Item
                    label="City"
                    name="City"
                    rules={[
                      {
                        required: true,
                        message: "Required city",
                      },
                    ]}
                  >
                    <Input placeholder="Riyadh" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="State"
                    name="State"
                    rules={[
                      {
                        required: true,
                        message: "Required state",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={options} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Contact Information</h5>
            </div>
            <div className="activity-body">
              <Form.Item label="Contact Name" name="cName">
                <Input placeholder="+966-0-123-1234" />
              </Form.Item>
              <Form.Item
                label="Contact Email"
                name="cEmail"
                rules={[
                  {
                    required: true,
                    message: "Required contact email",
                  },
                ]}
              >
                <Input placeholder="acmecorporation@gmail.com" />
              </Form.Item>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Company Information</h5>
            </div>
            <div className="activity-body">
              <Form.Item label="Internal ID" name="internalID">
                <Input placeholder="+966-0-123-1234" />
              </Form.Item>
              <Form.Item label="Use NAICS or GICS code" name="NAICS">
                <p className="m-0 text-black mb-8">
                  If you have a full NAICS code, we will automatically map the
                  company information for you. If you have a GICS code, we will
                  suggest the best matching below.
                </p>
                <div className="checkbox-round">
                  <Checkbox>NAICS Code</Checkbox>
                </div>
                <div className="checkbox-round">
                  <Checkbox>GICS Code</Checkbox>
                </div>
              </Form.Item>
              <Form.Item label="NAICS code" name="NAICSC">
                <Input placeholder="1234" prefix={<img src={Search} />} />
              </Form.Item>
              <Form.Item
                label="Sector"
                name="Sector"
                rules={[
                  {
                    required: true,
                    message: "Required sector",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Industry"
                name="industry"
                rules={[
                  {
                    required: true,
                    message: "Required industry",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Sub Industry"
                name="sindustry"
                rules={[
                  {
                    required: true,
                    message: "Required industry",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Drawer>
    </CatalogLayout>
  );
};

export default FinancedEntity;

import React, { useState } from "react";
import { Tabs, Layout, Button, Card } from "antd";
import BussinessDetail from "./BussinessDetail";
import PersonalInfo from "./PersonalInfo";
import PasswordChange from "./PasswordChange";
import CompanyInfo from "./CompanyInfo";
import GoalsTarget from "./GoalsTarget";
import SubscriptionProfile from "./Subscription";
import Members from "./Members";
const { Header } = Layout;

const Profile = () => {
  const [title, setTitle] = useState("Business Details");
  const items = [
    {
      key: "Business Details",
      label: "Business details",
      children: <BussinessDetail />,
    },
    {
      key: "Personal Information",
      label: "Personal information",
      children: <PersonalInfo />,
    },
    {
      key: "Change Password",
      label: "Change Password",
      children: <PasswordChange />,
    },
    {
      key: "Registration, tax & bank account",
      label: "Registration, tax & bank account",
      children: <CompanyInfo />,
    },
    {
      key: "Goals & Targets",
      label: "Goals & Targets",
      children: <GoalsTarget />,
    },
    {
      key: "Subscription Details",
      label: "Subscription details",
      children: <SubscriptionProfile />,
    },
    {
      key: "Members",
      label: "Members",
      children: <Members />,
    },
  ];

  const onChange = (key) => {
    setTitle(key);
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="m-0">{title}</h3>
      </Header>
      <div className="main-content">
        <div className="steps-wizard settings-tab">
          <Tabs
            defaultActiveKey="Business details"
            items={items}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Profile;

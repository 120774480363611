import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../redux/slices/countriesSlice";
import { fetchSettingsData } from "../redux/slices/settingsSlice";
import { fetchStates } from "../redux/slices/statesSlice";
import { fetchIndustries } from "../redux/slices/industrySlice";
import { fetchSolutations } from "../redux/slices/solutationSlice";
import { getGoalsAndTargets } from "../redux/slices/generalSettingsSlice";
import { fetchYears } from "../redux/slices/yearsSlice";

const useFetchCountriesData = (url) => {
  const dispatch = useDispatch();
  const { countries, loader, error } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(fetchCountries(url));
  }, [url]);

  return { countries, coutryLoader: loader, countryErr: error };
};

export const useFetchSettingsData = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.userSettings);
  useEffect(() => {
    dispatch(fetchSettingsData(url));
  }, [url]);

  return { userSettings: data, settingsLoader: loading, settingsErr: error };
};

export const useFetchStatesData = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.states);
  useEffect(() => {
    dispatch(fetchStates(url));
  }, [url]);

  return { states: data, statesLoader: loading, statesErr: error };
};

export const useFetchCitiesData = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.states);
  useEffect(() => {
    dispatch(fetchStates(url));
  }, [url]);

  return { cities: data, citiesLoader: loading, citiesErr: error };
};

export const useFetchIndustryData = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.industries);
  useEffect(() => {
    dispatch(fetchIndustries(url));
  }, [url]);

  return { industries: data, industriesLoader: loading, industriesErr: error };
};

export const useFetchSolutationsData = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.solutations);
  useEffect(() => {
    dispatch(fetchSolutations(url));
  }, [url]);

  return {
    solutations: data,
    solutationsLoader: loading,
    solutationsErr: error,
  };
};

export const useFetchGoalsAndTargets = () => {
  const dispatch = useDispatch();
  const { goalsAndTargets, loading, goalsAndTargetsErr } = useSelector(
    (state) => state.generalSettings
  );
  useEffect(() => {
    dispatch(getGoalsAndTargets());
  }, []);

  return {
    goalsAndTargets: goalsAndTargets,
    goalsAndTargetsLoader: loading,
    goalsAndTargetsErr: goalsAndTargetsErr,
  };
};

export const useFetchYears = (url) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.years);
  useEffect(() => {
    dispatch(fetchYears(url));
  }, []);

  return {
    years: data,
    yearsLoader: loading,
    yearsErr: error,
  };
};

export default useFetchCountriesData;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initState = {
  countries: "",
  error: "",
  loader: false,
};

export const fetchCountries = createAsyncThunk(
  "data/fetchCountries",
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      const message = err.response.data?.message ?? "An error occurred";
      return rejectWithValue(message);
    }
  }
);

const countriesSlice = createSlice({
  name: "countriesSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.loader = false;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.error = action.payload ?? "Unknown error occurred";
        state.loader = false;
      });
  },
});

export default countriesSlice.reducer;

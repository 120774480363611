import React from "react";
import {
  Card,
  Select,
  Row,
  Col,
  Button,
  Flex,
  Space,
  Form,
  DatePicker,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";

const ExportAudit = () => {
  return (
    <>
      <Card>
        <div className="card-body">
          <Form layout="vertical">
            <Row gutter={[24, 0]}>
              <Col md={6}>
                <Form.Item className="m-0" label="Date start" name="es">
                  <DatePicker placeholder="Select" className="w-100" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item className="m-0" label="Date end" name="es">
                  <DatePicker placeholder="Select" className="w-100" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  className="m-0"
                  label="Select reports to export *"
                  name="es"
                >
                  <Select
                    placeholder="All"
                    options={[
                      {
                        value: "uar",
                        label: "User Activity Report",
                      },
                      {
                        value: "ulr",
                        label: "User Login Report",
                      },
                      {
                        value: "urr",
                        label: "User Role Report",
                      },
                      {
                        value: "usr",
                        label: "User Status Report",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  className="m-0"
                  label="Select format to export *"
                  name="es1"
                >
                  <Select
                    placeholder="All"
                    options={[
                      {
                        value: "csv",
                        label: ".csv",
                      },
                      {
                        value: "xlsx",
                        label: ".xlsx",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div
            className="adapt-height mt-24"
            style={{ height: "calc(100vh - 430px)" }}
          ></div>
        </div>
        <div className="card-footer">
          <Flex justify="end">
            <Space>
              <Button shape="round" className="btn-h-40 text-black fw-500">
                Reset
              </Button>
              <Button
                type="primary"
                shape="round"
                className="btn-h-40"
                icon={<ExportOutlined />}
                iconPosition="start"
              >
                Export Audit History
              </Button>
            </Space>
          </Flex>
        </div>
      </Card>
    </>
  );
};

export default ExportAudit;

import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Space,
  Dropdown,
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Flex,
  Radio,
  List,
  Drawer,
  DatePicker,
  Form,
} from "antd";
import {
  CalendarOutlined,
  DownOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import BarChart from "../../../sharedModules/charts/BarChart";

const { Header } = Layout;
const EntityOverview = () => {
  const dataBar = {
    labels: ["Scope"],
    datasets: [
      {
        label: "Scope 1",
        data: [4123.35],
        backgroundColor: "#CC4D19",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 90,
      },
      {
        label: "Scope 2",
        data: [2800],
        backgroundColor: "#F2BFAB",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 90,
      },
      {
        label: "Scope 3",
        data: [1610],
        backgroundColor: "#FAE5DD",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 90,
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8,
        },
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        stacked: true,
        display: false,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "#000",
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#14151A",
        bodyColor: "#14151A",
        borderColor: "white",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue + " tCO₂e";
          },
          title: function (data) {
            return data[0].dataset.label;
          },
        },
      },
    },
  };
  const [pickerOpen, setPickerOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const handleOk = () => {
    setPickerOpen(false);
  };

  const handleCancel = () => {
    setPickerOpen(false);
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Financial Entity Overview</h4>
        <Space>
          <div className="overlay-date">
            <Button
              type="gray"
              shape="round"
              icon={<CalendarOutlined />}
              iconPosition="start"
              className="btn-h-40 fw-500"
              onClick={() => setPickerOpen(true)}
            >
              All time
            </Button>
          </div>

          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button
              type="gray"
              shape="round"
              icon={<DownOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
            >
              All funds
            </Button>
          </Dropdown>
        </Space>
      </Header>

      <div className="main-content">
        <Row gutter={[20, 16]}>
          <Col md={24}>
            <Card
              className="header-pad-12"
              title="Prime Materials Inc."
              extra={
                <>
                  <Button
                    type="text"
                    icon={<InfoCircleOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => setOpen(true)}
                  >
                    <span className="text-black">Entity Details</span>
                  </Button>
                </>
              }
            >
              <Row gutter={[12, 12]}>
                <Col md={12}>
                  <Card className="body-pad-12">
                    <Flex
                      justify="space-between"
                      align="center"
                      className="py-2"
                    >
                      <p className="m-0 text-black">Prime Materials Inc.</p>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">12,345.67</p>
                        <Badge
                          count="tCO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="body-pad-12">
                    <Flex
                      justify="space-between"
                      align="center"
                      className="py-2"
                    >
                      <p className="m-0 text-black">Attributed Emissions</p>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">12,345.67</p>
                        <Badge
                          count="tCO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="header-pad-12" title="Attributed scope breakdown">
              <Flex className="large-divider">
                <div className="graph-100 w-100">
                  <BarChart data={dataBar} options={optionsBar} />
                </div>
              </Flex>
              <List itemLayout="horizontal" className="stat-fig">
                <List.Item className="py-24">
                  <div>
                    <div className="price-sec m-0">
                      <h3 className="m-0">12,345.67</h3>
                      <Badge
                        count="CO₂e"
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <p className="m-0" style={{ color: "#000" }}>
                      Emissions by scope
                    </p>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#DC6803" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black"> Scope 1</p>
                        <Badge
                          count="30.22 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">4,123.45</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                    <p className="m-0 fs-12 text-black">
                      Direct emissions from owned or controlled sources
                    </p>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#F2BFAB" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black"> Scope 2</p>
                        <Badge
                          count="30.22 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">3,789.01</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                    <p className="m-0 fs-12 text-black">
                      Indirect emissions from the generation of purchased
                      electricity, steam, heating, and cooling consumed by the
                      reporting company
                    </p>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#FAE5DD" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black"> Scope 3</p>
                        <Badge
                          count="30.22 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">4,433.21</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                    <p className="m-0 fs-12 text-black">
                      All other indirect emissions that occur in a company’s
                      value chain
                    </p>
                  </div>
                </List.Item>
              </List>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="header-pad-12" title="Attributed gas breakdown">
              <Flex className="large-divider">
                <div className="graph-100 w-100">
                  <BarChart data={dataBar} options={optionsBar} />
                </div>
              </Flex>
              <List itemLayout="horizontal" className="stat-fig">
                <List.Item className="py-24">
                  <div>
                    <div className="price-sec m-0">
                      <h3 className="m-0">12,345.67</h3>
                      <Badge
                        count="CO₂e"
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <p className="m-0" style={{ color: "#000" }}>
                      Emissions by scope
                    </p>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#DC6803" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black">
                          {" "}
                          CO₂ (Carbon Dioxide)
                        </p>
                        <Badge
                          count="63.93%"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">7,890.12</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#F2BFAB" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black"> CH₄ (Methane)</p>
                        <Badge
                          count="19.00 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">7,890.12</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#FAE5DD" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black">
                          {" "}
                          N₂O (Nitrous Oxide)
                        </p>
                        <Badge
                          count="10.00 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">7,890.12</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#FFF5F1" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black">
                          {" "}
                          HFCs (Hydrofluorocarbons)
                        </p>
                        <Badge
                          count="4.60 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">7,890.12</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </List.Item>
                <List.Item className="py-24">
                  <span style={{ background: "#FFF9F7" }}></span>
                  <div className="w-100">
                    <Flex justify="space-between" align="center">
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="m-0 fw-500 text-black">
                          PFCs (Perfluorocarbons)
                        </p>
                        <Badge
                          count="2.49 %"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "rgba(10, 15, 41, .08)",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                      <Flex justify="space-between" align="center" gap="small">
                        <p className="fs-16 fw-500 m-0 text-black">7,890.12</p>
                        <Badge
                          count="CO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                          }}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </List.Item>
              </List>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        open={pickerOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_) => (
          <Flex
            justify="space-between"
            align="center"
            gap="middle"
            style={{
              paddingTop: 24,
              borderTop: "1px solid rgba(5, 5, 5, 0.06)",
            }}
          >
            <Button type="gray" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="start"
              size="default"
            >
              Search
            </Button>
          </Flex>
        )}
      >
        <Form layout="vertical">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="pq">Past quarter</Radio>
              <Radio value="pm">Past month</Radio>
              <Radio value="py">Past year</Radio>
              <Radio value="at">All time</Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Form.Item label="Custom date range">
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Drawer
        title={
          <>
            <h4 className="m-0">Prime Materials Inc.</h4>
          </>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Space size={[24, 24]} direction="vertical">
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Type</p>
            <p className="m-0 text-blad">Listed Entity</p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Sector</p>
            <p className="m-0 text-blad">Utilities</p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Industry</p>
            <p className="m-0 text-blad">
              Electric Power Generation, Transmission and Distribution
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Sub-Industry</p>
            <p className="m-0 text-blad">
              Fossil Fuel Electric Power Generation
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Section description</p>
            <p className="m-0 text-blad">
              This U.S. industry comprises establishments primarily engaged in
              operating fossil fuel powered electric power generation
              facilities. These facilities use fossil fuels, such as coal, oil,
              or gas, in internal combustion or combustion turbine conventional
              steam process to produce electric energy. The electric energy
              produced in these establishments is provided to electric power
              transmission systems or to electric power distribution systems.
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600 mb-1">Entity description</p>
            <p className="m-0 text-blad">
              The Hub Power Company Limited is a Pakistan-based independent
              power producer. The principal activities of the Company are to
              develop, own, operate and maintain power stations. The Company has
              installed power generation capacity of 3,581 megawatt (MW). Its
              fired thermal power plant situated at Mouza Kund, Hub in
              Balochistan. Its Narowal Plant is a fired, engine-based, combined
              cycle power station, located at Mouza Poong, Narowal in Punjab.
              The Company operates in three segments: power generation business,
              which includes the Hub plant, Narowal plant & Laraib plant;
              operations and maintenance business and investments in CPHGC, TEL,
              TNPTL, SECMC and Prime. The Company holds interest in Laraib
              Energy Limited, which is a run-of-the river 84MW hydel power plant
              near the New Bong Escape, 8 km downstream of Mangla Dam in Azad
              Jammu and Kashmir. The Company's subsidiaries include Hub Power
              Services Limited, Hub Power Holdings Limited, Narowal Energy
              Limited and Thar Energy Limited.
            </p>
          </div>
        </Space>
      </Drawer>
    </>
  );
};

export default EntityOverview;

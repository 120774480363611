import React, { useState } from "react";
import {
  Space,
  Layout,
  Button,
  Input,
  Dropdown,
  Row,
  Col,
  Flex,
  Table,
  Tag,
  Drawer,
  DatePicker,
} from "antd";
import {
  CalendarOutlined,
  CloudUploadOutlined,
  SearchOutlined,
  DownOutlined,
  SyncOutlined,
  EllipsisOutlined,
  ClockCircleOutlined,
  CheckCircleFilled,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import Search from "../../../images/search.svg";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;
const { Column } = Table;

const FinancialEntity = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const editRowDD = [
    {
      label: "View Details",
      key: "0",
      icon: <EyeOutlined />,
    },
  ];

  const openEntity = () => {
    navigate("entity-overview");
  };
  const columns = [
    {
      title: "Financed entity",
      dataIndex: "jobID",
      key: "jobID",
      render: (_, record) => (
        <>
          <p
            className="m-0 fw-500 text-black cursor-pointer"
            onClick={openEntity}
          >
            {_}
          </p>
        </>
      ),
    },
    {
      title: "Attribution Emission",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category - b.category,
      render: (_, record) => (
        <>
          <p className="m-0 fw-500 text-black">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.categoryCaption}</p>
        </>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "fileName",
      key: "fileName",
      sorter: (a, b) => a.fileName - b.fileName,
    },
    {
      title: "Revenue",
      dataIndex: "fileName",
      key: "fileName",
      sorter: (a, b) => a.fileName - b.fileName,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 74,
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: editRowDD,
              onClick: (event) => {
                const { key } = event;
                editRow(key, record);
              },
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<EllipsisOutlined />}
              iconPosition="start"
              style={{ transform: "rotate(90deg)", float: "right" }}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      jobID: "Prime Materials Inc.",
      status: ["In progress"],
      fileName: "$689,9234,244.88",
      category: "12,345.67",
      uploadedOn: "04-19-2024",
      uploadedOnDate: "11:03 AM",
      categoryCaption: "tCO2e",
    },
    {
      key: "2",
      jobID: "Reliant Resources Co.",
      status: ["Completed"],
      fileName: "$689,9234,244.88",
      category: "10,4523",
      uploadedOn: "04-19-2024",
      uploadedOnDate: "11:03 AM",
      categoryCaption: "tCO2e",
    },
  ];

  const editRow = (key, data) => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (selectedDate) => {
    console.log(selectedDate);
  };
  const toggleDatePicker = () => {
    setPickerOpen(!pickerOpen);
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Financial Entites</h4>
      </Header>

      <div className="main-content">
        <Row justify="start" alignItems="center" gutter={[16]}>
          <Col md={8}>
            <Input
              placeholder="Search"
              prefix={<img src={Search} />}
              style={{ borderRadius: 25 }}
            />
          </Col>
          <Col md={8}>
            <Flex align="center" justify="start" gap={12}>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button
                  type="gray"
                  shape="round"
                  icon={<DownOutlined />}
                  iconPosition="end"
                  className="btn-h-40 fw-500 text-black"
                >
                  Acme Corporation
                </Button>
              </Dropdown>
              <Button
                type="gray"
                shape="round"
                className="btn-h-40 fw-500 text-black"
              >
                Reporting period
              </Button>
            </Flex>
          </Col>
        </Row>
        <div className="mt-24">
          <div
            className="adapt-height mt-24"
            style={{ height: "calc(100vh - 210px)" }}
          >
            <Table
              dataSource={data}
              // pagination={{
              //   pageSize: 10,
              //   position: ["bottomCenter"],
              //   itemRender: (current, type, originalElement) => {
              //     if (type === "prev") {
              //       return <ArrowLeftOutlined />;
              //     }
              //     if (type === "next") {
              //       return <ArrowRightOutlined />;
              //     }
              //     return originalElement;
              //   },
              // }}
              pagination={false}
              columns={columns}
            ></Table>
          </div>
        </div>
      </div>
      <Drawer
        className="footprintDrawer"
        title={
          <Flex justify="start" align="center" gap="middle">
            <h4 className="m-0 pl-3">Activity Details</h4>
            <Tag
              color="warning"
              style={{
                color: "#AE590A",
                fontSize: 12,
                fontWeight: 500,
                background: "#FDEAD8",
                border: 0,
                borderRadius: 6,
              }}
            >
              <ClockCircleOutlined style={{ marginRight: 3 }} /> Processing
            </Tag>
          </Flex>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                shape="round"
                className="btn-h-40"
                onClick={onClose}
              >
                Back to financial entites
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="px-40">
          <ul className="monitoring-activity">
            <li>
              <p className="m-0 text-black fw-600">Job ID</p>
              <p className="m-0 fw-600 text-black-60">
                INT-NeueWorld-JR-140524-01
              </p>
            </li>
            <li>
              <p className="m-0 text-black fw-600">Data</p>
              <p className="m-0 fw-600 text-black-60">04-19-2024 11:03 AM</p>
            </li>
            <li>
              <p className="m-0 text-black fw-600">File size</p>
              <p className="m-0 fw-600 text-black-60">1 bytes</p>
            </li>
            <li>
              <p className="m-0 text-black fw-600">Type</p>
              <p className="m-0 fw-600 text-black-60">
                Organization hierarchy export
              </p>
            </li>
          </ul>
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" className="btn-h-40" disabled>
              View in ledger
            </Button>
            <Button
              shape="round"
              icon={<CloudDownloadOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-600"
            >
              Download export
            </Button>
          </Flex>
        </div>
      </Drawer>
    </>
  );
};

export default FinancialEntity;

import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Badge,
  Flex,
  Card,
  Button,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const GoalsTarget = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const [form] = Form.useForm();
  const onFinish = (values) => {
    // onNext();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    // Checks if there are any errors
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    // Check if any field is empty
    const formValues = form.getFieldsValue(); // Get all field values as an object
    const hasEmptyFields = Object.values(formValues).some((value) => !value); // Check for empty values

    // Disable the button if there are errors or missing fields
    setIsButtonDisabled(hasErrors || hasEmptyFields);
  };
  return (
    <>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Goals & targets</h3>
          <p className="mt-6 mb-0 text-black-60">
            Your enterprise's goals help us provide a tailor made experience.
          </p>
        </div>
        <div className="card-body">
          <Form
            layout="vertical"
            form={form}
            className="mt-24 goalsForm"
            onFieldsChange={handleFormChange}
          >
            <Row gutter={[16, 0]}>
              <Col md={24}>
                <p className="m-0 text-black-60">Question 01</p>
                <Form.Item
                  label="How many members will be added to your AhyaOS account?*"
                  name="members"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">1 - 50</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 02</p>
                <Form.Item
                  label="What are you looking to accomplish with AhyaOS?*"
                  name="accomplish"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      Measure scope 1 & 2 emissions
                    </Radio.Button>
                    <Radio.Button value="b">
                      Measure scope 3 emissions
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 03</p>
                <Form.Item
                  label="Select the sub-categories of scope 3 emissions you will measure through AhyaOS"
                  name="scope3Emission"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">Financed emissions</Radio.Button>
                    <Radio.Button value="b">Supply chain</Radio.Button>
                    <Radio.Button value="c">Measure all scopes</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 04</p>
                <Form.Item
                  label="Have you measures your enterprise emissions before?"
                  name="emissionBefore"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">Yes</Radio.Button>
                    <Radio.Button value="b">Scope 1 & 2</Radio.Button>
                    <Radio.Button value="c">
                      <strong>Year:</strong> 2024
                    </Radio.Button>
                    <Radio.Button value="d">
                      <strong>Emissions:</strong> 25,000 tCO2e
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 05</p>
                <Form.Item
                  label="Do you have an emission reduction target?"
                  extra="This refers to near-term or long-term emission reduction goals."
                  name="target"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">Yes</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="What are your base year emissions for the enterprise target"
                  name="target1"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 15,000 tCO2e
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="What is your enterprise emission reduction target? "
                  name="target2"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 10,000 tCO2e
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 06</p>
                <Form.Item
                  label="Have you committed to the SBTi?"
                  extra="Science Based Targets initiative enables entities globally to set emissions reductions targets in line with advancement in climate science."
                  name="commited"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">Yes</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="What are your base year emissions? "
                  name="commited1"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 10,000 %
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <p className="m-0 text-black fw-500">
                  What are your SBTi targets for scope 1 & 2? 
                </p>
                <Form.Item label="Near-term target year" name="commited2">
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 20,000 %
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <p className="m-0 text-black fw-500">
                  What are your SBTi targets for scope 1 & 2? 
                </p>
                <Form.Item label="Long-term target year" name="commited3">
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 40,000 %
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <p className="m-0 text-black fw-500">
                  What are your SBTi targets for scope 3? 
                </p>
                <Form.Item label="Near-term target year" name="commited4">
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      <strong>Year:</strong> 2040
                    </Radio.Button>
                    <Radio.Button value="b">
                      <strong>Emissions:</strong> 30,000 %
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 07</p>
                <Form.Item
                  label="Which sustainability standard will you report on?"
                  name="standards"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">GHG Protocol</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 08</p>
                <Form.Item
                  label="Do you require additional support with use of Ahya OS"
                  name="supportOf"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      Part time climate science/data science teams
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24}>
                <p className="m-0 text-black-60">Question 09</p>
                <Form.Item
                  label=" Are you interested in AI enabled measurement, reduction and understanding of emissions?"
                  name="intrestedAi"
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">
                      Yes - require AhyaAI data check
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          className="btn-h-40 mt-24"
          onClick={() => form.submit()}
          disabled={isButtonDisabled}
        >
          Update & save
        </Button>
      </Flex>
    </>
  );
};

export default GoalsTarget;

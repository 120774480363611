import React, { useState } from "react";
import {
  Button,
  Space,
  Dropdown,
  Card,
  Badge,
  Divider,
  Flex,
  Radio,
  Table,
} from "antd";
import Icon, {
  CalendarOutlined,
  DownOutlined,
  LineChartOutlined,
  PercentageOutlined,
  BarChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import LineChart from "../../../sharedModules/charts/LineChart";
import BarChart from "../../../sharedModules/charts/BarChart";

const BarChartI = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 10.0001H5.83333V17.5001H2.5V10.0001ZM14.1667 6.66675H17.5V17.5001H14.1667V6.66675ZM8.33333 1.66675H11.6667V17.5001H8.33333V1.66675Z"
      // fill="#0F1324"
    />
  </svg>
);
const BarChartIcon = (props) => <Icon component={BarChartI} {...props} />;

const TableGrid = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.33335 4.66667H15.6667V2.16667H2.33335V4.66667ZM10.6667 13.8333V6.33333H7.33335V13.8333H10.6667ZM12.3334 13.8333H15.6667V6.33333H12.3334V13.8333ZM5.66669 13.8333V6.33333H2.33335V13.8333H5.66669ZM1.50002 0.5H16.5C16.721 0.5 16.933 0.587797 17.0893 0.744078C17.2456 0.900358 17.3334 1.11232 17.3334 1.33333V14.6667C17.3334 14.8877 17.2456 15.0996 17.0893 15.2559C16.933 15.4122 16.721 15.5 16.5 15.5H1.50002C1.27901 15.5 1.06704 15.4122 0.910765 15.2559C0.754484 15.0996 0.666687 14.8877 0.666687 14.6667V1.33333C0.666687 1.11232 0.754484 0.900358 0.910765 0.744078C1.06704 0.587797 1.27901 0.5 1.50002 0.5Z" />
  </svg>
);
const TableIcon = (props) => <Icon component={TableGrid} {...props} />;

const ScopeStats = ({ scopeData }) => {
  const optionsBar = {
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 4,
          bottomRight: 4,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          padding: 40,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };
  const radioValueEmission = [
    {
      id: "line",
      title: <LineChartOutlined />,
      items: "",
    },
    {
      id: "percentage",
      title: <PercentageOutlined />,
      items: "",
    },
    {
      id: "absolute",
      title: <BarChartIcon />,
      items: "",
    },
    {
      id: "tabular",
      title: <TableIcon />,
      items: "",
    },
  ];

  const [emissionValue, setEmissionValue] = useState(radioValueEmission[0].id);
  const onChangeEmision = (e) => {
    setEmissionValue(e.target.value);
  };
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 20,
      fixed: "left",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 235,
      fixed: "left",
    },
    {
      title: "Jan ‘23",
      dataIndex: "jan23",
      key: "jan23",
      width: 160,
    },
    {
      title: "Feb ‘23",
      dataIndex: "feb23",
      key: "feb23",
      width: 160,
    },
    {
      title: "Mar ‘23",
      dataIndex: "mar23",
      key: "mar23",
      width: 160,
    },
    {
      title: "Apr ‘23",
      dataIndex: "apr23",
      key: "apr23",
      width: 160,
    },
    {
      title: "May ‘23",
      dataIndex: "may23",
      key: "may23",
      width: 160,
    },
    {
      title: "Jun ‘23",
      dataIndex: "jun23",
      key: "jun23",
      width: 160,
    },
    {
      title: "July ‘23",
      dataIndex: "july23",
      key: "july23",
      width: 160,
    },
    {
      title: "Aug ‘23",
      dataIndex: "aug23",
      key: "aug23",
      width: 160,
    },
    {
      title: "Sep ‘23",
      dataIndex: "sep23",
      key: "sep23",
      width: 160,
    },
    {
      title: "Oct ‘23",
      dataIndex: "oct23",
      key: "oct23",
      width: 160,
    },
    {
      title: "Nov ‘23",
      dataIndex: "nov23",
      key: "nov23",
      width: 160,
    },
    {
      title: "Dec ‘23",
      dataIndex: "dec23",
      key: "dec23",
      width: 160,
    },
  ];

  return (
    <Card className="scope-card">
      <div className="card-header">
        <Flex justify="space-between" align="center" gap="middle">
          <div>
            <h4 className="m-0 fs-16">
              {scopeData.title}
              {emissionValue !== "line" ? (
                <span className="text-capitalize">({emissionValue})</span>
              ) : (
                ""
              )}
            </h4>
          </div>
          <div className="steps-wizard">
            <Space split={<Divider type="vertical" />}>
              <Space>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <Button
                    shape="round"
                    type="gray"
                    className="btn-h-40 fw-500 text-black"
                  >
                    <CalendarOutlined /> Months <DownOutlined />
                  </Button>
                </Dropdown>
                <Button
                  shape="round"
                  type="gray"
                  icon={<DownOutlined />}
                  iconPosition="end"
                  className="btn-h-40 fw-500 text-black"
                >
                  All footprint categories
                </Button>
              </Space>
              <div className="ant-form radio-round">
                <Radio.Group
                  buttonStyle="solid"
                  size="large"
                  value={emissionValue}
                  onChange={onChangeEmision}
                >
                  {radioValueEmission.map((val, index) => {
                    return (
                      <Radio.Button
                        key={index}
                        value={val.id}
                        style={{
                          fontSize: 18,
                          display: "flex",
                          alignItems: "center",
                          padding: 10,
                        }}
                      >
                        {val.title}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </Space>
          </div>
        </Flex>
      </div>
      <div className={`card-body ${emissionValue === "tabular" ? "p-0" : ""}`}>
        {emissionValue === "line" ? (
          <>
            <div
              className="price-sec m-0"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <h1 className="fw-400 text-black line-1 m-0">1,786</h1>
              <Badge
                count="tCO₂e"
                style={{
                  backgroundColor: "#E9EAEC",
                  color: "rgba(15, 19, 36, 0.6)",
                  fontWeight: "600",
                  borderRadius: "6px",
                }}
              />
            </div>
            <p className="text-black-60 m-0">Total collected Emissions</p>
            <div className="graph-300 mt-24">
              <LineChart data={scopeData.lineChart} options={options} />
            </div>
          </>
        ) : emissionValue === "percentage" ? (
          <>
            <div
              className="price-sec m-0"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <h1 className="fw-400 text-black line-1 m-0">1,786</h1>
              <Badge
                count="tCO₂e"
                style={{
                  backgroundColor: "#E9EAEC",
                  color: "rgba(15, 19, 36, 0.6)",
                  fontWeight: "600",
                  borderRadius: "6px",
                }}
              />
            </div>
            <p className="text-black-60 m-0">Total collected Emissions</p>
            <div className="graph-300 mt-24">
              <BarChart data={scopeData.barChart} options={optionsBar} />
            </div>
          </>
        ) : emissionValue === "absolute" ? (
          <>
            <div
              className="price-sec m-0"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <h1 className="fw-400 text-black line-1 m-0">1,786</h1>
              <Badge
                count="tCO₂e"
                style={{
                  backgroundColor: "#E9EAEC",
                  color: "rgba(15, 19, 36, 0.6)",
                  fontWeight: "600",
                  borderRadius: "6px",
                }}
              />
            </div>
            <p className="text-black-60 m-0">Total collected Emissions</p>
            <div className="graph-300 mt-24">
              <BarChart data={scopeData.barChart} options={optionsBar} />
            </div>
          </>
        ) : emissionValue === "tabular" ? (
          <div
            className="adapt-height"
            style={{ height: "calc(100vh - 290px)" }}
          >
            <Table
              dataSource={scopeData.tableData}
              scroll={{
                x: 2160,
              }}
              // pagination={{
              //   pageSize: 10,
              //   position: ["bottomCenter"],
              //   itemRender: (current, type, originalElement) => {
              //     if (type === "prev") {
              //       return <ArrowLeftOutlined />;
              //     }
              //     if (type === "next") {
              //       return <ArrowRightOutlined />;
              //     }
              //     return originalElement;
              //   },
              // }}
              columns={columns}
              pagination={false}
            ></Table>
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default ScopeStats;

import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Input,
  Radio,
  Table,
  Tag,
  Flex,
  Card,
  Button,
  Drawer,
  Space,
  Form,
  Select,
  Badge,
  Divider,
  notification,
} from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  EllipsisOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  RedoOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Search from "../../../images/search.svg";

const Members = () => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("members");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenView, setDrawerOpenView] = useState(false);
  const [drawerOpenEdit, setDrawerOpenEdit] = useState(false);
  const [drawerOpenViewInv, setDrawerOpenViewInv] = useState(false);
  const [drawerOpenEditInv, setDrawerOpenEditInv] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const columnsInvited = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName - b.firstName,
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName - b.lastName,
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      sorter: (a, b) => a.jobTitle - b.jobTitle,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email - b.email,
    },
    {
      title: "Invited",
      dataIndex: "added",
      key: "added",
      sorter: (a, b) => a.added - b.added,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role - b.role,
    },
    {
      title: <div className="text-right">Action</div>,
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: editRowInvited,
              onClick: (event) => {
                const { key } = event;
                editRowInvite(key, record);
              },
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<EllipsisOutlined />}
              iconPosition="start"
              style={{ transform: "rotate(90deg)", float: "right" }}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];
  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName - b.firstName,
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName - b.lastName,
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      sorter: (a, b) => a.jobTitle - b.jobTitle,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            if (tag === "Active") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#166E3F",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#D1FAE4",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  {tag}
                </Tag>
              );
            }
            if (tag === "Deactivated") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#9A1C13",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#FCE5E4",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  {tag}
                </Tag>
              );
            }
          })}
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role - b.role,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email - b.email,
    },
    {
      title: "Added",
      dataIndex: "added",
      key: "added",
      sorter: (a, b) => a.added - b.added,
    },
    {
      title: <div className="text-right">Action</div>,
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: editRowDD,
              onClick: (event) => {
                const { key } = event;
                editRow(key, record);
              },
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<EllipsisOutlined />}
              iconPosition="start"
              style={{ transform: "rotate(90deg)", float: "right" }}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const editRowDD = [
    {
      label: "View Details",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Edit Details",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];
  const editRowInvited = [
    {
      label: "View Details",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Resend Invitation",
      key: "resend",
      icon: <RedoOutlined />,
    },
    {
      label: "Cancel Invitation",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const editRow = (key, data) => {
    if (key === "view") {
      setDrawerOpenView(true);
    }
    if (key === "edit") {
      setDrawerOpenEdit(true);
    }
  };

  const editRowInvite = (key, data) => {
    console.log(key, data);
    if (key === "view") {
      setDrawerOpenViewInv(true);
    }
    if (key === "resend") {
      setDrawerOpenEditInv(true);
    }
  };

  const data = [
    {
      key: "1",
      firstName: "John",
      lastName: "Doe",
      status: ["Active"],
      jobTitle: "Project manager",
      role: "View Only",
      email: "johndoe@acmecorporation.com",
      added: "15-12-2023",
    },
    {
      key: "2",
      firstName: "Jack",
      lastName: "Wallace",
      status: ["Deactivated"],
      jobTitle: "Data Scientist",
      role: "Contributor",
      email: "jackwallace@acmecorporation.com",
      added: "13-12-2023",
    },
    {
      key: "1",
      firstName: "James",
      lastName: "Bond",
      status: ["Active"],
      jobTitle: "Manager",
      role: "Administrator",
      email: "jamesbond@acmecorporation.com",
      added: "13-12-2023",
    },
  ];

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const onClose = () => {
    setDrawerOpen(false);
    setDrawerOpenView(false);
    setDrawerOpenEdit(false);
    setDrawerOpenViewInv(false);
    setDrawerOpenEditInv(false);
  };

  const resendInv = (type) => {
    setDrawerOpenEditInv(false);
    api[type]({
      message: "Invite Resend Successfully",
      placement: "bottomRight",
      icon: <CheckOutlined />,
    });
  };

  const optionsAS = [
    { value: "EC", label: "External Calculation" },
    { value: "GGP", label: "Greenhouse Gas Protocol" },
  ];

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        onClose();
      })
      .catch((errorInfo) => {
        // onFinishFailed(errorInfo);
      });
  };

  return (
    <>
      {contextHolder}
      <Card style={{ marginTop: 24 }}>
        <div className="card-body">
          <Row justify="space-between" alignItems="center" gutter={[16]}>
            <Col md={8}>
              <Input
                placeholder="Search"
                prefix={<img src={Search} />}
                style={{ borderRadius: 25 }}
              />
            </Col>
            <Col md={16}>
              <Flex align="center" justify="end" gap={12}>
                <div className="subscription-pay-type members-toggle">
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={handleModeChange}
                    value={mode}
                  >
                    <Radio.Button value="members">Members</Radio.Button>
                    <Radio.Button value="invited">Invited</Radio.Button>
                  </Radio.Group>
                </div>
                <Button
                  shape="circle"
                  icon={<SettingOutlined />}
                  iconPosition="start"
                  className="btn-h-40 fw-500 text-black px-2"
                ></Button>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  iconPosition="start"
                  className="btn-h-40 fw-500"
                  onClick={() => setDrawerOpen(true)}
                >
                  Invite Member
                </Button>
              </Flex>
            </Col>
          </Row>
          <div className="mt-24">
            <div
              className="adapt-height mt-24"
              style={{ height: "calc(100vh - 360px)" }}
            >
              {mode === "members" ? (
                <Table
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                ></Table>
              ) : (
                <Table
                  dataSource={data}
                  columns={columnsInvited}
                  pagination={false}
                ></Table>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Drawer
        className="footprintDrawer"
        title="johndoe@acmecorporation.com"
        onClose={onClose}
        open={drawerOpenViewInv}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel Invitation
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={() => setDrawerOpenEditInv(true)}
              >
                Edit Invitation
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="activity-row">
          <div className="activity-body">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">First name</p>
                <p className="text-black m-0 fs-16">John</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Last name</p>
                <p className="text-black m-0 fs-16">Doe</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Job title</p>
                <p className="text-black m-0 fs-16">Project manager</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Email</p>
                <p className="text-black m-0 fs-16">
                  <Badge
                    count="johndoe@acmecorporation.com"
                    style={{
                      backgroundColor: "#E9EAEC",
                      color: "rgba(15, 19, 36, 0.6)",
                      fontWeight: "500",
                      borderRadius: 8,
                      fontSize: 14,
                      padding: "5px 10px",
                      height: "auto",
                    }}
                  />
                </p>
              </Space>
            </Space>
          </div>
        </div>
        <Divider />
        <div className="activity-row">
          <div className="activity-body pt-0">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Role</p>
                <Tag
                  color="warning"
                  style={{
                    color: "#AE590A",
                    fontSize: 14,
                    fontWeight: 500,
                    background: "#FDEAD8",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  View Only
                </Tag>
              </Space>
            </Space>
          </div>
        </div>
        <Divider />
        <div className="activity-row">
          <div className="activity-body pt-0">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Status</p>
                <Tag
                  color="warning"
                  style={{
                    color: "#133A9A",
                    fontSize: 14,
                    fontWeight: 500,
                    background: "#E3EAFD",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  Invited
                </Tag>
              </Space>
            </Space>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="johndoe@acmecorporation.com"
        onClose={onClose}
        open={drawerOpenEditInv}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel Invitation
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={() => resendInv("success")}
              >
                Resend Invitation
              </Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <div className="activity-row">
            <div className="activity-body">
              <Form.Item
                label="First name"
                name="fName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. John" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Doe" />
              </Form.Item>
              <Form.Item
                label="Job title"
                name="jTitle"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Project Manager" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </div>
          </div>
          <Divider />
          <div className="activity-row">
            <div className="activity-body pt-0">
              <Form.Item
                label="Select Role"
                name="as"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Drawer>

      <Drawer
        className="footprintDrawer"
        title="Edit"
        onClose={onClose}
        open={drawerOpenEdit}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <div className="activity-row">
            <div className="activity-body">
              <Form.Item
                label="First name"
                name="fName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. John" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Doe" />
              </Form.Item>
              <Form.Item
                label="Job title"
                name="jTitle"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Project Manager" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </div>
          </div>
          <Divider />
          <div className="activity-row">
            <div className="activity-body pt-0">
              <Form.Item
                label="Select Role"
                name="as"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
              <Form.Item
                label="Select Status"
                name="asf"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="View Member"
        onClose={onClose}
        open={drawerOpenView}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<EditOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={() => setDrawerOpenEdit(true)}
              >
                Edit
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="activity-row">
          <div className="activity-body">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">First name</p>
                <p className="text-black m-0 fs-16">John</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Last name</p>
                <p className="text-black m-0 fs-16">Doe</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Job title</p>
                <p className="text-black m-0 fs-16">Project manager</p>
              </Space>
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Email</p>
                <p className="text-black m-0 fs-16">
                  <Badge
                    count="johndoe@acmecorporation.com"
                    style={{
                      backgroundColor: "#E9EAEC",
                      color: "rgba(15, 19, 36, 0.6)",
                      fontWeight: "500",
                      borderRadius: 8,
                      fontSize: 14,
                      padding: "5px 10px",
                      height: "auto",
                    }}
                  />
                </p>
              </Space>
            </Space>
          </div>
        </div>
        <Divider />
        <div className="activity-row">
          <div className="activity-body pt-0">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Role</p>
                <Tag
                  color="warning"
                  style={{
                    color: "#AE590A",
                    fontSize: 14,
                    fontWeight: 500,
                    background: "#FDEAD8",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  View Only
                </Tag>
              </Space>
            </Space>
          </div>
        </div>
        <Divider />
        <div className="activity-row">
          <div className="activity-body pt-0">
            <Space direction="vertical" size="large">
              <Space size="middle" direction="vertical">
                <p className="text-black m-0 fw-500">Status</p>
                <Tag
                  color="warning"
                  style={{
                    color: "#166E3F",
                    fontSize: 14,
                    fontWeight: 500,
                    background: "#D1FAE4",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  Active
                </Tag>
              </Space>
            </Space>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="Invite Member"
        onClose={onClose}
        open={drawerOpen}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button shape="round" className="btn-h-40 fw-500">
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={handleNext}
              >
                Invite
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="px-40">
          <p className="text-blad">
            When inviting a user, they will receive an email that will allow
            them to join current account. If they are new to Ahya, they will
            need to sign up providing their personal information.
          </p>
        </div>
        <div className="activity-row mt-24">
          <div className="activity-head">
            <h5 className="m-0 text-black">User Details</h5>
          </div>
          <div className="activity-body">
            <Form layout="vertical" form={form}>
              <Form.Item
                label="First name"
                name="fName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. John" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Doe" />
              </Form.Item>
              <Form.Item
                label="Job title"
                name="jTitle"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="etc. Project Manager" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                label="Select Role"
                name="as"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Members;

import React, { useState } from "react";
import {
  Card,
  Layout,
  Radio,
  Tabs,
  Row,
  Col,
  Badge,
  Flex,
  Button,
  Table,
  Tag,
  Steps,
  Drawer,
  Space,
  Divider,
  List,
} from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import {
  InfoCircleOutlined,
  CheckOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  ArrowLeftOutlined,
  CheckCircleFilled,
  ArrowRightOutlined,
  CloseOutlined,
  FileTextOutlined,
  EditOutlined,
} from "@ant-design/icons";

import cloud from "../../../images/cloud.png";

import assignee from "../../../images/requestType.png";
import footprint from "../../../images/dateRange.png";
import scope from "../../../images/requestDue.png";
import status from "../../../images/status.png";

const { Header } = Layout;
const { TabPane } = Tabs;
const { Column } = Table;

const Collect = () => {
  const [open, setOpen] = useState(false);
  const radioValue = [
    {
      id: "all",
      title: "All",
    },
    {
      id: "portfolioCompanies",
      title: "Portfolio companies",
    },
    {
      id: "supplyChains",
      title: "Supply chains",
    },
  ];
  const [scopeValue, setScopeValue] = useState(radioValue[0].id);
  const onChangeScope = (e) => {
    setScopeValue(e.target.value);
  };

  const descClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Requesting",
      dataIndex: "requesting",
      key: "requesting",
      width: 800,
      render: (_, record) => (
        <div onClick={descClick}>
          <p className="m-0 fw-500 text-black">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.categoryCaption}</p>
        </div>
      ),
    },
    {
      title: "Sent to",
      dataIndex: "sentTo",
      key: "sentTo",
      render: (_, record) => (
        <>
          <p className="m-0 fw-500 text-black">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.sentInc}</p>
        </>
      ),
    },
    {
      title: "Date range",
      dataIndex: "dateRange",
      key: "dateRange",
      sorter: (a, b) => a.dateRange - b.dateRange,
    },
    {
      title: "Date range",
      dataIndex: "dateRange1",
      key: "dateRange1",
      sorter: (a, b) => a.dateRange1 - b.dateRange1,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "In progress") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#AE590A",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#FDEAD8",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  <ClockCircleOutlined style={{ marginRight: 3 }} /> {tag}
                </Tag>
              );
            }
            if (tag === "Completed") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#166E3F",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#D1FAE4",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  <CheckCircleFilled style={{ marginRight: 3 }} /> {tag}
                </Tag>
              );
            }
          })}
        </>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      requesting: "Mobile Combustion",
      status: ["Completed"],
      sentTo: "Nick Velten",
      sentInc: "Prime Materials Inc.",
      dateRange: "04-19-2024 - 05-01-2024",
      dateRange1: "04-19-2024 - 05-01-2024",
      categoryCaption: "External Ticket",
    },
    {
      key: "2",
      requesting: "Associated Gas Venting and Flaring",
      status: ["In progress"],
      sentTo: "Nick Velten",
      sentInc: "Prime Materials Inc.",
      dateRange: "04-19-2024 - 05-01-2024",
      dateRange1: "04-19-2024 - 05-01-2024",
      categoryCaption: "Internal Ticket",
    },
    {
      key: "3",
      requesting: "Atmospheric Storage Tanks",
      status: ["Completed"],
      sentTo: "Nick Velten",
      sentInc: "Prime Materials Inc.",
      dateRange: "04-19-2024 - 05-01-2024",
      dateRange1: "04-19-2024 - 05-01-2024",
      categoryCaption: "External Ticket",
    },
  ];

  const collectedEmission = [
    {
      id: 0,
      title: "Total collected emissions",
      year: "FY 2023",
      amount: 39981,
      badge: "tCO₂e",
      icon: cloud,
    },
    {
      id: 1,
      title: "Annual change",
      year: "FY 2022 - 2023",
      amount: -18.02,
      badge: "%",
      icon: cloud,
    },
    {
      id: 2,
      title: "Weighted average emissions intensity",
      year: "FY 2023",
      amount: 321,
      badge: "tCO₂e/USD",
      icon: cloud,
    },
    {
      id: 3,
      title: "Average emissions intensity (suppliers)",
      year: "tCO2e / Total suppliers",
      amount: 12,
      badge: "tCO₂e",
      icon: cloud,
    },
  ];

  const readyReview = [
    {
      id: 0,
      title: "Scope 1, 2 and 3",
      category: "Prime Materials Inc.",
    },
    {
      id: 1,
      title: "Purchased Goods & Services",
      category: "Apex Innovations Inc.",
    },
    {
      id: 2,
      title: "Purchased Goods & Services",
      category: "Apex Innovations Inc.",
    },
    {
      id: 3,
      title: "Purchased Goods & Services",
      category: "Prime Materials Inc.",
    },
    {
      id: 4,
      title: "Scope 1, 2 and 3",
      category: "Stellar Supply Chain Solutions",
    },
  ];

  const recentImport = [
    {
      id: 0,
      title: "Scope 1, 2 and 3",
      category: "Prime Materials Inc.",
    },
    {
      id: 1,
      title: "Purchased Goods & Services",
      category: "Apex Innovations Inc.",
    },
    {
      id: 2,
      title: "Purchased Goods & Services",
      category: "Apex Innovations Inc.",
    },
    {
      id: 3,
      title: "Purchased Goods & Services",
      category: "Prime Materials Inc.",
    },
    {
      id: 4,
      title: "Scope 1, 2 and 3",
      category: "Stellar Supply Chain Solutions",
    },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="m-0">Collect</h3>
      </Header>

      <div className="main-content">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
            <Row gutter={[20, 20]}>
              <Col md={18}>
                <Card className="collect-card">
                  <div className="card-header">
                    <p className="m-0 text-merri fs-16">Top emitters</p>
                    <div className="steps-wizard">
                      <div className="ant-form radio-round">
                        <Radio.Group
                          buttonStyle="solid"
                          size="large"
                          value={scopeValue}
                          onChange={onChangeScope}
                        >
                          {radioValue.map((val, index) => {
                            return (
                              <Radio.Button key={index} value={val.id}>
                                {val.title}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row gutter={[40, 16]} style={{ height: "100%" }}>
                      <Col md={17}>
                        {scopeValue === "all" ? (
                          <Row gutter={[7, 7]} style={{ height: 600 }}>
                            <Col md={6}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#F79009" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Prime Materials Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FDB022" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Reliant Resources Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                            <Col md={6}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#F79009" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    ProSource Supplies Ltd.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Quality Components Group
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Essential Elements Corp.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                            <Col md={6}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Stellar Supply Chain Solutions
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Nexus Parts & Services Group
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Keystone Distributors Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Superior Logistics Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                            <Col md={6}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Benchmark Goods LLC
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Apex Innovations Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FDB022" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Summit Solutions Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Vertex Ventures LLC
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FFFAEB" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Pinnacle Partners Ltd.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                          </Row>
                        ) : scopeValue === "supplyChains" ? (
                          <Row gutter={[7, 7]} style={{ height: 600 }}>
                            <Col md={8}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#F79009" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Prime Materials Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FDB022" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Reliant Resources Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                            <Col md={8}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#F79009" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    ProSource Supplies Ltd.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Quality Components Group
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Essential Elements Corp.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                            <Col md={8}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEC84B" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Stellar Supply Chain Solutions
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Nexus Parts & Services Group
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Keystone Distributors Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Superior Logistics Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                          </Row>
                        ) : scopeValue === "portfolioCompanies" ? (
                          <Row gutter={[7, 7]} style={{ height: 600 }}>
                            <Col md={24}>
                              <Flex
                                gap={7}
                                vertical="vertical"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEF0C7" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Benchmark Goods LLC
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Apex Innovations Inc.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FDB022" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Summit Solutions Co.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FEDF89" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Vertex Ventures LLC
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                                <div
                                  className="grid-pane"
                                  style={{ background: "#FFFAEB" }}
                                >
                                  <p className="m-0 text-black fw-600">
                                    Pinnacle Partners Ltd.
                                  </p>
                                  <p className="m-0 text-black-60">
                                    12,789 tCO<sub>2</sub>e
                                  </p>
                                </div>
                              </Flex>
                            </Col>
                          </Row>
                        ) : (
                          "ji"
                        )}
                      </Col>
                      <Col md={7}>
                        <div className="collect-stats">
                          <div className="stats-head">
                            <h2 className="m-0 text-merri">
                              39,981
                              <Badge
                                count="tCO₂e"
                                style={{
                                  backgroundColor: "#E9EAEC",
                                  color: "rgba(15, 19, 36, 0.6)",
                                  marginLeft: 8,
                                  borderRadius: 6,
                                }}
                              />
                            </h2>
                            <p className="mt-2 mb-0">
                              Total collected emissions
                            </p>
                          </div>
                          <div className="stats-body">
                            <ul className="stat-fig">
                              <li>
                                <span style={{ background: "#DC6803" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  `&gt;` 1,000 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "##F79009" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  800 - 1,000 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "#FDB022" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  600 - 800 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "#FEC84B" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  400 - 600 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "#FEDF89" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  200 - 400 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "#FFEAD5" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  200 - 400 tCO₂e
                                </p>
                              </li>
                              <li>
                                <span style={{ background: "#FFF6ED" }}></span>
                                <p className="m-0 fw-500 text-black">
                                  {" "}
                                  0 - 200 tCO₂e
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Row gutter={[16, 16]} className="mt-3">
                  {collectedEmission.map((val, index) => (
                    <Col md={12} key={index}>
                      <Flex
                        justify="space-between"
                        align="center"
                        style={{
                          background: "#fff",
                          padding: 12,
                          borderRadius: 8,
                          overflow: "hidden",
                        }}
                      >
                        <Flex gap="small">
                          <img src={cloud} style={{ width: 44, height: 44 }} />
                          <div>
                            <p className="m-0 fw-500 text-black">{val.title}</p>
                            <p className="m-0 text-black-60">{val.year}</p>
                          </div>
                        </Flex>
                        <p className="m-0 fw-500">
                          {val.amount}
                          <Badge
                            count={val.badge}
                            style={{
                              backgroundColor: "#E9EAEC",
                              color: "rgba(15, 19, 36, 0.6)",
                              marginLeft: 8,
                              borderRadius: 6,
                            }}
                          />
                        </p>
                      </Flex>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col md={6}>
                <Row gutter={[0, 16]}>
                  <Col md={24}>
                    <Card className="collect-card">
                      <div className="card-header">
                        <Flex align="center" gap="middle">
                          <span
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "100%",
                              background: "#FDEAD8",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#F48E2F",
                            }}
                          >
                            <InfoCircleOutlined />
                          </span>
                          <p className="m-0 text-merri fs-16">
                            Ready for review
                          </p>
                        </Flex>
                      </div>
                      <div className="card-body p-0">
                        {readyReview.map((val, index) => (
                          <div style={{ padding: "10px 16px" }} key={index}>
                            <p className="m-0 text-black fw-500">{val.title}</p>
                            <p className="m-0 text-black-60 fs-12">
                              {val.category}
                            </p>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </Col>
                  <Col md={24}>
                    <Card className="collect-card">
                      <div className="card-header">
                        <Flex align="center" gap="middle">
                          <span
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "100%",
                              background: "#C3F8DC",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#21A65E",
                            }}
                          >
                            <CheckOutlined />
                          </span>
                          <p className="m-0 text-merri fs-16">
                            Recently Imported
                          </p>
                        </Flex>
                      </div>
                      <div className="card-body p-0">
                        {recentImport.map((val, index) => (
                          <div style={{ padding: "10px 16px" }} key={index}>
                            <p className="m-0 text-black fw-500">{val.title}</p>
                            <p className="m-0 text-black-60 fs-12">
                              {val.category}
                            </p>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Sent requests" key="2">
            <Card className="collect-card">
              <div className="card-header">
                <div>
                  <p className="m-0 text-merri fs-16">Sent Requests</p>
                  <p className="m-0 text-black fs-12">
                    This tab includes any received requests sent from your
                    organization to your internal users, customers or investors.{" "}
                  </p>
                </div>
                <div className="steps-wizard">
                  <Flex gap="small">
                    <div className="ant-form radio-round">
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        value={scopeValue}
                        onChange={onChangeScope}
                      >
                        {radioValue.map((val, index) => {
                          return (
                            <Radio.Button key={index} value={val.id}>
                              {val.title}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                    <Button
                      type="primary"
                      shape="round"
                      icon={<PlusOutlined />}
                      iconPosition="start"
                      className="btn-h-40"
                    >
                      New request
                    </Button>
                  </Flex>
                </div>
              </div>
              <div className="card-body p-0">
                <Row gutter={[40, 16]}>
                  <Col md={24}>
                    <div
                      className="adapt-height"
                      style={{ height: "calc(100vh - 350px)" }}
                    >
                      <Table
                        dataSource={data}
                        // pagination={{
                        //   pageSize: 10,
                        //   position: ["bottomCenter"],
                        //   itemRender: (current, type, originalElement) => {
                        //     if (type === "prev") {
                        //       return <ArrowLeftOutlined />;
                        //     }
                        //     if (type === "next") {
                        //       return <ArrowRightOutlined />;
                        //     }
                        //     return originalElement;
                        //   },
                        // }}
                        pagination={false}
                        columns={columns}
                      ></Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </TabPane>
          <TabPane tab="Received requests" key="3">
            <Card className="collect-card">
              <div className="card-header">
                <div>
                  <p className="m-0 text-merri fs-16">Received Requests</p>
                  <p className="m-0 text-black fs-12">
                    This tab includes any received requests sent from your
                    organization to your internal users, customers or investors.{" "}
                  </p>
                </div>
                <div className="steps-wizard">
                  <Flex gap="small">
                    <div className="ant-form radio-round">
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        value={scopeValue}
                        onChange={onChangeScope}
                      >
                        {radioValue.map((val, index) => {
                          return (
                            <Radio.Button key={index} value={val.id}>
                              {val.title}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </Flex>
                </div>
              </div>
              <div className="card-body p-0">
                <Row gutter={[40, 16]}>
                  <Col md={24}>
                    <div
                      className="adapt-height"
                      style={{ height: "calc(100vh - 350px)" }}
                    >
                      <Table
                        dataSource={data}
                        // pagination={{
                        //   pageSize: 10,
                        //   position: ["bottomCenter"],
                        //   itemRender: (current, type, originalElement) => {
                        //     if (type === "prev") {
                        //       return <ArrowLeftOutlined />;
                        //     }
                        //     if (type === "next") {
                        //       return <ArrowRightOutlined />;
                        //     }
                        //     return originalElement;
                        //   },
                        // }}
                        pagination={false}
                        columns={columns}
                      ></Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </TabPane>
        </Tabs>
      </div>
      <Drawer
        className="footprintDrawer"
        title={<h4 className="m-0">Associated Gas Venting and Flaring</h4>}
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
            <List itemLayout="horizontal">
              <List.Item
                actions={[
                  <Space>
                    <Tag
                      color="warning"
                      style={{
                        color: "#AE590A",
                        fontSize: 12,
                        fontWeight: 500,
                        background: "#FDEAD8",
                        border: 0,
                        borderRadius: 6,
                      }}
                    >
                      <ClockCircleOutlined style={{ marginRight: 3 }} /> In
                      progress
                    </Tag>
                  </Space>,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={status} />}
                  title="Status"
                />
              </List.Item>
              <List.Item
                actions={[
                  <Space>
                    <Badge
                      count={<span>Internal</span>}
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "500",
                        padding: "3px 10px",
                        borderRadius: "6px",
                      }}
                    />
                  </Space>,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={assignee} />}
                  title="Request type"
                />
              </List.Item>
              <List.Item
                actions={[<p className="m-0 fw-600 text-black">06-18-2024</p>]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={scope} />}
                  title="Requested due date"
                />
              </List.Item>
              <List.Item
                actions={[
                  <p className="m-0 fw-600 text-black">
                    04-19-2024 - 05-01-2024
                  </p>,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<img src={footprint} />}
                  title="Date range"
                />
              </List.Item>
            </List>
          </TabPane>
          <TabPane tab="Activity" key="2">
            <div className="px-40 mt-2">
              <Steps progressDot direction="vertical" current={1}>
                <Steps.Step
                  title="08:00 AM"
                  description={
                    <p className="m-0 fw-600">
                      Ticket created{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
                <Steps.Step
                  title="04:43 PM"
                  description={
                    <p className="m-0 fw-600">
                      New sheet uploaded{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
                <Steps.Step
                  title="05:11 PM"
                  description={
                    <p className="m-0 fw-600">
                      Activity Ledge Updated{" "}
                      <span style={{ display: "block", fontWeight: 400 }}>
                        John Doe
                      </span>
                    </p>
                  }
                />
              </Steps>
            </div>
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default Collect;

import React, { useState } from "react";
import { Card, Tabs } from "antd";
import OrganizationType from "./OrganizationType";
import OrganizationStructure from "./OrganizationStructure";

const OrganizationSettings = () => {
  const [title, setTitle] = useState("Organization Structure");
  const items = [
    {
      key: "Organization Structure",
      label: "Organization Structure",
      children: <OrganizationStructure />,
    },
    {
      key: "Organization Type",
      label: "Organization Type",
      children: <OrganizationType />,
    },
  ];

  const onChange = (key) => {
    setTitle(key);
  };
  return (
    <>
      <Card>
        <div className="card-body">
          <Tabs
            defaultActiveKey="Organization Structure"
            items={items}
            onChange={onChange}
          />
        </div>
      </Card>
    </>
  );
};

export default OrganizationSettings;

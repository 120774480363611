import React from "react";
import { Layout, Button, Space, Card, Row, Col, Collapse } from "antd";
import { DownOutlined, UpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import DoughnutChart from "../../../sharedModules/charts/DoughnutChart";
import ReduceCollapseHead from "./ReduceCollapseHead";
import ReduceCollapseBody from "./ReduceCollapseBody";

const FooprintReduction = () => {
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const scope1 = {
    scopeHeader: {
      title: "Scope 1",
      percent: "2.71",
      emission: "27,662.18",
    },
    scopeBody: [
      {
        recommended: "Recommended Focus Area",
        title: "Fugitive emissions - industrial gases",
        currentEmission: "87.33% of current scope emissions",
        scopeCount: "4,462.73",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Fugitive emissions -refrigeration",
        currentEmission: "11.74% of current scope emissions",
        scopeCount: "600.16",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Stationary combustion - facility",
        currentEmission: "0.48% of current scope emissions",
        scopeCount: "24.71",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Mobile combustion",
        currentEmission: "0.43% of current scope emissions",
        scopeCount: "22.04",
      },
    ],
  };

  const scope2 = {
    scopeHeader: {
      title: "Scope 2",
      percent: "< 0.01",
      emission: "108,790.61",
    },
    scopeBody: [
      {
        recommended: "Recommended Focus Area",
        title: "Purchased electribity - facility",
        currentEmission: "1003% of current scope emissions",
        scopeCount: "108,788.36",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Purchased cooling",
        currentEmission: "11.74% of current scope emissions",
        scopeCount: "1.16",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Purchased electricity - fleet on site fueling",
        currentEmission: "0.48% of current scope emissions",
        scopeCount: "1.11",
      },
    ],
  };

  const scope3 = {
    scopeHeader: {
      title: "Scope 3",
      percent: "> 99.99",
      emission: "1.7581",
    },
    scopeBody: [
      {
        recommended: "Recommended Focus Area",
        title: "Equity - listed (category 15)",
        currentEmission: "1003% of current scope emissions",
        scopeCount: "361,556",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Project finance - debit (category 15)",
        currentEmission: "11.74% of current scope emissions",
        scopeCount: "66,177.98",
      },
      {
        recommended: "Recommended Focus Area",
        title: "Project finance  - equity (category 15)",
        currentEmission: "0.48% of current scope emissions",
        scopeCount: "9,598.84",
      },
    ],
  };

  const items = [
    {
      key: "1",
      label: <ReduceCollapseHead header={scope1.scopeHeader} />,
      children: <ReduceCollapseBody data={scope1.scopeBody} />,
    },
    {
      key: "2",
      label: <ReduceCollapseHead header={scope2.scopeHeader} />,
      children: <ReduceCollapseBody data={scope2.scopeBody} />,
    },
    {
      key: "3",
      label: <ReduceCollapseHead header={scope3.scopeHeader} />,
      children: <ReduceCollapseBody data={scope3.scopeBody} />,
    },
  ];
  return (
    <>
      <Row gutter={[20, 16]}>
        <Col md={6}>
          <Card className="" title="Modeled footprint reduction">
            <Space direction="vertical" size="middle" className="w-100">
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  1.027 <sup className="text-black-60 fw-500 fs-12">tCO2e</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Current scope 1-3 footprint
                </p>
              </Card>
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  0 <sup className="text-black-60 fw-500 fs-12">tCO2e</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Modeled footprint reduction
                </p>
                <div className="scope-trend">
                  <Button
                    type="primary"
                    shape="circle"
                    className="btn-h-40"
                    icon={<ArrowDownOutlined />}
                  />
                </div>
              </Card>
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  0 <sup className="text-black-60 fw-500 fs-12">%</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Footprint reduction %
                </p>
              </Card>
            </Space>

            <div className="mt-24 dounot-chart">
              <DoughnutChart />
            </div>
          </Card>
        </Col>
        <Col md={18} className="collect-card my-3">
          <Card
            title="View by Scope Source"
            extra={
              <>
                {/* Organization Details */}
                <Button shape="round" className="btn-h-40 fw-500 text-black">
                  Reset All
                </Button>
              </>
            }
          >
            <div className="reduce-collapse">
              <Collapse
                accordion
                items={items}
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) =>
                  isActive ? <UpOutlined /> : <DownOutlined />
                }
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FooprintReduction;

import React from "react";
import { Button, Tag, Badge } from "antd";
import {
  ArrowRightOutlined,
  CheckOutlined,
  StarOutlined,
} from "@ant-design/icons";
import BadgeIcon from "../images/badge-icon.png";
import { useNavigate } from "react-router-dom";

const SubscriptionPlan = ({ plan, verified }) => {
  const navigate = useNavigate();
  const onFinish = () => {
    navigate("/verification-completed");
  };
  return (
    <div className="single-plan">
      <div className="plan-head">
        <div className="flex-center-between">
          <Tag>
            <img src={BadgeIcon} /> {plan.team}
          </Tag>
          {plan.planName === "Pro" && (
            <Badge
              count={
                <span>
                  <StarOutlined /> Recommended
                </span>
              }
              style={{
                backgroundColor: "#D1FAE4",
                color: "#166E3F",
                fontWeight: "500",
                borderRadius: "6px",
                padding: "6px 10px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            />
          )}
        </div>
        <h4 className="mb-0 mt-8">{plan.planName}</h4>
        <p className="mt-8 text-black-60 fs-12 mb-0">{plan.planDesc}</p>
        <div className="price-sec">
          {plan.crossPrice && <h4 className="m-0">{plan.crossPrice}</h4>}
          <h2 className="m-0">{plan.planPrice}</h2>
          <Badge
            count="USD"
            style={{
              backgroundColor: "#E9EAEC",
              color: "rgba(15, 19, 36, 0.6)",
              fontWeight: "600",
              borderRadius: "6px",
            }}
          />
        </div>
        <Button
          onClick={onFinish}
          type={plan.isCurrent ? "green" : "primary"}
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          block
          size="large"
        >
          {plan.isCurrent ? "Current plan" : verified ? "Upgrade" : "Continue"}
        </Button>
      </div>
      <div className="plan-body">
        <p className="mt-0 fw-600">{plan.fetureTitle}</p>
        <ul>
          {plan.featureList.map((list) => {
            return (
              <li>
                <CheckOutlined />
                {list}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionPlan;

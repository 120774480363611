import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Space,
  Dropdown,
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Flex,
  Radio,
  List,
  Select,
  DatePicker,
  Form,
  Table,
} from "antd";
import {
  CalendarOutlined,
  DownOutlined,
  SyncOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import BarChart from "../../../sharedModules/charts/BarChart";
import LineChart from "../../../sharedModules/charts/LineChart";
import BubbleChart from "../../../sharedModules/charts/BubbleChart";

const { Header } = Layout;
const FacilityAnalysis = () => {
  const dataBar = {
    labels: ["UAE Factory", "KSA Headquarters", "UAE Warehouse"],
    datasets: [
      {
        label: "Scope 1",
        data: [null, null, 3240],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: true,
        barThickness: 43,
        skipNull: true,
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Scope 1",
        data: [null, 4044, null],
        backgroundColor: "#7F7C71",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 43,
        skipNull: true,
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Scope 1",
        data: [7464, null, null],
        backgroundColor: "#3F686E",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 43,
        skipNull: true,
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    indexAxis: "y",
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 0,
          topRight: 6,
          bottomLeft: 0,
          bottomRight: 6,
        },
      },
    },
    scales: {
      x: {
        display: true,
        stacked: false,
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
            color: "#000",
          },
        },
      },
      y: {
        stacked: false,
        display: true,
        // title: {
        //   display: true,
        //   text: "Total GHG Emissions (tCO2e)",
        //   color: "rgba(15, 19, 36, 0.6)",
        //   font: {
        //     size: 12,
        //     family: "Montserrat",
        //   },
        // },
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "#000",
          font: {
            size: 12,
            family: "Montserrat",
            weight: "500",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: "600",
          family: "'Montserrat', sans-serif",
          size: 14,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#14151A",
        bodyColor: "#14151A",
        borderColor: "white",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue + " tCO₂e";
          },
          title: function (data) {
            return data[0].dataset.label;
          },
        },
      },
      tooltip: false,
    },
  };

  const data = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const EnergyOvertimeData = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [3000000, 3000000, 3000100, 400500, 5000000, 3000000],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const EmissionOvertimeData = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [2700, 2300, 2310, 2250, 2250, 2450],
        data: [0.8, 0.6, 0.4, 0.4, 0.2],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        title: {
          display: true,
          text: "Year",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
      },
      y: {
        display: true,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: false,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };

  const dataBubble = {
    datasets: [
      {
        label: "UAE Warehouse",
        data: [
          { x: 10, y: 20 },
          { x: 15, y: 10 },
          { x: 20, y: 30 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon1)}`;
          return image;
        },
        backgroundColor: "#4778F5",
        borderColor: "#4778F5",
        borderWidth: 0,
      },
      {
        label: "KSA Headquarters",
        data: [
          { x: 12, y: 22 },
          { x: 18, y: 12 },
          { x: 22, y: 32 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon2)}`;
          return image;
        },
        backgroundColor: "#26BD6C",
        borderColor: "#26BD6C",
        borderWidth: 0,
      },
      {
        label: "UAE Factory",
        data: [
          { x: 14, y: 24 },
          { x: 19, y: 14 },
          { x: 24, y: 34 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon3)}`;
          return image;
        },
        backgroundColor: "#EB632C",
        borderColor: "#EB632C",
        borderWidth: 0,
      },
    ],
  };

  const svgIcon1 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#4778F5"/></svg>`;
  const svgIcon2 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#26BD6C"/></svg>`;
  const svgIcon3 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#EB632C"/></svg>`;

  const optionsBubble = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Emissions Intensity (tCO2e / SF)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            alert(JSON.stringify(context.dataset.label));
            return `(${context.raw.x}, ${context.raw.y})`;
          },
        },
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
          tooltipEl.classList.remove("below", "no-transform");

          // Set HTML & Data
          if (tooltipModel.body) {
            const dataFromCurrentElement = tooltipModel.dataPoints[0];
            const currentElement = dataFromCurrentElement.dataIndex;
            const formattedValue = dataFromCurrentElement.formattedValue.trim();
            const currentDataToShow = formattedValue
              .substr(1, formattedValue.length - 2)
              .split(" ");
            const innerHtml = `
              <div style="border-collapse: separate; overflow: hidden; border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">
                <div style="display: flex; padding: 1.2rem; background-color: #FDEAD8">
                    <div style="display: flex;  flex-direction: column;  font-family: 'Montserrat'; font-size: 12px">
                      <div style="display: flex;justify-content: space-between; gap: 10px">Facility: <span style="font-weight: 600">${
                        dataFromCurrentElement.dataset.label
                      }</span></div>
                      <div style="display: flex;justify-content: space-between; gap: 10px">Total Emissions (tonnes of CO2e): <span style="font-weight: 600">${currentDataToShow[0].substr(
                        0,
                        currentDataToShow[0].length - 1
                      )}</span></div>
                      <div style="display: flex;justify-content: space-between; gap: 10px">Emission Intensity (tCO2e / SF):  <span style="font-weight: 600">${
                        currentDataToShow[1]
                      }</span></div>
                    </div>
                </div>
              </div>`;
            tooltipEl.querySelector("table").innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        display: true,
        formatter: (value, context) => {
          // data[0].dataset.label
          return `${context.dataset.label}`;
        },
        font: {
          weight: "500",
          family: "'Montserrat', sans-serif",
          size: 12,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: false,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };

  const radioValue = [
    {
      id: "intensityMetrics",
      title: "Intensity Metrics",
    },
    {
      id: "emissionsIntensity",
      title: "Emissions intensity",
    },
    {
      id: "energyIntensity",
      title: "Energy intensity",
    },
    {
      id: "emissionsOvertime",
      title: "Emissions over time",
    },
    {
      id: "energyOvertime",
      title: "Energy over time",
    },
    {
      id: "facilityMap",
      title: "Facility map",
    },
  ];
  const [scopeValue, setScopeValue] = useState(radioValue[0].id);
  const onChangeScope = (e) => {
    setScopeValue(e.target.value);
  };

  const columns = [
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      width: 180,
      sorter: (a, b) => a.facility - b.facility,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 220,
      sorter: (a, b) => a.country - b.country,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
      sorter: (a, b) => a.state - b.state,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 130,
      sorter: (a, b) => a.city - b.city,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 385,
      sorter: (a, b) => a.source - b.source,
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      width: 130,
      sorter: (a, b) => a.scope - b.scope,
    },
    {
      title: "Calculation Method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 300,
      sorter: (a, b) => a.calculationMethod - b.calculationMethod,
    },
    {
      title: "Total Emissions (tCO2e)",
      dataIndex: "totalEmissions",
      key: "totalEmissions",
      width: 200,
      sorter: (a, b) => a.totalEmissions - b.totalEmissions,
    },
    {
      title: "Total Facility Sqft",
      dataIndex: "totalFacilitySqft",
      key: "totalFacilitySqft",
      width: 160,
      sorter: (a, b) => a.totalFacilitySqft - b.totalFacilitySqft,
    },
    {
      title: "Total Emissions Intensity",
      dataIndex: "totalEmissionsIntensity",
      key: "totalEmissionsIntensity",
      width: 210,
      sorter: (a, b) => a.totalEmissionsIntensity - b.totalEmissionsIntensity,
    },
    {
      title: "Total kWh",
      dataIndex: "totalkWh",
      key: "totalkWh",
      width: 120,
      sorter: (a, b) => a.totalkWh - b.totalkWh,
    },
    {
      title: "Total Energy Use Intensity",
      dataIndex: "totalEnergyUseIntensity",
      key: "totalEnergyUseIntensity",
      width: 220,
      sorter: (a, b) => a.totalEnergyUseIntensity - b.totalEnergyUseIntensity,
    },
    {
      title: "Total Waste Quantity kg",
      dataIndex: "totalWasteQuantitykg",
      key: "totalWasteQuantitykg",
      width: 210,
      sorter: (a, b) => a.totalWasteQuantitykg - b.totalWasteQuantitykg,
    },
    {
      title: "Total Waste Intensity",
      dataIndex: "totalWasteIntensity",
      key: "totalWasteIntensity",
      width: 195,
      sorter: (a, b) => a.totalWasteIntensity - b.totalWasteIntensity,
    },
  ];
  const dataSource = [
    {
      key: "1",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Stationary combustion",
      scope: "Scope 1",
      calculationMethod: "Fuel based: Fuel type",
      totalEmissions: 1176, // 1,176
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0588,
      totalkWh: 73200, // 73,200
      totalEnergyUseIntensity: 0.32,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "2",
      facility: "UAE Warehouse",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Purchased electricity - Fleet onSites fueling",
      scope: "Scope 2",
      calculationMethod: "Location based",
      totalEmissions: 108, // 108
      totalFacilitySqft: 1500, // 1,500
      totalEmissionsIntensity: 0.072,
      totalkWh: 90000, // 90,000
      totalEnergyUseIntensity: 19.6,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "3",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Purchased cooling",
      scope: "Scope 2",
      calculationMethod: "Location based",
      totalEmissions: 120, // 120
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.006,
      totalkWh: 510600, // 510,600
      totalEnergyUseIntensity: 3.66,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "4",
      facility: "KSA Headquarters",
      country: "KSA",
      state: "Riyadh",
      city: "Riyadh",
      source: "Purchased electricity - facility",
      scope: "Scope 2",
      calculationMethod: "Location based",
      totalEmissions: 276, // 276
      totalFacilitySqft: 2200, // 2,200
      totalEmissionsIntensity: 0.13,
      totalkWh: 1488000, // 1,488,000
      totalEnergyUseIntensity: 40.91,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "5",
      facility: "UAE Warehouse",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Purchased electricity - facility",
      scope: "Scope 2",
      calculationMethod: "Location based",
      totalEmissions: 144, // 144
      totalFacilitySqft: 1500, // 1,500
      totalEmissionsIntensity: 0.096,
      totalkWh: "--",
      totalEnergyUseIntensity: 340.4,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "6",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Purchased electricity - facility",
      scope: "Scope 2",
      calculationMethod: "Location based",
      totalEmissions: 216, // 216
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0108,
      totalkWh: "--",
      totalEnergyUseIntensity: 74.4,
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "7",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Mobile combustion",
      scope: "Scope 1",
      calculationMethod: "Distance based",
      totalEmissions: 228, // 228
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0114,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "8",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Fugitive emissions - Refrigeration",
      scope: "Scope 1",
      calculationMethod: "Material balance based",
      totalEmissions: 120, // 120
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.006,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "9",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Capital goods (Category 2)",
      scope: "Scope 3",
      calculationMethod: "Average data based",
      totalEmissions: 84, // 84
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0042,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "10",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Capital goods (Category 2)",
      scope: "Scope 3",
      calculationMethod: "Spend based",
      totalEmissions: 144, // 144
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0072,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "11",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Downstream transportation and distribution (Category 9)",
      scope: "Scope 3",
      calculationMethod: "Distance based: weight-distance",
      totalEmissions: 1872, // 1,872
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0936,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "12",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "Employee commuting (Category 7)",
      scope: "Scope 3",
      calculationMethod: "Distance based",
      totalEmissions: 48, // 48
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0024,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
    {
      key: "13",
      facility: "UAE Factory",
      country: "United Arab Emirates",
      state: "Dubai",
      city: "Dubai",
      source: "End-of-life-treatment of sold products (Category 12)",
      scope: "Scope 3",
      calculationMethod: "Waste type: specific",
      totalEmissions: 132, // 132
      totalFacilitySqft: 20000, // 20,000
      totalEmissionsIntensity: 0.0066,
      totalkWh: "--",
      totalEnergyUseIntensity: "--",
      totalWasteQuantitykg: "--",
      totalWasteIntensity: "--",
    },
  ];

  const FacilityAnalysisValues = [
    { val: "27,662.18", subtitles: "Absolute emissions tCO2e", unit: "tCO2e" },
    {
      val: "1,264",
      subtitles: "Emissions intensity tCO2e / sqft.",
      unit: "tCO2e",
    },
    {
      val: "6,661,009",
      subtitles: "Energy use intensity kWh / sqft.",
      unit: "kWh",
    },
    { val: "0.045", subtitles: "Waste intensity kg / sqft.", unit: "kg" },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Facility Analysis</h4>
        <Button
          shape="round"
          icon={<SyncOutlined />}
          iconPosition="end"
          className="btn-h-40 fw-500"
        >
          Clear Filters
        </Button>
      </Header>

      <div className="main-content">
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Card>
              <Form layout="vertical">
                <Flex gap="large">
                  <Flex wrap align="start" flex="1">
                    <Col md={4}>
                      <Form.Item
                        label="Date start"
                        name="actStart"
                        className="m-0"
                      >
                        <DatePicker
                          placeholder="DD / MM / YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item className="m-0" label="Date end" name="actEnd">
                        <DatePicker
                          placeholder="DD / MM / YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        className="m-0"
                        label="Organization"
                        name="organ"
                      >
                        <Select
                          placeholder="All"
                          options={[
                            {
                              value: "AcmeCorporation",
                              label: "Acme Corporation",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        className="m-0"
                        label="Emission source"
                        name="es"
                      >
                        <Select
                          placeholder="All"
                          options={[
                            {
                              value: "feig",
                              label: "Fugitive emmissions - Industrial gases",
                            },
                            {
                              value: "fersc",
                              label:
                                "Fugitive emmissions - Refrigeration  Stationary combustion",
                            },
                            {
                              value: "mc",
                              label: "Mobile combustion",
                            },
                            {
                              value: "mc1",
                              label: "Stationary combustion",
                            },
                            {
                              value: "mc2",
                              label: "Purchased electricity - facility ",
                            },
                            {
                              value: "mc3",
                              label: "Purchased cooling",
                            },
                            {
                              value: "mc4",
                              label:
                                "Purchased electricity - Fleet onSites fueling ",
                            },
                            {
                              value: "mc5",
                              label:
                                "Purchased goods and services (Category 1)",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        className="m-0"
                        label="Location"
                        name="location"
                      >
                        <Select
                          placeholder="All"
                          options={[
                            {
                              value: "KSA",
                              label: "KSA",
                            },
                            {
                              value: "UAE",
                              label: "UAE",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item className="m-0" label="Scope" name="scope">
                        <Select
                          placeholder="All"
                          options={[
                            {
                              value: "Scope1",
                              label: "Scope 1",
                            },
                            {
                              value: "Scope2",
                              label: "Scope 2",
                            },
                            {
                              value: "Scope3",
                              label: "Scope 3",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Flex>
                  <span className="ant-space-item-split">
                    <div
                      className="ant-divider css-var-r2pb ant-divider-vertical"
                      role="separator"
                    ></div>
                  </span>
                  <Flex align="center">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={1}>Location-Based</Radio>
                        <Radio value={3}>Market-Based</Radio>
                      </Space>
                    </Radio.Group>
                  </Flex>
                </Flex>
              </Form>
            </Card>
          </Col>
          <Col md={24}>
            <Card>
              <Row gutter={[16, 24]}>
                {FacilityAnalysisValues.map((elementInArray, index) => (
                  <Col md={6}>
                    <Card>
                      <Space
                        direction="vertical"
                        size="middle"
                        className="w-100"
                      >
                        <Flex justify="space-between" align="center">
                          <Space>
                            <h3
                              className="m-0 fw-700"
                              style={{ color: "#000" }}
                            >
                              {elementInArray.val}
                            </h3>
                            <Badge
                              count={elementInArray.unit}
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                                fontWeight: "600",
                                borderRadius: "6px",
                              }}
                            />
                          </Space>
                          <h3 className="m-0">--</h3>
                        </Flex>
                        <Flex justify="space-between" align="center">
                          <p className="m-0 text-black">
                            {elementInArray.subtitles}
                          </p>
                          <Space>
                            <p className="m-0 text-black">△</p>
                            <Badge
                              count="%"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                                fontWeight: "600",
                                borderRadius: "6px",
                              }}
                            />
                          </Space>
                        </Flex>
                      </Space>
                    </Card>
                  </Col>
                ))}

                <Col md={12}>
                  <Card title="Emission intensity (tCO₂e per sqft.)">
                    <div className="graph-300 mt-24">
                      <LineChart data={data} options={options} />
                    </div>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card title="Facility">
                    <div className="graph-300 mt-24">
                      <BarChart data={dataBar} options={optionsBar} />
                    </div>
                  </Card>
                </Col>
                <Col md={24}>
                  <Divider style={{ margin: 0 }} />
                </Col>
                <Col md={24}>
                  <div className="steps-wizard mb-24">
                    <div className="ant-form radio-round">
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        value={scopeValue}
                        onChange={onChangeScope}
                      >
                        {radioValue.map((val, index) => {
                          return (
                            <Radio.Button key={index} value={val.id}>
                              {val.title}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                  {scopeValue === "intensityMetrics" ? (
                    <div className="adapt-height">
                      <Table
                        dataSource={dataSource}
                        pagination={false}
                        scroll={{
                          x: 4000,
                        }}
                        columns={columns}
                        summary={(pageData) => {
                          let totalEmissionsSum = 0;
                          let totalEmissionsIntensitySum = 0;
                          let totalkWhSum = 0;
                          let totalWasteQuantitykgSum = 0;
                          let totalWasteIntensitySum = 0;
                          let totalEnergyUseIntensitySum = 0;

                          pageData.forEach(
                            ({
                              totalEmissions,
                              totalEmissionsIntensity,
                              totalkWh,
                              totalWasteQuantitykg,
                              totalWasteIntensity,
                              totalEnergyUseIntensity,
                            }) => {
                              // Check if the values are numbers before adding them to the sum
                              if (typeof totalEmissions === "number") {
                                totalEmissionsSum += totalEmissions;
                              }
                              if (typeof totalEmissionsIntensity === "number") {
                                totalEmissionsIntensitySum +=
                                  totalEmissionsIntensity;
                              }
                              if (typeof totalkWh === "number") {
                                totalkWhSum += totalkWh;
                              }
                              if (typeof totalWasteQuantitykg === "number") {
                                totalWasteQuantitykgSum += totalWasteQuantitykg;
                              }
                              if (typeof totalWasteIntensity === "number") {
                                totalWasteIntensitySum += totalWasteIntensity;
                              }
                              if (typeof totalEnergyUseIntensity === "number") {
                                totalEnergyUseIntensitySum +=
                                  totalEnergyUseIntensity;
                              }
                            }
                          );

                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={7}>
                                Total
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={8} colSpan={2}>
                                {totalEmissionsSum}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={11}>
                                {totalEmissionsIntensitySum}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={12}>
                                {totalkWhSum}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={13}>
                                {totalEnergyUseIntensitySum}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={14}>
                                {totalWasteQuantitykgSum}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={15}>
                                {totalWasteIntensitySum}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }}
                      ></Table>
                    </div>
                  ) : scopeValue === "emissionsIntensity" ? (
                    <Card title="Emissions & Intensity">
                      <div className="graph-300">
                        <BubbleChart
                          data={dataBubble}
                          options={optionsBubble}
                        />
                      </div>
                    </Card>
                  ) : scopeValue === "energyIntensity" ? (
                    <Card title="Energy intensity">
                      <div className="graph-300">
                        <BubbleChart
                          data={dataBubble}
                          options={optionsBubble}
                        />
                      </div>
                    </Card>
                  ) : scopeValue === "emissionsOvertime" ? (
                    <Card title="Emissions over time">
                      <div className="graph-300">
                        <LineChart
                          data={EmissionOvertimeData}
                          options={options}
                        />
                      </div>
                    </Card>
                  ) : scopeValue === "energyOvertime" ? (
                    <Card title="Energy over time">
                      <div className="graph-300">
                        <LineChart
                          data={EnergyOvertimeData}
                          options={options}
                        />
                      </div>
                    </Card>
                  ) : scopeValue === "facilityMap" ? (
                    ""
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FacilityAnalysis;

import React from "react";
import { Space } from "antd";

const HeaderCollapse = ({ icon, label }) => {
  return (
    <Space align="center" size="middle">
      <img src={icon} />
      {label}
    </Space>
  );
};

export default HeaderCollapse;

import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  Space,
  Slider,
  Input,
  Flex,
  Button,
} from "antd";
import { SyncOutlined, ArrowDownOutlined } from "@ant-design/icons";

const EnergyCalculator = ({ val }) => {
  const [inputValue, setInputValue] = useState("0");
  const onChange = (newValue) => {
    setInputValue(newValue);
  };
  return (
    <>
      <Card className="mb-2 body-pad-16">
        <Row align="middle" gutter={[16, 8]}>
          <Col md={7}>
            <p className="text-black fw-600 m-0 py-2">{val.title}</p>
            <p className="m-0 fs-10">
              QJ6Q+CX, Al Aqiq <br />
              Size: <strong>{val.size}</strong> <br />
              Intensity Metric (Footprint/Area):{" "}
              <strong>{val.intensity}</strong> <br />% of total Organization's
              energy consumption: <strong>{val.consumption}</strong>
            </p>
          </Col>
          <Col md={11}>
            <Row align="start" gutter={[20, 8]}>
              <Col md={12}>
                <Space direction="vertical" className="w-100">
                  <p className="m-0 fs-10 fw-700">Clean electricity usage %</p>
                  <Slider
                    min={1}
                    onChange={onChange}
                    value={typeof inputValue === "number" ? inputValue : 0}
                  />
                  <div>
                    <Flex gap="middle">
                      <Input
                        value={inputValue}
                        suffix={
                          <Badge
                            count="%"
                            style={{
                              backgroundColor: "#E9EAEC",
                              color: "rgba(15, 19, 36, 0.8)",
                              padding: "0px 10px",
                              borderRadius: "6px",
                              fontSize: 14,
                              height: "auto",
                            }}
                          />
                        }
                      />
                      <SyncOutlined />
                    </Flex>
                  </div>
                  <p className="text-black fw-400 m-0 fs-10">
                    % of curreny facility energy usage
                  </p>
                </Space>
              </Col>
              <Col md={12}>
                <Space direction="vertical" className="w-100" size="small">
                  <p className="m-0 fs-10 fw-700">kWh consumed</p>
                  <Slider
                    min={1}
                    onChange={onChange}
                    value={typeof inputValue === "number" ? inputValue : 0}
                  />
                  <div>
                    <Flex gap="middle">
                      <Input
                        value={inputValue}
                        suffix={
                          <Badge
                            count="kWh"
                            style={{
                              backgroundColor: "#E9EAEC",
                              color: "rgba(15, 19, 36, 0.8)",
                              padding: "0px 10px",
                              borderRadius: "6px",
                              fontSize: 14,
                              height: "auto",
                            }}
                          />
                        }
                      />
                      <SyncOutlined />
                    </Flex>
                  </div>
                  <p className="text-black fw-400 m-0 fs-10">
                    Per selected reporting period
                  </p>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Flex justify="end" align="middle" gap="middle">
              <Button
                type="primary"
                shape="circle"
                className="btn-h-40"
                icon={<ArrowDownOutlined />}
              />
              <div className="text-right">
                <h4 className="m-0 fw-600 ff-mont">{val.scopeCount}</h4>
                <p className="m-0 text-black fs-12 fw-400">tCO₂e</p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EnergyCalculator;

import React, { useEffect, useState } from "react";
import {
  Menu,
  Badge,
  Drawer,
  Space,
  Button,
  Radio,
  Card,
  Avatar,
  List,
  Flex,
  Form,
  Input,
  Dropdown,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logo from "../../images/logo.png";
import Home from "../../images/menuIcon/home.png";
import Calculator from "../../images/menuIcon/calculator.png";
import Analyze from "../../images/menuIcon/analyze.png";
import Reduce from "../../images/menuIcon/reduce.png";
import Report from "../../images/menuIcon/report.png";
import Setting from "../../images/menuIcon/settings.png";
import Faq from "../../images/menuIcon/faq.png";
import Bell from "../../images/menuIcon/bell.png";
import AvatarIcon from "../../images/menuIcon/avatar.png";
import Switch from "../../images/switch.png";
import Logout from "../../images/logout.png";
import Tawazin from "../../images/tawazin.png";
import {
  CloseOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  LockOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import NotifyCard from "./NotifyCard";

import ProfileImg from "../../images/profile.jpeg";

const { SubMenu } = Menu;

const Sidebar = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openDD, setOpenDD] = useState(false);

  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [passwordFieldEnable, setPasswordFieldEnable] = useState(false);
  const [mode, setMode] = useState("all");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showDrawerProfile = () => {
    setOpenProfile(true);
  };

  const onCloseProfile = () => {
    setOpenProfile(false);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const showPasswordField = () => {
    setPasswordFieldEnable(!passwordFieldEnable);
  };

  useEffect(() => {
    // const paths = location.pathname.split("/").filter((i) => i);
    // const openKeys = paths.map((path, index) => `${index + 1}`);
    // setSelectedKeys([location.pathname]);
    // setOpenKeys(openKeys);
    // console.log(openKeys, paths, selectedKeys);
    const path = location.pathname;
    if (path.includes("/measure/")) {
      setOpenKeys(["cal", "data", "2a"]);
      var x = localStorage.getItem("keyPath");
      setSelectedKeys(JSON.parse(x));
    }

    if (path.includes("/reduce")) {
      setOpenKeys(["red2", "4"]);
      var x = localStorage.getItem("keyPath");
      setSelectedKeys(JSON.parse(x));
    }

    if (path.includes("/settings/")) {
      setOpenKeys(["sett", "6a"]);
      var x = localStorage.getItem("keyPath");
      setSelectedKeys(JSON.parse(x));
    }
    if (path.includes("/analyse/")) {
      setOpenKeys(["cal1", "data1", "3"]);
      var x = localStorage.getItem("keyPath");
      setSelectedKeys(JSON.parse(x));
    }
    if (path === "/") {
      setSelectedKeys("1");
      setOpenKeys([]);
    }
    if (path === "/report" || path === "/report/report-table") {
      setSelectedKeys("5");
      setOpenKeys([]);
    }
  }, [location]);

  const handleOpenChange = (keys) => {
    // console.log(keys);
    setOpenKeys(keys);
    if (keys.includes("3")) {
      setOpenKeys((prevOpenKey) => [...prevOpenKey, "cal1", "data1"]);
    }
    if (keys.includes("2a")) {
      setOpenKeys((prevOpenKey) => [...prevOpenKey, "cal", "data"]);
    }
    if (keys.includes("6a")) {
      setOpenKeys((prevOpenKey) => [...prevOpenKey, "sett"]);
    }
    if (keys.includes("4")) {
      setOpenKeys((prevOpenKey) => [...prevOpenKey, "red2"]);
    }
  };
  const handleSelectChange = ({ item, key, keyPath, selectedKeys }) => {
    // console.log(item, key, keyPath, selectedKeys);
    // setSelectedKeys(keyPath);
    localStorage.setItem("keyPath", JSON.stringify(keyPath));
  };

  const logout = () => {
    localStorage.removeItem("loggedIn");
    navigation("/login");
  };

  const editRowDD = [
    {
      label: (
        <div className="tawazin-menu">
          Switch to
          <img src={Tawazin} />
        </div>
      ),
      key: "0",
      icon: <img src={Switch} />,
    },
    {
      label: "Logout",
      key: "1",
      icon: <img src={Logout} />,
    },
  ];

  const editRow = (key) => {
    // setOpenDD(true);
    console.log(key);
    if (key === "1") {
      logout();
    }
  };

  return (
    <aside className="sidebar-menu">
      <div className="logo-side">
        <img className="ant-menu-item" src={Logo} />
      </div>
      <div className="menu-sec">
        {/* {selectedKeys} */}
        <Menu
          // defaultOpenKeys={["cal", "data", "2a"]}
          mode="inline"
          // defaultSelectedKeys={["2a", "2-2-1"]}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          onSelect={handleSelectChange}
        >
          <Menu.Item key="1">
            <Link to="/">
              <img src={Home} />
              Home
            </Link>
          </Menu.Item>
          <SubMenu
            key="2a"
            title={
              <p>
                <img src={Calculator} />
                Measure
              </p>
            }
          >
            <Menu.SubMenu title="Calculate" key="cal">
              <Menu.Item key="2-2-1">
                <Link to="/measure/footprint">Footprint sources</Link>
              </Menu.Item>
              <Menu.Item key="2-2-2">
                <Link to="/measure/collect">Collect</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Manage data" key="data">
              <Menu.Item key="2-3-1">
                <Link to="/measure/activity-ledger">
                  <span>
                    CO<sub>2</sub>e activity ledger
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2-3-2">
                <Link to="/measure/catalog">Catalogs</Link>
              </Menu.Item>
              <Menu.Item key="2-3-3">
                <Link to="/measure/file-upload">File upload</Link>
              </Menu.Item>
              <Menu.Item key="2-3-4">
                <Link to="/measure/job-monitoring">Job monitoring</Link>
              </Menu.Item>
              <Menu.Item key="2-3-5">
                <Link to="/measure/integration-hub">Integration Hub</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </SubMenu>
          <SubMenu
            key="3"
            title={
              <p>
                <img src={Analyze} />
                Analyze
              </p>
            }
          >
            <Menu.SubMenu title="Explore" key="cal1">
              <Menu.Item key="3-2-1">
                <Link to="/analyse/overview">Overview</Link>
              </Menu.Item>
              <Menu.Item key="3-2-2">
                <Link to="/analyse/analytics">Analytics</Link>
              </Menu.Item>
              <Menu.Item key="3-2-3">
                <Link to="/analyse/financial-entity">Financed entities</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Footprint Insights" key="data1">
              <Menu.Item key="3-3-1">
                <Link to="/analyse/facility-analysis">Facility analysis</Link>
              </Menu.Item>
              <Menu.Item key="3-3-2">
                <Link to="/analyse/value-analysis">Value chain analysis</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </SubMenu>

          <SubMenu
            key="4"
            title={
              <p>
                <img src={Reduce} />
                Reduce
              </p>
            }
          >
            <Menu.SubMenu title="Decarbonization" key="red2">
              <Menu.Item key="4-1">
                <Link to="/reduce/reduction">Reduction Modeling</Link>
              </Menu.Item>
              <Menu.Item key="4-2">
                <Link to="/reduce/decarbonization">
                  Decarbonization Pathway
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </SubMenu>

          <Menu.Item key="5">
            <Link to="/report">
              <img src={Report} />
              Report
            </Link>
          </Menu.Item>
        </Menu>
        <Menu
          className="othaer-menu"
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          onSelect={handleSelectChange}
        >
          <SubMenu
            key="6a"
            title={
              <p>
                <img src={Calculator} />
                Settings
              </p>
            }
          >
            <Menu.SubMenu key="sett">
              <Menu.Item key="6-1">
                <Link to="/settings/profile">Profile</Link>
              </Menu.Item>
              <Menu.Item key="6-2">
                <Link to="/settings/configration">Configration</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </SubMenu>

          <Menu.Item key="7">
            <Link to="/">
              <img src={Faq} />
              Help and support
            </Link>
          </Menu.Item>
          <Menu.Item key="8" onClick={showDrawer}>
            <Link>
              <img src={Bell} />
              Notification
              <Badge
                count="02"
                style={{
                  background: "#EB632C",
                  borderColor: "rgba(10, 15, 41, 0.08)",
                }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item key="9" className="profile-item">
            <p onClick={showDrawerProfile}>
              <span className="menu-avatar">
                <img src={AvatarIcon} />
              </span>
              <span className="avatar-desc">
                <strong>
                  John Doe
                  <Badge
                    count="Unverified"
                    style={{
                      background: "#FFEAE1",
                      borderColor: "#FFEAE1",
                      color: "#A83F14",
                      borderRadius: 4,
                      fontSize: 12,
                      fontWeight: 500,
                      padding: "0px 4px",
                      height: 16,
                      lineHeight: 1.35,
                    }}
                  />
                </strong>
                Acme Corporation
              </span>
            </p>
            <Dropdown
              menu={{
                items: editRowDD,
                onClick: (event) => {
                  const { key } = event;
                  editRow(key);
                },
              }}
              trigger={["click"]}
              placement="topRight"
            >
              <Button
                type="text"
                icon={<EllipsisOutlined />}
                iconPosition="start"
              ></Button>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>

      <Drawer
        title={
          <h4 className="m-0">
            <ArrowLeftOutlined style={{ fontSize: 16, marginRight: 4 }} />{" "}
            Notifications
          </h4>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <div className="steps-wizard notification-body">
          <div className="ant-form">
            <Radio.Group
              buttonStyle="solid"
              size="large"
              onChange={handleModeChange}
              value={mode}
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="unread">
                Unread{" "}
                <Badge
                  count="3"
                  style={{
                    backgroundColor: "#E9EAEC",
                    borderColor: "#E9EAEC",
                    color: "#0F1324",
                    fontWeight: "500",
                    borderRadius: "3px",
                  }}
                />
              </Radio.Button>
            </Radio.Group>

            <div className="mt-24">
              {mode === "all" ? (
                <Space
                  direction="vertical"
                  size={[24, 24]}
                  style={{ width: "100%" }}
                >
                  <NotifyCard unread />

                  <div className="or">
                    <p className="m-0">Read</p>
                  </div>

                  <NotifyCard />
                </Space>
              ) : (
                <Space
                  direction="vertical"
                  size={[24, 24]}
                  style={{ width: "100%" }}
                >
                  <NotifyCard unread />
                  <NotifyCard unread />
                </Space>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        title={<h4 className="m-0">Profile Details</h4>}
        onClose={onCloseProfile}
        open={openProfile}
        width={675}
        closable={false}
        className="profile-drawer"
        extra={
          <Space>
            <Button type="text" onClick={onCloseProfile}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <>
          <div className="text-center mb-20 mt-20">
            <Space direction="vertical">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                src={<img src={ProfileImg} alt="avatar" />}
              />
              <h5 className="m-0 text-black-60 text-inter font-500">
                Change Image <EditOutlined />
              </h5>
            </Space>
          </div>
          <Card
            bordered={false}
            type="inner"
            title="Basic details"
            className="mb-20"
          >
            <List itemLayout="horizontal">
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">Organization name</p>
                  <p className="m-0">Acme Corporation</p>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">First name</p>
                  <p className="m-0">John</p>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">Last name</p>
                  <p className="m-0">Doe</p>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">Corporate email</p>
                  <p className="m-0">acme.johndoe@gmail.com</p>
                </Flex>
              </List.Item>
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">Location</p>
                  <p className="m-0">United Arab Emirates</p>
                </Flex>
              </List.Item>
            </List>
          </Card>
          <Card bordered={false} type="inner" title="Password details">
            <List itemLayout="horizontal">
              <List.Item>
                <Flex justify="space-between" className="w-100">
                  <p className="m-0">Password</p>
                  <Space size={[24, 8]}>
                    <p className="m-0">
                      Ja*********** <LockOutlined />
                    </p>
                    <p
                      className="m-0 text-primary text-underline fw-500 cursor-pointer"
                      onClick={showPasswordField}
                    >
                      Change Password
                    </p>
                  </Space>
                </Flex>
              </List.Item>
              {passwordFieldEnable && (
                <List.Item className="pb-0">
                  <Form layout="vertical" className="w-100">
                    <Form.Item label="Current Password" name="password">
                      <Input.Password placeholder="ahya1234" />
                    </Form.Item>
                  </Form>
                </List.Item>
              )}
            </List>
          </Card>
        </>
      </Drawer>
    </aside>
  );
};

export default Sidebar;

import React from "react";
import { Flex, Button, notification } from "antd";
import { EllipsisOutlined, CheckOutlined } from "@ant-design/icons";

const DataOptions = ({ onCloseTemplate }) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Success message",
      description:
        "Your template is being generated. Please allow a few moments for the download to begin.",
      placement: "bottomRight",
      icon: <CheckOutlined />,
    });
    if (onCloseTemplate) {
      onCloseTemplate();
    }
  };
  return (
    <>
      {contextHolder}
      <Flex
        gap="middle"
        className="px-3 mb-2 cursor-pointer"
        onClick={() => openNotificationWithIcon("success")}
      >
        <p className="m-0 fw-500 text-black">
          GHGP - Equipment - Fugitive Emissions - Industrial Gases -
          Purchase-Based
        </p>
        <Button
          type="text"
          icon={<EllipsisOutlined />}
          iconPosition="end"
        ></Button>
      </Flex>
      <Flex
        gap="middle"
        className="px-3 mb-2"
        onClick={() => openNotificationWithIcon("success")}
      >
        <p className="m-0 fw-500 text-black">
          GHGP - Equipment - Fugitive Emissions - Industrial Gases -
          Purchase-Based
        </p>
        <Button
          type="text"
          icon={<EllipsisOutlined />}
          iconPosition="end"
        ></Button>
      </Flex>
    </>
  );
};

export default DataOptions;

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import UseDocumentTitle from "../hooks/useDocumentTitle";
import FinancedEntity from "../Container/measure/catalog/financedEntity/index.js";
import EmissionFactor from "../Container/measure/catalog/emissionFactor/index.js";
import FootprintSource from "../Container/measure/catalog/footprintSource/index.js";
import Facilities from "../Container/measure/catalog/facilities/index.js";
import Location from "../Container/measure/catalog/location/index.js";
import Login from "../Container/auth/login/index.js";
import Signup from "../Container/auth/signup/index.js";
import SignupConfirm from "../Container/auth/signupConfirm/index.js";
import VerifyOtp from "../Container/auth/otpVerify/index.js";
import ResetPassword from "../Container/auth/resetPassword/index.js";
import ResetConfirm from "../Container/auth/resetConfirm/index.js";
import SetNewPassword from "../Container/auth/setNewPassword/index.js";
import PasswordConfirm from "../Container/auth/passwordConfirm/index.js";
import Verification from "../Container/verification/index.js";
import VerificationComp from "../Container/verification/VerificationComp.js";
import Home from "../Container/home/index.js";
import Footprint from "../Container/measure/footprint/index.js";
import ConfigureChecklist from "../Container/measure/footprint/configureChecklist.js";
import ManualConfigration from "../Container/measure/footprint/manualConfigration.js";
import GuidedConfigration from "../Container/measure/footprint/guidedConfigration.js";
import ActivityLedger from "../Container/measure/activityLedger/index.js";
import Catalog from "../Container/measure/catalog/index.js";
import Fileupload from "../Container/measure/fileupload/index.js";
import IntegrationHub from "../Container/measure/integrationHub/index.js";
import Collect from "../Container/measure/collect/index.js";
import JobMonitoring from "../Container/measure/jobMonitoring/index.js";

// import ProtectedRoutes from "../sharedModules/ProtectedRoutes.js";
import Layouts from "../sharedModules/defaultLayout/index.js";
import Overview from "../Container/analyse/overview/index.js";
import Analytics from "../Container/analyse/analytics/index.js";
import FinancialEntity from "../Container/analyse/financialEntity/index.js";
import EntityOverview from "../Container/analyse/financialEntity/entityOverview.js";
import FacilityAnalysis from "../Container/analyse/facilityAnalysis/index.js";
import ValueAnalysis from "../Container/analyse/valueAnalysis/index.js";
import Report from "../Container/report/index.js";
import ReportTable from "../Container/report/ReportTable.js";
import Profile from "../Container/settings/profile/index.js";
import Configration from "../Container/settings/configration/index.js";
import Reduce from "../Container/reduce/reduction/index.js";
import Decarbonization from "../Container/reduce/decarbonization/index.js";

const MyRoutes = () => {
  useEffect(() => {
    //  console.log('knlk', Home)
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Login" />
              <Login />
            </>
          }
        />

        <Route
          path="/signup"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Signup" />
              <Signup />
            </>
          }
        />

        <Route
          path="/reset-password"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Reset password" />
              <ResetPassword />
            </>
          }
        />
        <Route
          path="/reset-confirm"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Reset confirm" />
              <ResetConfirm />
            </>
          }
        />

        <Route
          path="/set-new-password"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Set new password" />
              <SetNewPassword />
            </>
          }
        />
        <Route
          path="/new-password-confirmation"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Password confirmation" />
              <PasswordConfirm />
            </>
          }
        />

        <Route
          path="/signup-complete"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Signup confirmation" />
              <SignupConfirm />
            </>
          }
        />

        {/* ProtectedRoute */}
        {/* <Route element={<ProtectedRoutes />}> */}
        <Route
          element={
            <Layouts>
              <Outlet />
            </Layouts>
          }
        >
          <Route
            path="/"
            // element={
            //   <Layouts>
            //     <Outlet />
            //   </Layouts>
            // }
          >
            <Route
              path="/"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Home" />
                  <Home />
                </>
              }
            />
            <Route
              path="/reduce/reduction"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Home" />
                  <Reduce />
                </>
              }
            />
            <Route
              path="/reduce/decarbonization"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Home" />
                  <Decarbonization />
                </>
              }
            />

            <Route
              path="/report"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Report" />
                  <Report />
                </>
              }
            />
            <Route
              path="/settings/profile"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Profile" />
                  <Profile />
                </>
              }
            />
            <Route
              path="/settings/configration"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Configration" />
                  <Configration />
                </>
              }
            />

            <Route
              path="/report/report-table"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Report" />
                  <ReportTable />
                </>
              }
            />

            <Route
              path="/analyse/overview"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Overview" />
                  <Overview />
                </>
              }
            />
            <Route
              path="/analyse/analytics"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Overview" />
                  <Analytics />
                </>
              }
            />
            <Route
              path="/analyse/financial-entity"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - financial-entity" />
                  <FinancialEntity />
                </>
              }
            />
            <Route
              path="/analyse/financial-entity/entity-overview"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - financial-entity" />
                  <EntityOverview />
                </>
              }
            />
            <Route
              path="/analyse/value-analysis"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - financial-entity" />
                  <ValueAnalysis />
                </>
              }
            />
            <Route
              path="/analyse/facility-analysis"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - financial-entity" />
                  <FacilityAnalysis />
                </>
              }
            />
            <Route
              path="/measure/activity-ledger"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Activity Ledger" />
                  <ActivityLedger />
                </>
              }
            />
            <Route
              path="/measure/catalog"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <Catalog />
                </>
              }
            />
            <Route
              path="/measure/catalog/facilities"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <Facilities />
                </>
              }
            />
            <Route
              path="/measure/catalog/locations"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <Location />
                </>
              }
            />
            <Route
              path="/measure/catalog/financed-entity"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <FinancedEntity />
                </>
              }
            />
            <Route
              path="/measure/catalog/emission-factor"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <EmissionFactor />
                </>
              }
            />
            <Route
              path="/measure/catalog/footprint-source"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Catalog" />
                  <FootprintSource />
                </>
              }
            />

            <Route
              path="/measure/file-upload"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - file upload" />
                  <Fileupload />
                </>
              }
            />
            <Route
              path="/measure/integration-hub"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Integration hub" />
                  <IntegrationHub />
                </>
              }
            />
            <Route
              path="/measure/collect"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Collect" />
                  <Collect />
                </>
              }
            />
            <Route
              path="/measure/job-monitoring"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Job Monitoring" />
                  <JobMonitoring />
                </>
              }
            />
            <Route
              path="/measure/footprint"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Footprint" />
                  <Footprint />
                </>
              }
            />
            <Route
              path="/measure/footprint/configure-checklist"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Configure checklist" />
                  <ConfigureChecklist />
                </>
              }
            />
            <Route
              path="/measure/footprint/configure-checklist/manual-configration"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Configure checklist-Manual" />
                  <ManualConfigration />
                </>
              }
            />
            <Route
              path="/measure/footprint/configure-checklist/guided-configration"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Configure checklist-Guided" />
                  <GuidedConfigration />
                </>
              }
            />
            <Route
              path="/verification"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Verification" />
                  <Verification />
                </>
              }
            />
            <Route
              path="/verification-completed"
              element={
                <>
                  <UseDocumentTitle title="Ahya OS - Verification completed" />
                  <VerificationComp />
                </>
              }
            />
          </Route>

          <Route
            path="/signup"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Home" />
                <Home />
              </>
            }
          />

          <Route
            path="/report"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Report" />
                <Report />
              </>
            }
          />

          <Route
            path="/report/report-table"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Report" />
                <ReportTable />
              </>
            }
          />

          <Route
            path="/analyse/overview"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Overview" />
                <Overview />
              </>
            }
          />
          <Route
            path="/analyse/analytics"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Overview" />
                <Analytics />
              </>
            }
          />
          <Route
            path="/analyse/financial-entity"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - financial-entity" />
                <FinancialEntity />
              </>
            }
          />
          <Route
            path="/analyse/financial-entity/entity-overview"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - financial-entity" />
                <EntityOverview />
              </>
            }
          />
          <Route
            path="/analyse/value-analysis"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - financial-entity" />
                <ValueAnalysis />
              </>
            }
          />
          <Route
            path="/analyse/facility-analysis"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - financial-entity" />
                <FacilityAnalysis />
              </>
            }
          />
          <Route
            path="/measure/activity-ledger"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Activity Ledger" />
                <ActivityLedger />
              </>
            }
          />
          <Route
            path="/measure/catalog"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <Catalog />
              </>
            }
          />
          <Route
            path="/measure/catalog/facilities"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <Facilities />
              </>
            }
          />
          <Route
            path="/measure/catalog/locations"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <Location />
              </>
            }
          />
          <Route
            path="/measure/catalog/financed-entity"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <FinancedEntity />
              </>
            }
          />
          <Route
            path="/measure/catalog/emission-factor"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <EmissionFactor />
              </>
            }
          />
          <Route
            path="/measure/catalog/footprint-source"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Catalog" />
                <FootprintSource />
              </>
            }
          />

          <Route
            path="/measure/file-upload"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - file upload" />
                <Fileupload />
              </>
            }
          />
          <Route
            path="/measure/integration-hub"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Integration hub" />
                <IntegrationHub />
              </>
            }
          />
          <Route
            path="/measure/collect"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Collect" />
                <Collect />
              </>
            }
          />
          <Route
            path="/measure/job-monitoring"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Job Monitoring" />
                <JobMonitoring />
              </>
            }
          />
          <Route
            path="/measure/footprint"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Footprint" />
                <Footprint />
              </>
            }
          />
          <Route
            path="/measure/footprint/configure-checklist"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Configure checklist" />
                <ConfigureChecklist />
              </>
            }
          />
          <Route
            path="/measure/footprint/configure-checklist/manual-configration"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Configure checklist-Manual" />
                <ManualConfigration />
              </>
            }
          />
          <Route
            path="/measure/footprint/configure-checklist/guided-configration"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Configure checklist-Guided" />
                <GuidedConfigration />
              </>
            }
          />
        </Route>

        {/* <Route
            path="/signup-complete"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Signup confirmation" />
                <SignupConfirm />
              </>
            }
          /> */}

        <Route
          path="/verify-code"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Verify OTP" />
              <VerifyOtp />
            </>
          }
        />
        {/* <Route
            path="/reset-password"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Reset password" />
                <ResetPassword />
              </>
            }
          />
          <Route
            path="/reset-confirm"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Reset confirm" />
                <ResetConfirm />
              </>
            }
          />
          <Route
            path="/set-new-password"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Set new password" />
                <SetNewPassword />
              </>
            }
          />
          <Route
            path="/new-password-confirmation"
            element={
              <>
                <UseDocumentTitle title="Ahya OS - Password confirmation" />
                <PasswordConfirm />
              </>
            }
          /> */}
        <Route
          path="/verification"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Verification" />
              <Verification />
            </>
          }
        />
        <Route
          path="/verification-completed"
          element={
            <>
              <UseDocumentTitle title="Ahya OS - Verification completed" />
              <VerificationComp />
            </>
          }
        />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;

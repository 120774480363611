import React, { useState } from "react";
import Tree from "react-d3-tree";
import {
  Dropdown,
  Form,
  Button,
  Flex,
  Drawer,
  Row,
  Col,
  Space,
  Input,
  Select,
  Badge,
} from "antd";
import {
  PlusOutlined,
  InfoCircleFilled,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

// Sample data
const myTreeData = [
  {
    name: "Acme Corporation",
    title: "Manufacturing",
    children: [
      {
        name: "Technova Solutions",
        title: "Information Technology",
      },
      {
        name: "Skyline",
        title: "Healthcare",
      },
      {
        name: "Innovatech Industries",
        title: "Finance",
      },
      {
        name: "Brightwave Enterprises",
        title: "Manufacturing",
        children: [
          {
            name: "Zenith Global",
            title: "Retail",
          },
        ],
      },
    ],
  },
];

// Custom Node Component
const CustomNode = ({ nodeDatum, openEditModal }) => {
  // Dropdown menu for each node
  const editRow = (key, data) => {
    // if (key === "view") {
    //   setDrawerOpenView(true);
    // }
    // if (key === "edit") {
    //   setDrawerOpenEdit(true);
    // }
    console.log(key);
    if (openEditModal) {
      openEditModal();
    }
  };

  const editRowDD = [
    {
      label: "Edit Organization",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Organization",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];
  return (
    <g>
      {/* Rectangle as the background of the node */}
      <rect
        width="305"
        height="90"
        x="-152"
        y="-40"
        fill="#fff"
        stroke="#3F686E"
        strokeWidth="1"
        rx="6"
        ry="6"
        filter="drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))"
      />
      {/* Avatar Circle */}
      <circle
        cx="-125"
        cy="-5"
        r="15"
        fill="#717684"
        strokeWidth="0"
        textAnchor="start"
      />
      {/* Avatar Text */}
      <text
        fill="#fff"
        x="-125"
        y="2"
        textAnchor="middle"
        fontSize="18"
        fontWeight="500"
        stroke="#fff"
        strokeWidth="0"
      >
        {nodeDatum.name.charAt(0)}
      </text>
      {/* Text for the name */}
      <text
        fill="#14151A"
        x="-100"
        y="-5"
        textAnchor="start"
        fontSize="14"
        fontWeight="600"
        strokeWidth="0"
      >
        {nodeDatum.name}
      </text>
      {/* Text for the title */}
      <text
        fill="#14151A"
        x="-100"
        y="13"
        textAnchor="start"
        fontSize="12"
        fontWeight="400"
        strokeWidth="0"
      >
        {nodeDatum.title}
      </text>
      {/* Ellipsis Dropdown Button */}
      <foreignObject
        x="105"
        y="-35"
        width="40"
        height="40"
        textAnchor="start"
        strokeWidth="0"
      >
        <Dropdown
          menu={{
            items: editRowDD,
            onClick: (event) => {
              const { key } = event;
              editRow(key);
            },
          }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button
            type="text"
            icon={<EllipsisOutlined />}
            iconPosition="start"
            style={{ transform: "rotate(90deg)", float: "right" }}
          ></Button>
        </Dropdown>
      </foreignObject>
    </g>
  );
};

const OrganizationStructure = () => {
  const [openAddDraw, setOpenAddDraw] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();

  const onClose = () => {
    setOpenAddDraw(false);
    setIsEdit(false);
  };

  const openEditModal = () => {
    setOpenAddDraw(true);
    setIsEdit(true);
  };

  const optionsAS = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          iconPosition="start"
          onClick={() => setOpenAddDraw(true)}
        >
          Add
        </Button>
      </Flex>
      <div
        className="treeWrapper"
        style={{ width: "100%", height: 700, marginTop: 16 }}
      >
        <Tree
          data={myTreeData}
          orientation="vertical"
          pathFunc="step"
          translate={{ x: 750, y: 190 }}
          renderCustomNodeElement={(rd3tProps) => (
            <CustomNode {...rd3tProps} openEditModal={openEditModal} />
          )}
          separation={{ siblings: 1, nonSiblings: 2 }}
          nodeSize={{ x: 320, y: 120 }}
        />
      </div>
      <Drawer
        className="footprintDrawer"
        title="Add Organization"
        onClose={onClose}
        open={openAddDraw}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <div className="activity-row">
            <div className="activity-body pt-0">
              <Form.Item
                label="Organization Name"
                name="typeDesc"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Quantum Dynamics" />
              </Form.Item>
              <Form.Item
                label="Organization Type"
                name="typeDesc1"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Research Institutions" />
              </Form.Item>
              <Form.Item
                label="Industry"
                name="industry"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Telecommunication" />
              </Form.Item>

              {isEdit ? (
                <>
                  <Form.Item
                    label="Scope 1 Emissions"
                    name="scopeEmissions"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="41.53"
                      suffix={
                        <Badge
                          count="tCO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Scope 2 Emissions"
                    name="scopeEmissions2"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="32.6"
                      suffix={
                        <Badge
                          count="tCO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Scope 3 Emissions"
                    name="scopeEmissions3"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="1.74"
                      suffix={
                        <Badge
                          count="tCO₂e"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ownership"
                    tooltip={{
                      title: "Ownership percentage cannot exceed 100%.",
                      icon: (
                        <InfoCircleFilled
                          style={{ color: "rgb(10, 15, 41, .25)" }}
                        />
                      ),
                      color: "#fff",
                      overlayInnerStyle: {
                        color: "#000",
                        fontSize: "12px",
                        width: "315px",
                        boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                        // border: "1px solid #DEE0E3",
                      },
                    }}
                    name="ownership"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Max 100"
                      suffix={
                        <Badge
                          count="%"
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "rgba(15, 19, 36, 0.6)",
                          }}
                        />
                      }
                    />
                  </Form.Item>
                </>
              ) : (
                ""
              )}
              <Form.Item
                label="Business address"
                name="businessaddress"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
              <Form.Item
                label="State / Province"
                name="State"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Dubai" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Dubai" />
              </Form.Item>
              <Form.Item
                label="Parent Organization"
                name="parentOrganization"
                extra="Select if this is a subsidiary of an organization."
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Technova Solution" options={optionsAS} />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input.TextArea placeholder="Account level resource group" />
              </Form.Item>
            </div>

            {isEdit ? (
              <>
                <div className="activity-head">
                  <h5 className="m-0 text-black">
                    Point of Contact Information
                  </h5>
                </div>
                <div className="activity-body">
                  <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Jemy" />
                  </Form.Item>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Manager" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="manager@ahya.com" />
                  </Form.Item>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default OrganizationStructure;

import React, { useState } from "react";
import { Row, Col, Form, Input, Select, Space, Button, Card, Flex } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const CompanyInfo = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const [form] = Form.useForm();
  const onFinish = (values) => {
    // onNext();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    // Checks if there are any errors
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    // Check if any field is empty
    const formValues = form.getFieldsValue(); // Get all field values as an object
    const hasEmptyFields = Object.values(formValues).some((value) => !value); // Check for empty values

    // Disable the button if there are errors or missing fields
    setIsButtonDisabled(hasErrors || hasEmptyFields);
  };
  return (
    <>
      <Form layout="vertical" form={form} onFieldsChange={handleFormChange}>
        <Card style={{ marginTop: 24 }}>
          <div className="card-header">
            <h3 className="m-0">Company registration and tax</h3>
            <p className="mt-6 mb-0 text-black-60">
              Provide legal documentation and information to verify your
              business.
            </p>
          </div>
          <div className="card-body">
            <Row gutter={[16, 0]} className="mt-24">
              <Col md={12}>
                <Form.Item
                  label="Incorporation certificate / Commercial license"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      // border: "1px solid #DEE0E3",
                    },
                  }}
                  name="commercialLic"
                  rules={[
                    {
                      required: true,
                      message: "Required  commercial license",
                    },
                  ]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Input placeholder="pdf or jpeg; 10 mb" />
                    <Button>Upload</Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Tax number"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      // border: "1px solid #DEE0E3",
                    },
                  }}
                  name="taxNumber"
                  rules={[
                    {
                      required: true,
                      message: "Required  tax number",
                    },
                  ]}
                >
                  <Input placeholder="eg: 483765837536481" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Tax document"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      // border: "1px solid #DEE0E3",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Input placeholder="pdf or jpeg; 10 mb" />
                    <Button>Upload</Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Others"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      // border: "1px solid #DEE0E3",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Input placeholder="pdf or jpeg; 10 mb" />
                    <Button>Upload</Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        <Card style={{ marginTop: 24 }}>
          <div className="card-header">
            <h3 className="m-0">Bank account information</h3>
            <p className="mt-6 mb-0 text-black-60">
              Provide your banking information to make digital transactions.
            </p>
          </div>
          <div className="card-body">
            <Row gutter={[16, 0]} className="mt-24">
              <Col md={12}>
                <Form.Item
                  label="IBAN"
                  name="iban"
                  rules={[
                    {
                      required: true,
                      message: "Required  IBAN",
                    },
                  ]}
                >
                  <Input placeholder="eg: 483765837536481" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Account title">
                  <Input placeholder="eg. Ahya Technologies" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Bank name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: "Required  bank name",
                    },
                  ]}
                >
                  <Input placeholder="eg. Emirates NBD" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Branch location"
                  name="branchLocation"
                  rules={[
                    {
                      required: true,
                      message: "Required  branch location",
                    },
                  ]}
                >
                  <Input placeholder="eg. Group Head Office" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Required  country",
                    },
                  ]}
                >
                  <Select
                    placeholder="eg. United Arab Emirates"
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Required  city",
                    },
                  ]}
                >
                  <Input placeholder="eg. Dubai" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          className="btn-h-40 mt-24"
          onClick={() => form.submit()}
          disabled={isButtonDisabled}
        >
          Update & save
        </Button>
      </Flex>
    </>
  );
};

export default CompanyInfo;

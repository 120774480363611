import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Badge,
  DatePicker,
  Card,
  Button,
  Spin,
} from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { useFetchGoalsAndTargets, useFetchYears } from "../../hooks/useApiHook";
import { API_SETTINGS_BASE_URL } from "../../config";

const GoalTarget = ({ current, onNext, onPrev }) => {
  // Fetching Goals And Target data values.
  const { goalsAndTargets, goalsAndTargetsErr } = useFetchGoalsAndTargets();

  // Fetching Years data.
  const { years, yearsLoader, yearsErr } = useFetchYears(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/years/?start_year=1920&end_year=2025`
  );

  const [form] = Form.useForm();
  const onFinish = (values) => {
    onNext();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        onFinish(values);
      })
      .catch((errorInfo) => {
        onFinishFailed(errorInfo);
      });
  };

  return (
    <>
      {goalsAndTargetsErr && "Something went wrong, Please refresh the page."}
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Goals & targets</h3>
          <p className="mt-6 mb-0 text-black-60">
            Your enterprise's goals help us provide a tailor made experience.
          </p>
        </div>
        <div className="card-body">
          <Form layout="vertical" form={form} className="mt-24">
            <Row gutter={[16, 0]}>
              <Col md={24}>
                <Form.Item
                  label="1. How many members will be added to your AhyaOS account?*"
                  name="members"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[0]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="2. What are you looking to accomplish with AhyaOS?*"
                  name="accomplish"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[1]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="3. Select the sub-categories of scope 3 emissions you will measure through AhyaOS"
                  name="scope3Emission"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[2]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="4. Have you measures your enterprise emissions before?"
                  name="emissionBefore"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[3]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        Please suggest what were your enterprise’s last year’s
                        annual GHG emissions? * 
                      </p>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label="Select Year"
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label="Emissions"
                        name="emission"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="tCO₂e"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label="Select one"
                        name="scopeIs"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid" size="large">
                          <Radio.Button value="a">Scope 1 & 2</Radio.Button>
                          <Radio.Button value="b">All Scopes</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="5. Do you have an emission reduction target?"
                  extra="This refers to near-term or long-term emission reduction goals."
                  name="target"
                  tooltip={{
                    title:
                      "This refers to near-term or long-term emission reduction goals.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                    },
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[6]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your base year emissions for the enterprise
                        target
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year1"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission1"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="tCO₂e"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What is your enterprise emission reduction target? 
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year2"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission2"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="tCO₂e"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="6. Have you committed to the SBTi?"
                  extra="Science Based Targets initiative enables entities globally to set emissions reductions targets in line with advancement in climate science."
                  name="commited"
                  tooltip={{
                    title:
                      "Science Based Targets initiative (SBTi) enables entities globally to set emissions reductions targets in line with advancement in climate science. For more details visit: https://sciencebasedtargets.org/",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                    },
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    <Radio.Button value="a">Yes</Radio.Button>
                    <Radio.Button value="b">No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your base year emissions? 
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year3"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission3"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="%"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your SBTi targets for scope 1 & 2? 
                      </p>
                      <p className="mt-0 mb-8 text-black fw-700">
                        Near-term target year
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year4"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission4"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="%"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your SBTi targets for scope 1 & 2? 
                      </p>
                      <p className="mt-0 mb-8 text-black fw-700">
                        Long-term target year 
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year5"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission5"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="%"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your SBTi targets for scope 3? 
                      </p>
                      <p className="mt-0 mb-8 text-black fw-700">
                        Near-term target year
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year6"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission6"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="tCO₂e"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="other-checks">
                  <Row gutter={[24, 0]}>
                    <Col md={24}>
                      <p className="mt-0 mb-8 text-black">
                        What are your SBTi targets for scope 3? 
                      </p>
                      <p className="mt-0 mb-8 text-black fw-700">
                        Long-term target year
                      </p>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Select Year"
                        name="year7"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please Select"
                          style={{ position: "rel" }}
                          dropdownRender={(menu) => (
                            <div>
                              {yearsLoader &&
                                "Something went wrong, please try again"}
                              {menu}
                              {yearsErr && (
                                <div
                                  style={{ textAlign: "center", padding: 8 }}
                                >
                                  <Spin />
                                </div>
                              )}
                            </div>
                          )}
                        >
                          {years?.length > 0
                            ? years?.map((year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Emissions"
                        name="emission7"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter value."
                          suffix={
                            <Badge
                              count="tCO₂e"
                              style={{
                                backgroundColor: "#E9EAEC",
                                color: "rgba(15, 19, 36, 0.6)",
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="7. Establish a reporting period"
                  name="reportPeriod"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  extra="The time period over which your enterprise measures, monitors, and reports their emissions. It is recommended that this aligns with the financial reporting period."
                >
                  <Row gutter={[16, 0]}>
                    <Col md={12}>
                      <DatePicker
                        placeholder="DD / MM / YYYY"
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col md={12}>
                      <DatePicker
                        placeholder="DD / MM / YYYY"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="8. Which sustainability standard will you report on?"
                  name="standards"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[8]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="9. Do you require additional support with use of Ahya OS"
                  name="supportOf"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[7]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="10. Are you interested in AI enabled measurement, reduction and understanding of emissions?"
                  name="intrestedAi"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" size="large">
                    {goalsAndTargets?.[4]?.values?.map((val) => (
                      <Radio.Button key={val?.id} value={val?.id}>
                        {val?.value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <div
        style={{ marginTop: 24 }}
        className={!current > 0 ? "flex-center-end" : "flex-center-between"}
      >
        {current > 0 && (
          <Button
            shape="round"
            icon={<ArrowLeftOutlined />}
            onClick={onPrev}
            size="large"
          >
            Back
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          size="large"
          onClick={handleNext}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default GoalTarget;

import { Form, Select, Spin } from 'antd';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useFetchCitiesData } from '../../hooks/useApiHook';
import { API_SETTINGS_BASE_URL } from '../../config';

const CitiesSelect = ({ stateId, countryId, isPersonalInfo }) => {
    const [citiesPage, setcitiesPage] = useState(0);

    const { cities, citiesLoader, citiesErr } = useFetchCitiesData(
        `${API_SETTINGS_BASE_URL}/v1/general_settings/cities/?skip=${citiesPage * 20
        }&limit=20&state_id=${stateId ? stateId : countryId}`
    );
    return (
        <>
            <Form.Item
                label="City"
                name="city"
                rules={[
                    {
                        required: true,
                        message: "Required city",
                    },
                ]}
            >
                {/* <Input placeholder="eg. Dubai" /> */}
                <Select
                    placeholder="Please Select"
                    style={{ position: "rel" }}
                    disabled={stateId | countryId ? false : true}
                    dropdownRender={(menu) => (
                        <div>
                            {citiesErr && "Something went wrong, please try again"}
                            {menu}
                            {citiesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <Spin />
                                </div>
                            )}
                            {!citiesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <NavLink
                                        onClick={() => {
                                            if (cities?.length > 0) {
                                                setcitiesPage((prevPage) => prevPage + 1);
                                            } else {
                                                setcitiesPage(0);
                                            }
                                        }}
                                    >
                                        {cities?.length >= 20 ? "Load more" : ""}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    )}
                >
                    {cities?.length > 0
                        ? cities?.map((city) => (
                            <Select.Option key={city.id} value={isPersonalInfo ? city?.name : city?.id}>
                                {city?.name}
                            </Select.Option>
                        ))
                        : null}
                </Select>
            </Form.Item>
        </>
    )
}

export default CitiesSelect

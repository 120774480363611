export const maskEmail = (email) => {
  console.log("email ", email);
  // Split email into local and domain parts
  const [localPart, domain] = email && email?.split("@");

  // Mask local part
  const maskedLocalPart =
    localPart?.length > 2
      ? `${localPart?.slice(0, 2)}${"*"?.repeat(
          localPart?.length - 2 - 1
        )}${localPart.slice(-1)}`
      : localPart; // Handle short local parts

  return `${maskedLocalPart}@${domain}`;
};

import React, { useState } from "react";
import CatalogLayout from "../CatalogLayout";
import {
  Flex,
  Space,
  Button,
  Dropdown,
  Switch,
  Input,
  Table,
  Drawer,
  Form,
  Select,
  Row,
  Col,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  LockOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import AddCatalog from "../addCatalog";
import Search from "../../../../images/search.svg";

const Facilities = () => {
  const equipmentData = {
    type: "Facility",
    content: [
      "After you have created at least one Location, you can attach Facilities to them.",
      "Facilities are the individual buildings, offices, floors, structures, equipment, or other stationary items that Locations are composed of. Some of your emission sources will be Facilities at specific Locations.",
    ],
  };
  const [equipmentModalData, setEquipmentModalData] = useState(equipmentData);
  const [open, setOpen] = useState(false);
  const [addManually, setAddManually] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onClose = () => {
    setOpen(false);
    setAddManually(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Facility",
      dataIndex: "equipment",
      key: "equipment",
      width: 660,
      sorter: (a, b) => a.equipment - b.equipment,
    },
    {
      title: "Type",
      dataIndex: "category",
      key: "category",
      width: 300,
    },
    {
      title: "City",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
      sorter: (a, b) => a.itemSource - b.itemSource,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Acme Warehouse",
      category: "Manufacturing & Industrial",
      itemSource: "Dubai",
    },
    {
      key: "2",
      equipment: "KSA Sales Office",
      category: "Office",
      itemSource: "Riyadh",
    },
    {
      key: "3",
      equipment: "UAE Branch",
      category: "Ware house & Storage",
      itemSource: "Sharjah",
    },
    {
      key: "4",
      equipment: "USA Branch",
      category: "Office",
      itemSource: "Miami",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const addManully = () => {
    setAddManually(true);
  };
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];
  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Facilities</h4>
        </Space>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Export
          </Button>
        </Dropdown>
      </Flex>
      <Space className="mt-24">
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Category
          </Button>
        </Dropdown>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Subcategory
          </Button>
        </Dropdown>
      </Space>
      <Flex
        justify="space-between"
        align="center"
        className="mt-24"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500 }}
        />
        <Space size={[20, 20]}>
          <Space>
            <Switch />
            <p className="m-0">Show archived</p>
          </Space>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            type="primary"
            className="btn-h-40 fw-500"
            onClick={() => setOpen(true)}
          >
            Add Facilities
          </Button>
        </Space>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>
      <Drawer
        className="footprintDrawer"
        title="Add Facility"
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <AddCatalog
          onClose={onClose}
          equipmentModalData={equipmentModalData}
          addManully={addManully}
        />
      </Drawer>

      <Drawer
        className="footprintDrawer"
        title="Add Facility"
        onClose={onClose}
        open={addManually}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" className="btn-h-40" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
              onClick={onClose}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <Form layout="vertical">
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Facility Information</h5>
            </div>
            <div className="activity-body">
              <Form.Item
                label="Facility name"
                name="facility"
                rules={[
                  {
                    required: true,
                    message: "Required facility name",
                  },
                ]}
              >
                <Input placeholder="Cooler" />
              </Form.Item>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Required category",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Sub category"
                name="scategory"
                rules={[
                  {
                    required: true,
                    message: "Required sub category",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Required location",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Location details"
                name="locationD"
                rules={[
                  {
                    required: true,
                    message: "Required location details",
                  },
                ]}
              >
                <Row gutter={[24, 0]}>
                  <Col md={12}>
                    <Form.Item
                      label="Country"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Required country",
                        },
                      ]}
                    >
                      <Select placeholder="Please Select" options={options} />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label="Zip code"
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Required Zip code",
                        },
                      ]}
                    >
                      <Input placeholder="12675" suffix={<LockOutlined />} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Internal ID"
                name="internalID"
                rules={[
                  {
                    required: true,
                    message: "Required internal ID",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
              <Form.Item
                label="Facility description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Required facility description",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
              <Form.Item
                label="Refrigerant remaining at disposal (Remaining percentage)"
                name="refrigerant"
                rules={[
                  {
                    required: true,
                    message: "Required Refrigerant remaining at disposal",
                  },
                ]}
              >
                <Input placeholder="0" />
              </Form.Item>
            </div>
          </div>

          <div className="activity-row mt-24">
            <div className="activity-head">
              <h5 className="m-0 text-black">Facility Information</h5>
            </div>
            <div className="activity-body">
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <Form.Item
                    label="Gross area"
                    name="gross"
                    rules={[
                      {
                        required: true,
                        message: "Required gross area",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={options} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Gross area units"
                    name="area"
                    rules={[
                      {
                        required: true,
                        message: "Required gross area units",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={options} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Gross area units"
                    name="units"
                    rules={[
                      {
                        required: true,
                        message: "Required gross area units",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please Select"
                      addonAfter={<PercentageOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Drawer>
    </CatalogLayout>
  );
};

export default Facilities;

import React, { useState } from "react";
import { Card, Radio, Tooltip } from "antd";

import info from "../../../images/info.png";

const BusinessAssets = () => {
  const [value, setValue] = useState("");
  const [valueFC, setValueFC] = useState("");
  const [valueES, setValueES] = useState("");
  const [valuePL, setValuePL] = useState("");
  const [valueLO, setValueLO] = useState("");
  const [valueER, setValueER] = useState("");
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onChangeFC = (e) => {
    setValueFC(e.target.value);
  };
  const onChangeES = (e) => {
    setValueES(e.target.value);
  };
  const onChangePL = (e) => {
    setValuePL(e.target.value);
  };
  const onChangeLO = (e) => {
    setValueLO(e.target.value);
  };
  const onChangeER = (e) => {
    setValueER(e.target.value);
  };
  return (
    <>
      <h4 className="mt-3 mb-3 fs-18">Questions</h4>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Does your business have significant investment activities?
          </h5>
          <p className="m-0 fs-12">
            (e.g. project finance, mortgages, business loans, equity, commercial
            real estate, motor vehicle loans, bonds, etc.)?
          </p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeFC} value={valueFC}>
              <li>
                <Radio value="tes">
                  <p className="m-0 fw-500">Yes</p>
                </Radio>
              </li>
              <li>
                <Radio value="no">
                  <p className="m-0 fw-500">No</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Which of the following asset types do you have in your business?
            These may be owned or leased assets.
          </h5>
          <p className="m-0 fs-12">Select all that apply.</p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChange} value={value}>
              <li>
                <Radio value="equip">
                  <p className="m-0 fw-500">
                    Equipment (e.g. boilers, air conditioning units, chillers,
                    furnaces, etc.)
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="fleet">
                  <p className="m-0 fw-500">
                    Fleet (e.g. car, motorbikes, planes, ships, etc.)
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="leased">
                  <p className="m-0 fw-500">Leased Assets</p>
                </Radio>
              </li>
              <li>
                <Radio value="emsiison">
                  <p className="m-0 fw-500">
                    Emissions For Oil and Gas Operations
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="none">
                  <p className="m-0 fw-500">None of the above</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Let's start with your operating assets. Do you have any operating
            assets in your business
          </h5>
          <p className="m-0 fs-12">
            (e.g. fleet, equipment, leased assets, etc.)
          </p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeES} value={valueES}>
              <li>
                <Radio value="yes1">
                  <p className="m-0 fw-500">Yes</p>
                </Radio>
              </li>
              <li>
                <Radio value="no1">
                  <p className="m-0 fw-500">No</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Now let's understand more about your physical locations. Does your
            business operate at any physical locations.
          </h5>
          <p className="m-0 fs-12">
            (e.g. offices, manufacturing plants, warehousing, retail stores, or
            maintenance facilities)? This includes any locations leased,
            operated, and/or owned by your business.
          </p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangePL} value={valuePL}>
              <li>
                <Radio value="yes2">
                  <p className="m-0 fw-500">Yes</p>
                </Radio>
              </li>
              <li>
                <Radio value="no2">
                  <p className="m-0 fw-500">No</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Do you own or lease your organization's sites and/or facilities from
            a third-party?
          </h5>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeLO} value={valueLO}>
              <li>
                <Radio value="lease1">
                  <p className="m-0 fw-500">Lease</p>
                </Radio>
              </li>
              <li>
                <Radio value="own">
                  <p className="m-0 fw-500">Own</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
      <Card className="conf-card">
        <div className="card-header">
          <h5 className="m-0 fs-16">
            Which of the following employee-related emission sources are
            relevant to your business?
          </h5>
          <p className="m-0 fs-12">Select all that apply.</p>
        </div>
        <div className="card-body">
          <ul>
            <Radio.Group onChange={onChangeER} value={valueER}>
              <li>
                <Radio value="ect">
                  <p className="m-0 fw-500">
                    Employee Commuting and Teleworking
                  </p>
                </Radio>
              </li>
              <li>
                <Radio value="bt">
                  <p className="m-0 fw-500">Business Travel</p>
                </Radio>
              </li>
              <li>
                <Radio value="noneA">
                  <p className="m-0 fw-500">None of the above</p>
                </Radio>
              </li>
            </Radio.Group>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default BusinessAssets;

import React, { useState } from "react";
import AuthLayout from "../../../sharedModules/authtLayout";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { newPasswordRequest } from "../../../utils/api-services/auth";
import { API_SETTINGS_BASE_URL } from "../../../config";

const SetNewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [msg, setMsg] = useState({ type: null, message: null });
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  console.log("location ", searchParams.get("token"));

  const onFinish = async (values) => {
    const token = searchParams.get("token");
    console.log("token ", token);
    if (!token) return console.log("token not found.");
    console.log("Success:", values);
    const url = `${API_SETTINGS_BASE_URL}/v1/auth_sso_otp/reset-password-sso/`;
    try {
      setLoading(true);
      const response = await newPasswordRequest(token, values?.password, url);
      setLoading(false);

      console.log("Response ", response);
      if (response?.status === 200) {
        navigate("/new-password-confirmation");
      } else {
        console.log("failed");
      }
    } catch (error) {
      setLoading(false);
      console.log("errorr ", error);
      setMsg({ type: "error", message: error?.message });

      setTimeout(() => {
        setMsg({ type: null, message: null });
      }, 5000);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      <Card bordered={false}>
        <h3 className="text-center m-0 fw-700">Set new password</h3>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mt-24"
        >
          <Row gutter={[16, 0]}>
            <Col md={24}>
              <Form.Item
                label="New Password"
                name="password"
                rules={[{ required: true, message: "Required  password!" }]}
              >
                <Input.Password placeholder="eg. ●●●●●●●●●●" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Confirm New Password"
                name="Cpassword"
                rules={[{ required: true, message: "Required  password!" }]}
              >
                <Input.Password placeholder="eg. ●●●●●●●●●●" />
              </Form.Item>
            </Col>
            <Col md={24}>
              {msg.type && (
                <p
                  style={{
                    color: msg.type === "error" ? "#FF4D4F" : "#25956a",
                    marginTop: "-15px",
                  }}
                >
                  {msg?.message}
                </p>
              )}
            </Col>
            <Col md={24} className="mt-10">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { Cpassword, password } = getFieldsValue();
                  const formIsComplete = !!Cpassword && !!password;
                  return (
                    <Button
                      // onClick={onFinish}
                      type="primary"
                      shape="round"
                      icon={<CheckOutlined />}
                      disabled={!formIsComplete || loading}
                      iconPosition="end"
                      block
                      size="large"
                      htmlType="submit"
                    >
                      {loading && <Spin tip="Loading" size="small" />}
                      Set new password
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default SetNewPassword;

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Card,
  Button,
  Spin,
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useFetchIndustryData } from "../../hooks/useApiHook";
import { API_SETTINGS_BASE_URL } from "../../config";
import { saveBusinessDetails } from "../../redux/slices/businessDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import StatesSelect from "../../sharedModules/verification/StatesSelect";
import CitiesSelect from "../../sharedModules/verification/CitiesSelect";
import CountriesSelect from "../../sharedModules/verification/CountriesSelect";

const BusinessDetail = ({ current, onNext, onPrev }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.businessDetails);
  const [form] = Form.useForm();
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // Fetching Industry data.
  const { industries, industriesLoader, industriesErr } = useFetchIndustryData(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/industry-sector/`
  );

  const selectBefore = (
    <Select defaultValue="add" style={{ width: 80 }}>
      <Option value="add">971</Option>
      <Option value="minus">+92</Option>
    </Select>
  );

  const onFinish = (values) => {
    // onNext();
    console.log("Success:", values);
    const url = `${API_SETTINGS_BASE_URL}/v1/profile-verfication/business-details`;
    const raw = {
      companyLegalName: values?.companyName,
      industry_id: values?.industry,
      corporateWebsite: values?.websiteURL,
      contactNumber: values?.contactNumber?.toString(),
      businessAddressLine1: values?.address1,
      businessAddressLine2: values?.address2,
      postalCode: values?.poBox,
      country_id: values?.country,
      state_id: values?.state,
      city_id: values?.city,
    };

    dispatch(saveBusinessDetails({ url, raw })).then((action) => {
      if (saveBusinessDetails.fulfilled.match(action)) {
        onNext();
        localStorage.setItem("bussinessDetails", JSON.stringify(values));
      } else {
        setErrorMsg(
          typeof action.payload === "string"
            ? action.payload
            : "Something went wrong, please try again."
        );
        console.log("Error:", action);
      }

      if (saveBusinessDetails.rejected.match(action)) {
        console.log("action ", action);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        onFinish(values);
      })
      .catch((errorInfo) => {
        onFinishFailed(errorInfo);
      });
  };

  // useEffect(() => {
  //   console.log("error ", error);
  //   if (error) {
  //     const formattedErrors =
  //       error?.length > 0 &&
  //       error.reduce((acc, err) => {
  //         const [_, field] = err.loc;
  //         acc[field] = err.msg;
  //         return acc;
  //       }, {});
  //     setApiErrors(formattedErrors);

  //     console.log("formattedErrors ", formattedErrors);
  //     setApiErrors(error);
  //   }
  // }, [data, error]);

  useEffect(() => {
    // Retrieve form values from localStorage
    const storedValues =
      JSON.parse(localStorage.getItem("bussinessDetails")) || null;
    if (storedValues) {
      form.setFieldsValue(storedValues);
    }
  }, [form]);

  return (
    <>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Business details</h3>
          <p className="mt-6 mb-0 text-black-60">
            Your business information helps us with verification of your
            enterprise.
          </p>
        </div>
        <div className="card-body">
          <Form layout="vertical" form={form} className="mt-24">
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Form.Item
                  label="Company legal name"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Required  company legal name",
                    },
                  ]}
                >
                  <Input placeholder="eg. Ahya Technologies LTD." />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Required  industry",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    style={{ position: "rel" }}
                    dropdownRender={(menu) => (
                      <div>
                        {industriesErr &&
                          "Something went wrong, please try again"}
                        {menu}
                        {industriesLoader && (
                          <div style={{ textAlign: "center", padding: 8 }}>
                            <Spin />
                          </div>
                        )}
                      </div>
                    )}
                  >
                    {industries?.length > 0
                      ? industries?.map((industry) => (
                          <Select.Option key={industry.id} value={industry?.id}>
                            {industry?.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Corporate website"
                  name="websiteURL"
                  rules={[
                    {
                      required: true,
                      message: "Required corporate website",
                    },
                  ]}
                >
                  <Input
                    addonBefore="Website"
                    placeholder="Enter website URL"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Required contact Number",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={selectBefore}
                    placeholder="Enter number"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Business address (line 1)"
                  name="address1"
                  rules={[
                    {
                      required: true,
                      message: "Required business address (line 1)",
                    },
                  ]}
                >
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Business address (line 2)"
                  name="address2"
                  rules={[
                    {
                      required: true,
                      message: "Required business address (line 2)",
                    },
                  ]}
                >
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <CountriesSelect setCountryId={setCountryId} />
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Code / P.O. box"
                  name="poBox"
                  rules={[
                    {
                      required: true,
                      message: "Required P.O box",
                    },
                  ]}
                >
                  <Input placeholder="eg. 29670" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <StatesSelect setStateId={setStateId} countryId={countryId} />
              </Col>
              <Col md={12}>
                <CitiesSelect stateId={stateId} />
              </Col>

              <Col md={24}>
                {errorMsg && (
                  <p
                    style={{
                      color: "#FF4D4F",
                      marginTop: "-5px",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <div
        style={{ marginTop: 24 }}
        className={!current > 0 ? "flex-center-end" : "flex-center-between"}
      >
        {current > 0 && (
          <Button
            shape="round"
            icon={<ArrowLeftOutlined />}
            onClick={onPrev}
            size="large"
          >
            Back
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          size="large"
          onClick={handleNext}
          disabled={loading}
        >
          Continue
          {loading && <Spin tip="Loading" size="small" />}
        </Button>
      </div>
    </>
  );
};

export default BusinessDetail;

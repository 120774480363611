import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "../sidebar";
const { Header, Content, Sider } = Layout;

const Layouts = (props) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={280} style={{ background: "#3F686E" }}>
        <Sidebar />
      </Sider>
      <Layout style={{ height: "100vh", overflow: "auto" }}>
        <Content
          style={{ padding: "0", display: "flex", flexDirection: "column" }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Layouts;

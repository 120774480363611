/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AuthLayout from "../../../sharedModules/authtLayout";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Spin,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hanldeAuthSignupRequest } from "../../../redux/slices/authSlice";
import { API_SETTINGS_BASE_URL } from "../../../config";
import useFetchCountriesData, {
  useFetchSettingsData,
} from "../../../hooks/useApiHook";

const SignUp = () => {
  const dispatch = useDispatch();
  const { data, loading, signupError } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [countriesPage, setCountriesPage] = useState(0);

  // Fetching countries data.
  const { countries, error, loader } = useFetchCountriesData(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/countries/?skip=${
      countriesPage * 20
    }&limit=20`
  );

  // Fetching user-settings data.
  const { userSettings, settingsErr, settingsLoader } = useFetchSettingsData(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/user-settings/?skip=0&limit=20&header_name=Signup&section_name=AhyaOS%20Support`
  );

  const onFinish = (values) => {
    const url = `${API_SETTINGS_BASE_URL}/v1/auth_sso_otp/signup-sso-otp/`;
    const raw = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      job_title: values?.jobTitle,
      organization_name: values?.organizationName,
      corporate_email: values?.corporateEmail,
      password: values?.password,
      confirm_password: values?.Cpassword,
      country: values?.location,
      user_settings: values?.support,
      id: values?.support?.[0],
    };

    dispatch(hanldeAuthSignupRequest({ url, raw })).then((action) => {
      if (hanldeAuthSignupRequest.fulfilled.match(action)) {
        navigate("/verify-code", {
          state: raw,
        });
        localStorage.setItem("loggedIn", JSON.stringify("true"));
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const emailValidator = (_, value) => {
    if (!value || !/(gmail\.com|outlook\.com|yahoo\.com)$/i.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Emails from Gmail, Outlook, and Yahoo are not allowed.")
    );
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please confirm your password!"));
    }
    if (value !== form.getFieldValue("password")) {
      return Promise.reject(new Error("The two passwords do not match!"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (signupError) {
      setMsg(signupError);
      setTimeout(() => {
        setMsg(null);
      }, 5000);
    }
  }, [data, signupError]);

  return (
    <AuthLayout>
      <Card bordered={false}>
        <h3 className="text-center fw-700 m-0">Sign up</h3>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mt-24"
        >
          <Row gutter={[16, 0]}>
            <Col md={12}>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Required  first name",
                  },
                ]}
              >
                <Input placeholder="eg. John" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Required  last name",
                  },
                ]}
              >
                <Input placeholder="eg. Doe" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Job title"
                name="jobTitle"
                rules={[
                  {
                    required: true,
                    message: "Required  job title",
                  },
                ]}
              >
                <Input placeholder="eg. Project Manager" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Organization name"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: "Required  organization name",
                  },
                ]}
              >
                <Input placeholder="eg. Ahya Technologies" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Corporate email"
                name="corporateEmail"
                rules={[
                  {
                    required: true,
                    message: "Required  corporate email",
                    type: "email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                  { validator: emailValidator },
                ]}
                extra="This will be the primary email associate with your organization."
              >
                <Input placeholder="eg. name@company.com" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Password is required" },
                  { min: 8, message: "Password must be 8 characters" },
                ]}
              >
                <Input.Password placeholder="eg. ●●●●●●●●●●" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Confirm password"
                name="Cpassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Required  confirm password",
                  },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password placeholder="eg. ●●●●●●●●●● " />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: " Required location" }]}
              >
                <Select
                  placeholder="eg. United Arab Emirates"
                  style={{ position: "rel" }}
                  dropdownRender={(menu) => (
                    <div>
                      {error && "Something went wrong, please try again"}
                      {menu}
                      {loader && (
                        <div style={{ textAlign: "center", padding: 8 }}>
                          <Spin />
                        </div>
                      )}
                      {!loader && (
                        <div style={{ textAlign: "center", padding: 8 }}>
                          <NavLink
                            onClick={() => {
                              if (countries?.length > 0) {
                                setCountriesPage((prevPage) => prevPage + 1);
                              } else {
                                setCountriesPage(0);
                              }
                            }}
                          >
                            {countries?.length > 0 ? "Load more" : "Back"}
                          </NavLink>
                        </div>
                      )}
                    </div>
                  )}
                >
                  {countries?.length > 0
                    ? countries?.map((country) => (
                        <Select.Option key={country.id} value={country?.id}>
                          {country?.name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="How can AhyaOS support you best?"
                name="support"
                rules={[
                  {
                    required: true,
                    message: " Required",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  mode="tags"
                  maxTagCount="responsive"
                  className="manzar"
                  dropdownRender={(menu) => (
                    <div>
                      {settingsErr && "Something went wrong, please try again"}
                      {menu}
                      {settingsLoader && (
                        <div style={{ textAlign: "center", padding: 8 }}>
                          <Spin />
                        </div>
                      )}
                    </div>
                  )}
                >
                  {userSettings?.length > 0
                    ? userSettings?.map((settings) => (
                        <Select.Option key={settings.id} value={settings?.id}>
                          {settings?.value}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item name="agreement" valuePropName="checked">
                <Checkbox>
                  By signing up, I agree with{" "}
                  <Link className="text-black text-underline">
                    terms of use
                  </Link>{" "}
                  and{" "}
                  <Link className="text-black text-underline">
                    privacy policy
                  </Link>{" "}
                  .
                </Checkbox>
              </Form.Item>
            </Col>
            <Col md={24}>
              {msg && (
                <p
                  style={{
                    color: "#FF4D4F",
                    marginTop: "-15px",
                    textAlign: "center",
                  }}
                >
                  {msg}
                </p>
              )}
            </Col>
            <Col md={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const {
                    firstName,
                    lastName,
                    organizationName,
                    corporateEmail,
                    password,
                    Cpassword,
                    location,
                    support,
                    jobTitle,
                  } = getFieldsValue();
                  const formIsComplete =
                    !!firstName &&
                    !!lastName &&
                    !!organizationName &&
                    !!corporateEmail &&
                    !!password &&
                    !!Cpassword &&
                    !!support &&
                    !!jobTitle &&
                    !!location;
                  return (
                    <Button
                      // onClick={onFinish}
                      type="primary"
                      shape="round"
                      icon={<ArrowRightOutlined />}
                      disabled={!formIsComplete || loading}
                      iconPosition="end"
                      block
                      size="large"
                      htmlType="submit"
                    >
                      {loading && <Spin tip="Loading" size="small" />}
                      Sign up
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={24}>
              <p className="text-center">
                Already have an account?{" "}
                <Link className="text-primary fw-500" to="/login">
                  Login
                </Link>
              </p>
            </Col>
          </Row>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default SignUp;

import { Form, Select, Spin } from 'antd';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useFetchStatesData } from '../../hooks/useApiHook';
import { API_SETTINGS_BASE_URL } from '../../config';

const StatesSelect = ({ setStateId, countryId, isPersonalInfo }) => {
    const [statePage, setStatePage] = useState(0);
    console.log("countryId ", countryId)
    const { states, statesLoader, statesErr } = useFetchStatesData(
        `${API_SETTINGS_BASE_URL}/v1/general_settings/states/?skip=${statePage * 20
        }&limit=200&country_id=${countryId}`
    );
    console.log("states : ", states)
    console.log("statesErr : ", statesErr)
    return (
        <>
            <Form.Item
                label="State / province"
                name="state"
                rules={[
                    {
                        required: true,
                        message: "Required state",
                    },
                ]}
            >
                {/* <Input placeholder="eg. Dubai" /> */}
                <Select
                    placeholder="Please Select"
                    style={{ position: "rel" }}
                    onSelect={(selected) => {
                        if (isPersonalInfo) {
                            let result = states?.find(
                                (state) => state?.name === selected
                            );
                            setStateId(result?.id);
                        } else {
                            setStateId(selected)
                        }
                    }}
                    disabled={countryId ? false : true}
                    dropdownRender={(menu) => (
                        <div>
                            {statesErr && "Something went wrong, please try again"}
                            {menu}
                            {statesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <Spin />
                                </div>
                            )}
                            {!statesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <NavLink
                                        onClick={() => {
                                            if (states?.length > 0) {
                                                setStatePage((prevPage) => prevPage + 1);
                                            } else {
                                                setStatePage(0);
                                            }
                                        }}
                                    >
                                        {states?.length > 0 ? "Load more" : "Back"}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    )}
                >
                    {states?.length > 0
                        ? states?.map((state) => (
                            <Select.Option key={state.id} value={isPersonalInfo ? state?.name : state?.id} >
                                {state?.name}
                            </Select.Option>
                        ))
                        : null}
                </Select>
            </Form.Item>
        </>
    )
}

export default StatesSelect

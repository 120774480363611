import React, { useState } from "react";
import AuthLayout from "../../../sharedModules/authtLayout";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../../../utils/api-services/auth";
import { API_SETTINGS_BASE_URL } from "../../../config";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const url = `${API_SETTINGS_BASE_URL}/v1/auth_sso_otp/forgot-password-sso/`;
    try {
      setLoading(true);
      const response = await resetPasswordRequest(values?.username, url);
      setLoading(false);

      console.log("Response ", response);
      if (response?.status === 200) {
        console.log("success");
        navigate("/reset-confirm", { state: { email: values?.username } });
      } else {
        console.log("failed");
      }
    } catch (error) {
      setLoading(false);
      console.log("errorr ", error);
      setError(error?.message);

      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setError(errorInfo);
  };
  return (
    <AuthLayout>
      <Card bordered={false}>
        <h3 className="text-center m-0 fw-700">Forgot password</h3>
        <p className="mt-8 text-black-60 mb-0 text-center">
          We need to confirm your email to send instructions <br />
          to reset your password.
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mt-24"
        >
          <Row gutter={[16, 0]}>
            <Col md={24}>
              <Form.Item
                label="Corporate email"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Required  corporate email",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="eg. name@company.com" />
              </Form.Item>
            </Col>
            <Col md={24}>
              {error && (
                <p style={{ color: "#FF4D4F", marginTop: "-15px" }}>{error}</p>
              )}
            </Col>
            <Col md={24} className="mt-10">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { username } = getFieldsValue();
                  const formIsComplete = !!username;
                  return (
                    <Button
                      type="primary"
                      shape="round"
                      icon={<ArrowRightOutlined />}
                      disabled={!formIsComplete || loading}
                      iconPosition="end"
                      block
                      size="large"
                      htmlType="submit"
                    >
                      {loading && <Spin tip="Loading" size="small" />}
                      Reset your password
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default ResetPassword;

import React, { useState } from "react";
import { Button, Card, Drawer, Space } from "antd";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import aws from "../../../images/aws.svg";

const IntegrationCard = ({ logo, heading, subtitle, paragraph }) => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card className="inte-card">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={logo} style={{ maxHeight: 35, marginBottom: 15 }} />
            <p className="m-0 text-black fs-16 text-merri">{heading}</p>
            {subtitle && <p className="m-0 text-black-60 fs-12">{subtitle}</p>}
            <p className="m-0 fw-500 text-black-60">{paragraph}</p>
          </div>
          <Button
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="large"
            className="btn-h-40 mt-24 fs-14 text-black fw-500"
            block
            onClick={openDrawer}
          >
            View Details
          </Button>
        </div>
      </Card>
      <Drawer
        title={
          <>
            <h4 className="m-0">Purchased Goods & Services</h4>
            <p className="m-0 fs-12 text-black-60">AWS</p>
          </>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Space size={[24, 24]} direction="vertical">
          <div>
            <p className="mt-0 text-blad fw-600 mb-2">Description</p>
            <p className="m-0 text-blad ">
              Automate the collection of your organization’s relevant cloud
              computing usage information from AWS.
            </p>
          </div>
          <div>
            <p className="mt-0 text-blad fw-600 mb-2">
              Footprint Sources Collected
            </p>
            <p className="m-0 text-blad ">Footprint Sources Collected</p>
          </div>
          <div>
            <p className="mt-0 text-blad fw-600 mb-2">How It Works</p>
            <ol className="pl-3">
              <li className="mb-2 text-blad">
                We retrieve cost and usage data by month from the AWS Billing
                and Cost Management API
              </li>
              <li className="text-blad">
                We load the processed records into Persefoni and calculate your
                footprint using the “Purchased Goods and Services (Category 1):
                Spend-Based: Industry” calculation
              </li>
            </ol>
          </div>
          <div>
            <p className="mt-0 text-blad fw-600 mb-2">Prerequisites</p>
            <p className="m-0 text-blad ">AWS IAM administrator account</p>
          </div>
          <div>
            <p className="mt-0 text-blad fw-600 mb-2">
              How We Calculate Your Footprint
            </p>
            <p className="m-0 text-blad ">
              We retrieve the list of monthly cost and usage reports for all
              months that began after the user-provided billing start date and
              completed prior to the run date. We collect the unblended cost
              amount for each monthly cost and usage report.
            </p>
          </div>
        </Space>
      </Drawer>
    </>
  );
};

export default IntegrationCard;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initState = {
  data: null,
  loading: false,
  error: "",
};

export const saveBusinessDetails = createAsyncThunk(
  "verification/business-details",
  async (data, { rejectWithValue }) => {
    try {
      console.log("data is ", data);
      const response = await axios.post(data?.url, data?.raw);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      const message = err?.response?.data?.detail ?? "An error occurred";

      console.log("message is ", message);
      const result = Array.isArray(message)
        ? message?.detail?.[0]?.msg
        : message;
      return rejectWithValue(result);
    }
  }
);

const businessDetailsSlice = createSlice({
  name: "businessDetailsSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveBusinessDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBusinessDetails.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(saveBusinessDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default businessDetailsSlice.reducer;

import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Badge, Space } from "antd";
import { ArrowRightOutlined, CheckOutlined } from "@ant-design/icons";
import collectData from "../../images/collectData.png";
import calculate from "../../images/calculate.png";
import reduce from "../../images/reduce.png";
import manage from "../../images/manage.png";
import integration from "../../images/integration.png";
import { Link } from "react-router-dom";
import LineChart from "../../sharedModules/charts/LineChart";

const Home = () => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Now",
      "Dec",
    ],
    datasets: [
      {
        label: "No. of downloads (K)",
        lineTension: 0.05,
        pointRadius: 0,
        data: [2, 4, 5, 6, 9, 8, 12, 15, 12, 9, 12, 14],
        borderColor: "#EF7D4F",
        backgroundColor: (context) => {
          const backgroundColor = [
            "rgba(255, 255, 255,1)",
            "rgba(255, 255, 255,0.1)",
            "rgba(254, 169, 35,0.1)",
            "rgba(217, 94, 40, .4)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            data,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, backgroundColor[3]);
          gradientBg.addColorStop(0.75, backgroundColor[1]);
          gradientBg.addColorStop(1, backgroundColor[0]);
          return gradientBg;
        },
        fill: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 14,
            family: "Montserrat",
          },
        },
      },
      y: {
        display: false,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <div className="main-content">
        <Card
          className="mb-3 card-body-py-0"
          style={{ background: "rgba(235, 99, 44, .16)", color: "#EB632C" }}
        >
          <Space className="mt-8 mb-8" size={16}>
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 6,
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckOutlined />
            </div>
            <div>
              <h3 className="m-0 fs-18" style={{ color: "#EB632C" }}>
                Attention
              </h3>
              <p className="m-0">
                Your access is currently active, but verification is pending.
                Please complete the verification process to fully utilize our
                services. Thank you for your cooperation.{" "}
                <Link
                  className="fw-600"
                  style={{ color: "#EB632C", textDecoration: "underline" }}
                >
                  Verify Now
                </Link>
              </p>
            </div>
          </Space>
        </Card>

        <Card className="grpah-card mb-3">
          <h4 className="text-black-60 m-0">Acme Corporation</h4>
          <div className="price-sec mb-0">
            <h2 className="m-0">0</h2>
            <Badge
              count={
                <span>
                  MtCO<sub>2</sub>e
                </span>
              }
              style={{
                backgroundColor: "#E9EAEC",
                color: "rgba(15, 19, 36, 0.6)",
                fontWeight: "500",
                padding: "3px 10px 1px",
                borderRadius: "6px",
              }}
            />
          </div>
          <p className="m-0 text-black-60 fs-14">Prior 12 month footprint</p>
          <div className="mt-8 graph-300">
            <LineChart data={data} options={options} />
          </div>
        </Card>

        <Card className="mb-3">
          <h4 className="text-black fw-600 m-0 ff-mont mb-8">
            Explore your footprint
          </h4>
          <p className="m-0 text-black-60 fs-14">
            Exploring your footprint helps you determine where your organization
            currently stands and allows you to build decarbonization strategies
            accordingly. You can take a quick glance at your organization
            details, current footprint data, emission scope sources, and even an
            emissions map based on your facility's geolocation. We also provide
            in-depth, easy-to-digest analytics for each of your main footprint
            source categories along with insights that incorporate CDP data to
            provide you with sub-industry comparisons.
          </p>
          <Button
            type="gray"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            className="mt-3"
          >
            Go to analyse
          </Button>

          <div className="block mt-3">
            <Row gutter={[16, 0]}>
              <Col md={8}>
                <Card className="text-center">
                  <Badge
                    count="10.58%"
                    style={{
                      backgroundColor: "rgba(254, 169, 35, .1)",
                      color: "#FEA923",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h2 className="m-0">7,732.56</h2>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-600">Scope 1</p>
                </Card>
              </Col>
              <Col md={8}>
                <Card className="text-center">
                  <Badge
                    count="10.58%"
                    style={{
                      backgroundColor: "rgba(26, 180, 211, .1)",
                      color: "#1AB4D3",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h2 className="m-0">7,732.56</h2>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-600">Scope 2</p>
                </Card>
              </Col>
              <Col md={8}>
                <Card className="text-center">
                  <Badge
                    count="78.58%"
                    style={{
                      backgroundColor: "rgba(86, 84, 212, .1)",
                      color: "#5654D4",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h2 className="m-0">7,732.56</h2>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-600">Scope 3</p>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>

        <Row className="mt-3" gutter={20}>
          <Col md={12}>
            <Card className="overflow-hidden h-100">
              <Row gutter={[33, 0]} align="middle" className="h-100">
                <Col md={10}>
                  <h4 className="text-black m-0 fw-600 ff-mont mb-8">
                    Collect your data
                  </h4>
                  <p className="m-0 text-black-60 fs-12">
                    This feature allows you to configure footprint sources
                    (across Scope's 1, 2 & 3) including value chain and financed
                    emissions and collect emissions data internally and
                    externally in a secure and standardised manner.
                  </p>
                  <Button
                    type="gray"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="mt-3"
                  >
                    Go to collect
                  </Button>
                </Col>
                <Col md={14}>
                  <img src={collectData} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="overflow-hidden">
              <Row gutter={[21, 0]} align="middle">
                <Col md={12}>
                  <h4 className="text-black m-0 fw-600 ff-mont mb-8">
                    Calculate your footprint
                  </h4>
                  <p className="m-0 text-black-60 fs-12">
                    Ahya simplifies carbon calculations by providing
                    step-by-step instructions on capturing the data needed to
                    calculate each specific footprint source. Our interface
                    eliminates the need for any prior carbon accounting
                    knowledge. With us, calculating your organization's carbon
                    footprint across Scope 1, 2, and 3 and maintaining complete
                    compliance with the Greenhouse Gas Protocol is now easier
                    than ever. Get started calculating yovur organization's
                    footprint today so you can better recognize climate risks
                    and accurately meet disclosure requirements.
                  </p>
                  <Button
                    type="gray"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="mt-3"
                  >
                    Go to calculate
                  </Button>
                </Col>
                <Col md={12}>
                  <img src={calculate} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3" gutter={[20, 0]}>
          <Col md={8}>
            <Card className="overflow-hidden h-100 card-body-pb-0">
              <Row className="h-100 align-content-between">
                <Col md={24}>
                  <h4 className="text-black m-0 fw-600 ff-mont mb-8">
                    Reduce your footprint
                  </h4>
                  <p className="m-0 text-black-60 fs-12">
                    This feature allows you to configure footprint sources
                    (across Our footprint reduction modules show your
                    organization's current footprint and offer modeled
                    reductions that automatically update based on your entered
                    data for specific scopes and facilities. We provide
                    additional insight into how each reduction model generates
                    the information to give you a better understanding of how
                    certain activities affect your current footprint. We also
                    make it easy for you to track and measure progress towards
                    your decarbonization target.
                  </p>
                  <Button
                    type="gray"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="mt-3"
                  >
                    Go to reduce
                  </Button>
                </Col>
                <Col md={24}>
                  <img src={reduce} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={8}>
            <Card className="overflow-hidden h-100 card-body-pb-0">
              <Row className="h-100 align-content-between">
                <Col md={24}>
                  <h4 className="text-black m-0 fw-600 ff-mont mb-8">
                    Manage your data
                  </h4>
                  <p className="m-0 text-black-60 fs-12">
                    Ahya makes data management simpler than ever. Our platform
                    provides a centralized location to view and update your
                    organization's assets (equipment, facilities, etc.),
                    catalogs, and calculations. Having this data in one place
                    makes it easy to assess the quality and accuracy of your
                    organization's data.
                  </p>
                  <Button
                    type="gray"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="mt-3"
                  >
                    Go to manage data
                  </Button>
                </Col>
                <Col md={24}>
                  <img src={manage} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={8}>
            <Card className="overflow-hidden h-100 card-body-pb-0">
              <Row className="h-100 align-content-between">
                <Col md={24}>
                  <h4 className="text-black m-0 fw-600 ff-mont mb-8">
                    Connect your integrations
                  </h4>
                  <p className="m-0 text-black-60 fs-12">
                    With AhyaOS Integration Hub, you can easily connect our
                    platform to other software applications to automate data
                    collection and transform it into carbon footprint data. The
                    result is less time spent on labor-intensive manual data
                    collection requests and more time spent on value-added
                    aspects of carbon accounting, such as footprint reduction
                    planning.
                  </p>
                  <Button
                    type="gray"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="mt-3"
                  >
                    Go to integration hub
                  </Button>
                </Col>
                <Col md={24}>
                  <img src={integration} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;

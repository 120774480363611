import React, { useState } from "react";
import { Tabs, Checkbox, Table } from "antd";

const { TabPane } = Tabs;
const Scope3Tab = () => {
  const [selectAll, setSelectAll] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRowClick = (record) => {
    const key = record.key;
    const newSelectedRowKeys = [...selectedRowKeys];

    if (newSelectedRowKeys.includes(key)) {
      newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(key), 1);
    } else {
      newSelectedRowKeys.push(key);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          <p className="m-0 fw-500 text-black">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.category}</p>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Bus Transportation (Catagory 6)",
      category: "Assets",
    },
    {
      key: "2",
      name: "Business Travel (Category 6)",
      category: "People Activity"
    },
    {
      key: "3",
      name: "Commercial Air Travel (Category 6)",
      category: "People Activity"
    },
    {
      key: "4",
      name: "Employee Commuting (Category 7)",
      category: "People Activity"
    },
    {
      key: "5",
      name: "Employee Commuting/Telework (Category 7)",
      category: "People Activity"
    },
    {
      key: "6",
      name: "Hotel Stay (Category 6)",
      category:"People Activity"
     },
     {
      key: "7",
      name: "Number of Employees(Catagory 6)",
      category: "People Activity"
    },

    {
    key: "8",
    name: "Personal Vehicle (Category 6)",
    category: "People Activity"
  },
  {
    key: "4",
    name: "Private Air Travel (Category 6)",
    category: "People Activity"
  },
  {
    key: "4",
    name: "Rail Travel (Category 6)",
    category: "People Activity"
  },
  {
    key: "9",
    name: "Rental Car (Category 6)",
    category: "People Activity"
  },

  {
    key: "8",
    name: "Ride Share (Category 6)",
    category: "People Activity"
  },
  {
    key: "9",
    name: "Subway/Tram Transportation (Category 6)",
    category: "People Activity"
  },
  {
    key: "10",
    name: "Taxi (Category 6)",
    category: "People Activity"
  },
  {
    key: "11",
    name: "Telework (Category 7)",
    category: "People Activity"
  },

  {
    key: "12",
    name: "Telework (Category 7)",
    category: "People Activity"
  },
  {
    key: "13",
    name: "Telework (Category 7)",
    category: "People Activity"
  },
  {
    key: "14",
    name: "Water Travel (Category 6)",
    category: "People Activity"
  },
  {
    key: "15",
    name: "Business Loan - Listed (Category 15)",
    category: "People Activity"
  },
  {
    key: "16",
    name: "Business Loan - Unlisted (Category 15)",
    category: "People Activity"
  },
  {
    key: "17",
    name: "Commercial Real Estate (Category 15)",
    category: "People Activity"
  },

  {
    key: "18",
    name: "Corporate Bond - Listed (Category 15)",
    category: "People Activity"
  },
  {
    key: "19",
    name: "Corporate Bond - Unlisted (Category 15)",
    category: "People Activity"
  },
  {
    key: "20",
    name: "Equity - Listed (Category 15)",
    category: "People Activity"
  },
  {
    key: "21",
    name: "Equity - Unlisted (Category 15)",
    category: "People Activity"
  },
  {
    key: "22",
    name: "Mortgages (Category 15)",
    category: "People Activity"
  },
    {
    key: "23",
    name: "Motor Vehicle Loan (Category 15)",
    category: "People Activity"
  },



  ];

  const onSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedRowKeys(checked ? data.map((item) => item.key) : []);
  };
  const extraContent = (
    <Checkbox onChange={onSelectAllChange} checked={selectAll}>
      Select All
    </Checkbox>
  );
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={extraContent}
      style={{ marginTop: 36 }}
    >
      <TabPane tab="All" key="1">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: () => {
              onRowClick(record);
            },
          })}
        />
      </TabPane>
      <TabPane tab="People Activity" key="2"></TabPane>
      <TabPane tab="Supply Chain" key="3"></TabPane>
      <TabPane tab="Assets" key="4"></TabPane>
      <TabPane tab="Sites" key="5"></TabPane>
    </Tabs>
  );
};

export default Scope3Tab;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../config";
import axios from "axios";

const initState = {
  data: null,
  loading: false,
  signupError: "",
  signinError: "",
  signupVerifyError: "",
};

export const hanldeAuthSignupRequest = createAsyncThunk(
  "auth/sign-up",
  async (data, { rejectWithValue }) => {
    try {
      console.log("data ", data);
      const response = await axios.post(data?.url, data?.raw);
      console.log("response : ", response);
      return response.data;
    } catch (err) {
      console.log("err is : ", err);
      console.log("error is : ", err?.response);
      if (!err.response) {
        throw err;
      }

      const message = err?.response?.data?.detail ?? "An error occurred";

      console.log("message is ", message);
      const result = Array.isArray(message)
        ? message?.detail?.[0]?.msg
        : message;
      return rejectWithValue(result);
    }
  }
);

export const hanldeSignupVerifiedRequest = createAsyncThunk(
  "auth/sign-up-verified",
  async (data, { rejectWithValue }) => {
    try {
      console.log("data ", data);
      const response = await axios.post(data?.url, data?.raw);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      const message = err?.response?.data?.detail
        ? JSON.parse(err?.response?.data?.detail)
        : "An error occurred";

      console.log("message is ", message);
      const result = message?.detail?.[0]?.msg ?? message?.detail;
      console.log("Result os ", result);
      return rejectWithValue(result);
    }
  }
);

export const hanldeAuthLoginRequest = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: data?.url,
      headers: {
        "x-api-key":
          "ahyaosh2PnWso61W47W6ogIAUXq9UDyD2xVN4uInbg4SpcBpXDKqm97JbML8aqFD",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data?.raw,
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      const message = err?.response?.data?.detail
        ? JSON.parse(err?.response?.data?.detail)
        : "An error occurred";

      console.log("message is ", message);
      const result = message?.detail?.[0]?.msg ?? message?.detail;
      console.log("Result os ", result);
      return rejectWithValue(result);
    }
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(hanldeAuthSignupRequest.pending, (state) => {
        state.loading = true;
        state.signupError = null;
      })
      .addCase(hanldeAuthSignupRequest.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(hanldeAuthSignupRequest.rejected, (state, action) => {
        state.signupError =
          action.payload ?? "Something went wrong, please try again.";
        state.loading = false;
      })
      .addCase(hanldeAuthLoginRequest.pending, (state) => {
        state.loading = true;
        state.signinError = null;
      })
      .addCase(hanldeAuthLoginRequest.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(hanldeAuthLoginRequest.rejected, (state, action) => {
        state.signinError =
          action.payload ?? "Something went wrong, please try again.";
        state.loading = false;
      })
      .addCase(hanldeSignupVerifiedRequest.pending, (state) => {
        state.loading = true;
        state.signupVerifyError = null;
      })
      .addCase(hanldeSignupVerifiedRequest.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(hanldeSignupVerifiedRequest.rejected, (state, action) => {
        state.signupVerifyError =
          action.payload ?? "Something went wrong, please try again.";
        state.loading = false;
      });
  },
});

export default authSlice.reducer;

import React, { useState } from "react";
import {
  Card,
  Select,
  Row,
  Col,
  Button,
  Flex,
  Space,
  Form,
  DatePicker,
  Tabs,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import ReportingPeriods from "./ReportingPeriods";
import ReportingPreferences from "./ReportingPreferences";

const ReportSetting = () => {
  const [title, setTitle] = useState("Reporting Periods");
  const items = [
    {
      key: "Reporting Periods",
      label: "Reporting Periods",
      children: <ReportingPeriods />,
    },
    {
      key: "Reporting Preferences",
      label: "Reporting Preferences",
      children: <ReportingPreferences />,
    },
  ];

  const onChange = (key) => {
    setTitle(key);
  };
  return (
    <>
      <Card>
        <div className="card-body">
          <Tabs
            defaultActiveKey="Reporting Periods"
            items={items}
            onChange={onChange}
          />
        </div>
      </Card>
    </>
  );
};

export default ReportSetting;

import React, { useState } from "react";
import CatalogLayout from "../CatalogLayout";
import { Flex, Space, Button, Dropdown, Switch, Input, Table } from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  SettingOutlined,
  ArrowRightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Search from "../../../../images/search.svg";

const FootprintSource = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Footprint Source",
      dataIndex: "equipment",
      key: "equipment",
      sorter: (a, b) => a.equipment - b.equipment,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Use of Anesthetics",
      category: "Stationary Combustion Equipment",
      itemSource: "Account",
    },
    {
      key: "2",
      equipment: "Production of Crude Steel using Electric Furnace",
      category: "Stationary Combustion Equipment",
      itemSource: "Account",
    },
    {
      key: "3",
      equipment:
        "Use of Electricity in Electric Furnaces (for Production of Pig Iron, Steel, Alloy Iron, and Carbide)",
      category: "Stationary Combustion Equipment",
      itemSource: "Account",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];
  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Footprint Sources (Default)</h4>
        </Space>
      </Flex>

      <Flex
        justify="space-between"
        align="center"
        className="mt-24"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500 }}
        />
        <Button
          shape="circle"
          icon={<SettingOutlined />}
          iconPosition="end"
          className="fw-500 text-black"
        ></Button>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          // rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>
    </CatalogLayout>
  );
};

export default FootprintSource;

import axios from "axios";

// Reset password API request
export const resetPasswordRequest = async (email, url) => {
  return axios.post(
    url,
    { email },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

// Set new password API request
export const newPasswordRequest = async (token, new_password, url) => {
  return axios.post(
    url,
    { token, new_password },
    {
      headers: {
        "x-api-key":
          "ahyaosh2PnWso61W47W6ogIAUXq9UDyD2xVN4uInbg4SpcBpXDKqm97JbML8aqFD",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};
